import * as React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { DialogContent, Link, Typography } from "@mui/material";
import { ResultDialogDTO } from "../registration";

const SuccessDialog = ({ email }: { email: string }): React.JSX.Element => {
  return (
    <>
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
          py: 3,
        }}
      >
        Registrierung erfolgreich!
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" component="p" sx={{ mb: 2 }}>
          Vielen Dank für deine Registrierung!
        </Typography>

        <Typography variant="body1" component="p" sx={{ mb: 2 }}>
          Wir haben dir eine E-Mail an <b> {email} </b> geschickt. Bitte
          bestätige deine E-Mail-Adresse, indem du auf den Link in der E-Mail
          klickst.
        </Typography>
        <Typography variant="body1" component="p" sx={{ mb: 2 }}>
          Erst nach der Bestätigung deiner E-Mail-Adresse kannst du dich
          anmelden und unser Angebot nutzen.
        </Typography>
        <Typography variant="body1" component="p" sx={{ mb: 2 }}>
          Solltest du keine E-Mail von uns erhalten haben, prüfe bitte deinen
          Spam-Ordner. Sollte auch dort keine E-Mail von uns zu finden sein,
          kontaktiere uns bitte unter{" "}
          <Link href="mailto:bewerbungscoaching@wbstraining.de">
            bewerbungscoaching@wbstraining.de
          </Link>
        </Typography>

        <Typography variant="body1" component="p" sx={{ mb: 2 }}>
          Herzliche Grüße
          <br />
          Dein Bewerbungscoaching Team
        </Typography>
      </DialogContent>
    </>
  );
};

const ErrorDialog = ({
  email,
  message,
}: {
  email: string;
  message: string | null;
}): React.JSX.Element => {
  if (message === "emailAlreadyExists") {
    return (
      <>
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
            py: 3,
          }}
        >
          Registrierung fehlgeschlagen!
        </DialogTitle>

        <DialogContent>
          <Typography variant="body1" component="p" sx={{ mb: 2 }}>
            vielen Dank für Ihr Interesse an unserem Coachingangebot!
          </Typography>

          <Typography variant="body1" component="p" sx={{ mb: 2 }}>
            Leider ist die E-Mail-Adresse <b>{email}</b>, die du bei Deiner
            Registrierung angegeben hast, bereits in unserem System vorhanden,
            sodass eine erneute Registrierung nicht möglich ist.
          </Typography>

          <Typography variant="body1" component="p" sx={{ mb: 2 }}>
            Solltest du Fragen haben, wende dich bitte an uns unter{" "}
            <Link href="mailto:bewerbungscoaching@wbstraining.de">
              bewerbungscoaching@wbstraining.de
            </Link>
          </Typography>

          <Typography variant="body1" component="p" sx={{ mb: 2 }}>
            Herzliche Grüße
            <br />
            Dein Bewerbungscoaching Team
          </Typography>
        </DialogContent>
      </>
    );
  } else {
    return (
      <>
        <DialogTitle>Registrierung fehlgeschlagen!</DialogTitle>
        <DialogContent>
          <Typography variant="body1" component="p" sx={{ mb: 2 }}></Typography>

          <Typography variant="body1" component="p" sx={{ mb: 2 }}>
            Sollte das Problem weiterhin bestehen, kontaktiere uns bitte unter{" "}
            <Link href="mailto:bewerbungscoaching@wbstraining.de">
              bewerbungscoaching@wbstraining.de
            </Link>
          </Typography>
          <Typography variant="body1" component="p" sx={{ mb: 2 }}>
            Herzliche Grüße
            <br />
            Dein Bewerbungscoaching Team
          </Typography>
        </DialogContent>
      </>
    );
  }
};

const RegistrationResultDialog = ({
  registrationResultDialogDTO,
  setRegistrationResultDialogDTO,
}: {
  registrationResultDialogDTO: ResultDialogDTO;
  setRegistrationResultDialogDTO: (
    registrationResultDialogDTO: ResultDialogDTO
  ) => void;
}): React.JSX.Element => {
  return (
    <Dialog
      onClose={() =>
        setRegistrationResultDialogDTO({
          ...registrationResultDialogDTO,
          open: false,
        })
      }
      open={registrationResultDialogDTO.open}
    >
      {registrationResultDialogDTO.status === "success" ? (
        <SuccessDialog email={registrationResultDialogDTO.email} />
      ) : (
        <ErrorDialog
          email={registrationResultDialogDTO.email}
          message={registrationResultDialogDTO.message}
        />
      )}
    </Dialog>
  );
};

export default RegistrationResultDialog;
