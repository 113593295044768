import { Grid, Autocomplete, TextField, Button } from "@mui/material";
import React from "react";
import SearchControllsProps from "../interface/searchControllsProps";

const SearchControlls = ({
  setSearchControlls,
  searchControlls,
  startSearch,
}: {
  setSearchControlls: React.Dispatch<
    React.SetStateAction<SearchControllsProps>
  >;
  searchControlls: SearchControllsProps;
  startSearch: () => void;
}): React.JSX.Element => {
  return (
    <Grid container spacing={2} sx={{ mb: 2 }}>
      <Grid item xs={12} md={6} spacing={2}>
        <Autocomplete
          disabled={searchControlls.loading}
          multiple
          onChange={(_event, value) => {
            setSearchControlls({
              ...searchControlls,
              status: {
                booked: value.includes("Gebucht"),
                free: value.includes("Frei"),
                missed: value.includes("Versäumt"),
              },
            });
          }}
          disableCloseOnSelect
          id="tags-standard"
          options={["Gebucht", "Frei", "Versäumt"]}
          defaultValue={["Gebucht", "Frei", "Versäumt"]}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Status des Coachings"
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={4} md={2} spacing={2}>
        <TextField
          disabled={searchControlls.loading}
          fullWidth
          type="date"
          value={searchControlls.date.start}
          onChange={(e) =>
            setSearchControlls({
              ...searchControlls,
              date: { ...searchControlls.date, start: e.target.value },
            })
          }
          id="EndOfQualification"
          label="Termin von"
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} sm={4} md={2} spacing={2}>
        <TextField
          disabled={searchControlls.loading}
          fullWidth
          type="date"
          value={searchControlls.date.end}
          onChange={(e) =>
            setSearchControlls({
              ...searchControlls,
              date: { ...searchControlls.date, end: e.target.value },
            })
          }
          id="EndOfQualification"
          label="Termin bis"
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} sm={4} md={2} spacing={2}>
        <Button
          disabled={searchControlls.loading}
          onClick={startSearch}
          size="large"
          sx={{
            height: "100%",
          }}
          fullWidth
          variant="contained"
          color="primary"
        >
          Suchen
        </Button>
      </Grid>
    </Grid>
  );
};

export default SearchControlls;
