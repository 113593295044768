import { Box, Button, Container, Typography } from "@mui/material";
import { AppContext } from "../../../context/appContext";
import React from "react";

const GoToLogin = (): React.JSX.Element => {
  const { openLoginDialog, setOpenLoginDialog } = React.useContext(AppContext);

  return (
    <Box
      sx={{
        py: 10,
        background: "#eeeeee",
      }}
      id="registration"
    >
      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <Typography
            variant="h3"
            component="h3"
            sx={{ mb: 4, textAlign: "center" }}
          >
            Hier geht es zur Anmeldung
          </Typography>

          <Button
            variant="contained"
            color="primary"
            sx={{ px: 10, py: 2 }}
            onClick={() => setOpenLoginDialog(!openLoginDialog)}
          >
            Zur Anmeldung
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default GoToLogin;
