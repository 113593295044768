import { TextField } from "@mui/material";

const InputDate = ({
  focus,
  disabled,
  date,
  setDate,
  error,
  showHelperText,
  required,
}: {
  focus?: boolean;
  disabled?: boolean;
  date: string;
  setDate: (date: string) => void;
  error?: boolean;
  showHelperText?: boolean;
  required?: boolean;
}): React.JSX.Element => {
  return (
    <TextField
      focused={focus}
      disabled={disabled}
      color="secondary"
      fullWidth
      type="date"
      value={date}
      onChange={(e) => {
        setDate(e.target.value);
      }}
      id="search-booking-date"
      label="Datum"
      variant="outlined"
      error={error && !date}
      required={required}
      helperText={showHelperText ? "Bitte wähle ein Datum aus." : ""}
    />
  );
};

export default InputDate;
