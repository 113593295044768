import { Grid, Typography } from "@mui/material";

const Qualification = ({
  appointment,
}: {
  appointment: any;
}): React.JSX.Element => {
  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={6}
      sx={{
        mb: 2,
      }}
    >
      <Typography
        sx={{
          fontWeight: "bold",
        }}
        variant="caption"
        component="p"
      >
        Qualifizierung
      </Typography>
      <Typography variant="body1" component="p">
        {appointment.participantQualification}
      </Typography>
    </Grid>
  );
};

export default Qualification;
