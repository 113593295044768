import { Box, Typography } from "@mui/material";

const NoFilterMessage = (): React.JSX.Element => {
  return (
    <Box
      sx={{
        backgroundColor: "#eeeeee",
        p: 2,
        mb: 2,
        borderRadius: 2,
        textAlign: "center",
      }}
    >
      <Typography variant="body1" component="div">
        Du hast beide Filter deaktivert.
      </Typography>
    </Box>
  );
};

export default NoFilterMessage;
