import { Box, Typography, styled } from "@mui/material";
import HeartImage from "../../../assets/landingpage/heart.png";

const NavLogo = styled("img")(() => ({
  width: 50,
  margin: "0 10px",
}));

const Heartbanner = (): React.JSX.Element => {
  return (
    <Box
      sx={{
        background: (theme) => theme.palette.primary.main,
      }}
    >
      <Typography
        variant="h6"
        component="h6"
        sx={{
          py: 1,
          color: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Unser <NavLogo src={HeartImage} alt="HeartImage" /> schlägt für Bildung
      </Typography>
    </Box>
  );
};

export default Heartbanner;
