import {
  Dialog,
  DialogContent,
  IconButton,
  Grid,
  styled,
  TextField,
  Box,
  Typography,
  Button,
} from "@mui/material";
import { AppContext } from "../../../context/appContext";
import React, { useRef } from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import LoginBackground from "../../../assets/landingpage/login-background.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { endpoints } from "../../../endpoints/endpoints";
import ForgetPasswordDialog from "./forgetPasswordDialog";
import PasswordInput from "../../inputs/password/passwordInput";

const BackgroundImg = styled("img")(() => ({
  width: "100%",
  height: "100%",
  objectFit: "cover",
}));

const LoginDialog = (): React.JSX.Element => {
  const {
    openLoginDialog,
    setOpenLoginDialog,
    setUser,
    openForegtPasswordDialog,
    setOpenForegtPasswordDialog,
  } = React.useContext(AppContext);

  const [userCredentials, setUserCredentials] = React.useState({
    email: "",
    password: "",
  });

  const [loadSubmit, setLoadSubmit] = React.useState(false);
  const loginError = useRef(false);

  const navigate = useNavigate();

  const SubmitLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoadSubmit(true);

    axios
      .post(endpoints.authentication.login, {
        username: userCredentials.email,
        password: userCredentials.password,
      })
      .then((response) => {
        if (response.data.meta.status === "success") {
          setUser(response.data.data);
          localStorage.setItem("sessionId", response.data.data.sessionId);
          setOpenLoginDialog(!openLoginDialog);
          navigate("/intern");
        } else {
          loginError.current = true;
        }
        setLoadSubmit(false);
      })
      .catch((error) => {
        console.log(error);
        setLoadSubmit(false);
      });
  };

  return (
    <Dialog
      open={openLoginDialog}
      onClose={() => setOpenLoginDialog(!openLoginDialog)}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogContent
        sx={{
          margin: "0 !important",
          padding: "0 !important",
        }}
      >
        <Grid container spacing={0}>
          <Grid
            item
            sm={6}
            sx={{
              display: {
                xs: "none",
                sm: "flex",
              },
              justifyContent: "center",
              alignItems: "center",
              padding: "20px",
              backgroundColor: "#F2F8FF",
            }}
          >
            <BackgroundImg src={LoginBackground} alt="" />
          </Grid>

          {/* forget password */}
          {openForegtPasswordDialog ? (
            <Grid item xs={12} sm={6}>
              <ForgetPasswordDialog />
            </Grid>
          ) : (
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  padding: "10px 30px",
                }}
              >
                <IconButton
                  aria-label="close"
                  onClick={() => setOpenLoginDialog(!openLoginDialog)}
                >
                  <CloseOutlinedIcon />
                </IconButton>
              </Box>

              <Box
                sx={{
                  textAlign: "center",
                }}
              >
                <Typography
                  variant="body1"
                  component="p"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Anmelden
                </Typography>
              </Box>

              <form
                onSubmit={SubmitLogin}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "5px 30px 30px 30px",
                }}
              >
                <TextField
                  required
                  fullWidth
                  type="email"
                  id="email"
                  label="Email Addresse"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  sx={{ my: 2 }}
                  value={userCredentials.email}
                  onChange={(e) => {
                    setUserCredentials({
                      ...userCredentials,
                      email: e.target.value,
                    });
                  }}
                />

                <PasswordInput
                  password={userCredentials.password}
                  setPassword={(e: any) =>
                    setUserCredentials({
                      ...userCredentials,
                      password: e,
                    })
                  }
                  label={"Passwort"}
                />

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    my: 2,
                  }}
                >
                  <Button
                    disabled={loadSubmit}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    {loadSubmit ? "Anmeldung läuft..." : "Anmelden"}
                  </Button>
                </Box>

                <Button
                  variant="text"
                  onClick={() =>
                    setOpenForegtPasswordDialog(!openForegtPasswordDialog)
                  }
                >
                  <Typography variant="caption" component="span">
                    Passwort vergessen
                  </Typography>
                </Button>

                <Typography
                  variant="body2"
                  component="p"
                  color={"error"}
                  sx={{
                    display: loginError.current ? "block" : "none",
                    textAlign: "center",
                    my: 2,
                  }}
                >
                  Anmeldung fehlgeschlagen!
                  <br />
                  Benutzername oder Passwort falsch.
                </Typography>
              </form>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default LoginDialog;
