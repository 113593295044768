import { Box, Grid, Button } from "@mui/material";
import RenderFileDetail from "./renderFileDetail";
import { File } from "../participantFiles";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import axios from "axios";
import { AppContext, User } from "../../../../context/appContext";
import React from "react";
import { endpoints } from "../../../../endpoints/endpoints";
import { enqueueSnackbar } from "notistack";

const UploadTile = ({
  file,
  deletable,
  onDelete,
}: {
  file: File;
  deletable?: boolean;
  onDelete?: () => void;
}) => {
  const {
    user,
  }: {
    user: User;
  } = React.useContext(AppContext);

  const [confirmDelete, setConfirmDelete] = React.useState(false);

  const deleteFile = (fileId: string): void => {
    axios
      .post(endpoints.participant.deleteFile, {
        fileId: fileId,
        sessionId: user.sessionId,
      })
      .then(() => {
        enqueueSnackbar("Die Datei wurde gelöscht!", {
          variant: "success",
        });
        setConfirmDelete(false);

        if (onDelete) {
          onDelete();
        }
      })
      .catch(() => {
        enqueueSnackbar("Die Datei konnte nicht gelöscht werden!", {
          variant: "error",
        });
      });
  };

  const downloadFile = (fileId: string): void => {
    axios
      .post(
        endpoints.participant.downloadFile,
        {
          fileId: fileId,
          sessionId: user.sessionId,
        },
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        if (response.data.size === 0) {
          enqueueSnackbar("Die Datei konnte nicht heruntergeladen werden!", {
            variant: "error",
          });
          return;
        }

        // simulate click to download file
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file.name);
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        enqueueSnackbar("Die Datei konnte nicht heruntergeladen werden!", {
          variant: "error",
        });
      });
  };

  return (
    <Box
      sx={{
        backgroundColor: "#eeeeee",
        p: 2,
        mb: 2,
        borderRadius: 2,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={6} md={6}>
          <RenderFileDetail label={"Dateiname"} shownDetail={file.name} />
        </Grid>

        <Grid item xs={12} md={3}>
          <Button
            sx={{
              px: 5,
            }}
            onClick={() => downloadFile(file.id)}
            startIcon={<FileDownloadOutlinedIcon />}
            fullWidth
            variant="contained"
            color="secondary"
          >
            Download
          </Button>
        </Grid>

        <Grid item xs={12} md={3}>
          <Button
            onClick={
              confirmDelete
                ? () => deleteFile(file.id)
                : () => setConfirmDelete(true)
            }
            disabled={!deletable}
            sx={{
              px: 5,
            }}
            startIcon={<DeleteOutlinedIcon />}
            fullWidth
            variant="outlined"
            color="secondary"
          >
            {confirmDelete ? "Sicher?" : "Löschen"}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UploadTile;
