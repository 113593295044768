import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect } from "react";
import { endpoints } from "../../../endpoints/endpoints";
import { User, AppContext } from "../../../context/appContext";

const SelectCoachInput = ({
  coach,
  setCoach,
  required,
  error,
  removeAll,
  disabled,
}: {
  coach: { label: string; value: string | null } | null;
  setCoach: React.Dispatch<React.SetStateAction<any>>;
  required?: boolean;
  error?: boolean;
  removeAll?: boolean;
  disabled?: boolean;
}): React.JSX.Element => {
  const {
    user,
  }: {
    user: User;
  } = React.useContext(AppContext);

  const [coachList, setCoachList] = React.useState([]);
  const fetchCoachList = React.useRef(false);

  useEffect(() => {
    if (fetchCoachList.current) {
      return;
    }
    fetchCoachList.current = true;
    axios
      .post(endpoints.coach.getAll, {
        sessionId: user.sessionId,
      })
      .then((response) => {
        if (!removeAll) {
          response.data.unshift({ label: "Alle", value: null });
        }

        setCoachList(response.data);
        fetchCoachList.current = false;
      })
      .catch((error) => {
        console.error("Error fetching coach list", error);
        fetchCoachList.current = false;
      });
  }, [removeAll, user.sessionId]);

  return (
    <Autocomplete
      disabled={disabled}
      loading={fetchCoachList.current}
      loadingText="Lädt Coachliste..."
      disablePortal
      id="search-booking-coach"
      options={coachList}
      getOptionLabel={(option) => option.label}
      value={coach}
      onChange={(_, newValue) => {
        setCoach(newValue);
      }}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderInput={(params) => (
        <TextField
          required={required}
          color="secondary"
          {...params}
          label="Coach"
          error={error && !coach}
        />
      )}
    />
  );
};

export default SelectCoachInput;
