import Section from "../../../../components/section/section";
import { Uploads, File } from "../participantFiles";
import RenderHeadline from "./renderHeadline";
import UploadTile from "./uploadTile";

const CoachUploads = ({ uploads }: { uploads: Uploads }): React.JSX.Element => {
  return (
    <Section>
      <RenderHeadline text={"Von Deinem Coach hochgeladene Unterlagen"} />

      {uploads.files.uploadCoach.map((file: File) => (
        <UploadTile key={file.id} file={file} deletable={false} />
      ))}
    </Section>
  );
};

export default CoachUploads;
