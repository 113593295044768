import { Divider } from "@mui/material";
import NoEditableFileMessage from "../components/noEditableFileMessage";
import UploadSection from "./src/uploadSection";
import ParticipantUploads from "./src/participantUploads";
import CoachUploads from "./src/participantCoach";
import React, { useEffect } from "react";
import axios from "axios";
import { User, AppContext } from "../../../context/appContext";
import { endpoints } from "../../../endpoints/endpoints";

export interface File {
  id: string;
  name: string;
  format: {
    label: string;
    value: string;
  };
  size: string;
  uploadTime: string;
  uploadedBy: {
    label: string;
    value: string;
    role: string;
  };
}

export interface Uploads {
  editableFile: boolean;
  files: {
    uploadParticiopant: File[];
    uploadCoach: File[];
  };
}

const ParticipantFiles = (): React.JSX.Element => {
  const {
    user,
  }: {
    user: User;
  } = React.useContext(AppContext);

  const loadFiles = React.useRef(false);
  const [uploads, setUploads] = React.useState({
    editableFile: false,
    files: {
      uploadParticiopant: [],
      uploadCoach: [],
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);

    if (loadFiles.current) {
      return;
    }

    loadFiles.current = true;

    axios
      .post(endpoints.participant.getFiles, {
        sessionId: user.sessionId,
      })

      .then((response) => {
        setUploads(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [user.sessionId]);

  return (
    <>
      {!uploads.editableFile && <NoEditableFileMessage />}

      <UploadSection />

      <Divider />

      <ParticipantUploads uploads={uploads} />

      <Divider />

      <CoachUploads uploads={uploads} />
    </>
  );
};

export default ParticipantFiles;
