import { Typography } from "@mui/material";

const RenderHeadline = ({ text }: { text: string }): React.JSX.Element => {
  return (
    <Typography variant="h5" component="h5" sx={{ mb: 4, textAlign: "center" }}>
      {text}
    </Typography>
  );
};

export default RenderHeadline;
