import { TextField } from "@mui/material";

const InputTime = ({
  focus,
  disabled,
  time,
  setTime,
  label,
  error,
  showHelperText,
  required,
}: {
  focus?: boolean;
  disabled?: boolean;
  time: string;
  setTime: (time: string) => void;
  label: string;
  error?: boolean;
  showHelperText?: boolean;
  required?: boolean;
}): React.JSX.Element => {
  return (
    <TextField
      focused={focus}
      disabled={disabled}
      color="secondary"
      fullWidth
      type="time"
      value={time}
      onChange={(e) => {
        setTime(e.target.value);
      }}
      id="input-time"
      label={label}
      variant="outlined"
      error={error && !time}
      required={required}
      helperText={showHelperText ? "Bitte wähle eine Uhrzeit aus." : ""}
    />
  );
};

export default InputTime;
