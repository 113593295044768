import { Grid, Box, Typography, Button } from "@mui/material";

const WelcomeTextWrapper = (): React.JSX.Element => {
  return (
    <Grid
      item
      lg={7}
      sx={{
        display: "flex",
        flexDirection: "center",
        alignItems: "center",
        textAlign: {
          xs: "center",
          lg: "left",
        },
      }}
    >
      <Box>
        <Typography variant="h4" component="h4" sx={{ mb: 2 }}>
          Herzlich Willkommen im WBS JobMentoring Onlinecoachingportal
        </Typography>

        <Typography variant="body1" component="p" sx={{ mb: 2 }}>
          Ein entscheidender Baustein, um im Bewerbungsprozess punkten zu
          können, sind aktuelle, zielführende und aussagekräftige
          Bewerbungsunterlagen und Souveränität und Sicherheit im
          Vorstellungsgespräch.
        </Typography>

        <Typography variant="body1" component="p" sx={{ mb: 2 }}>
          In diesem Portal erhältst du als Teilnehmer:in der WBS Training
          vielfältige Informationen rund um die Themen der Bewerbung und kannst
          zudem bis zu drei für dich kostenfreie Einzelcoachingtermine mit einem
          unserer Onlinebewerbungscoaches buchen.
        </Typography>

        <Typography variant="h5" component="h5" sx={{ mb: 2 }}>
          Durch Einzelcoaching noch sicherer werden.
        </Typography>

        <Typography variant="body1" component="p" sx={{ mb: 2 }}>
          Registriere dich noch heute und buche deine persönlichen
          Onlinebewerbungscoaching-Termine direkt über dieses Portal
        </Typography>

        <Button
          variant="contained"
          color="secondary"
          sx={{ mb: 2 }}
          onClick={() => {
            const element = document.getElementById("registration");
            element?.scrollIntoView({ behavior: "smooth" });
          }}
        >
          zur Registrierung
        </Button>
      </Box>
    </Grid>
  );
};

export default WelcomeTextWrapper;
