import { Grid, Typography, Link } from "@mui/material";
import RenderEmail from "./renderEmail";
import CoachProps from "../interface/coachProps";

const RenderCoachDetails = ({
  coach,
}: {
  coach: CoachProps;
}): React.JSX.Element => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        mt: 0,
      }}
    >
      <Grid item xs={12} sm={5}>
        <RenderEmail email={coach.email} />
      </Grid>

      <Grid item xs={12} sm={2}>
        <Typography
          variant="caption"
          color="span"
          sx={{
            fontWeight: "bold",
          }}
        >
          CoachID
        </Typography>
        <br />
        <Typography variant="body1" color="initial">
          {coach.id}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant="caption"
          color="span"
          sx={{
            fontWeight: "bold",
          }}
        >
          TeamsLink
        </Typography>
        <br />
        <Typography
          variant="body1"
          color="initial"
          sx={{
            wordBreak: "break-all",
          }}
        >
          <Link
            sx={{
              color: (theme: { palette: { secondary: { main: any } } }) =>
                theme.palette.secondary.main,
              "&:hover": {
                color: (theme: { palette: { primary: { main: any } } }) =>
                  theme.palette.primary.main,
              },
            }}
            target="_blank"
            rel="noreferrer"
            href={coach.teams_link}
          >
            {coach.teams_link}
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default RenderCoachDetails;
