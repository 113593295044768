import Benefits from "./landingpage/benefits/benefits";
import Registration from "./landingpage/registration/registration";
import Welcome from "./landingpage/welcome/welcome";
import GoToLogin from "./landingpage/goToLogin/goToLogin";
import React from "react";
import { User, AppContext } from "../context/appContext";

const Landingpage = (): React.JSX.Element => {
  const {
    user,
  }: {
    user: User;
  } = React.useContext(AppContext);

  return (
    <React.Fragment>
      <Welcome />
      {!user.sessionId && <GoToLogin />}
      <Benefits />
      <Registration />
    </React.Fragment>
  );
};

export default Landingpage;
