interface EnvironmentList {
  name: string;
  url: string;
}

export interface Endpoints {
  admin: {
    searchParticipant: string;
    searchParticipantFull: string;
    appointmentOverview: string;
    createAppointment: string;
    deleteAppointment: string;
    importAppointmentsByFile: string;
    changeActiveStatus: string;
    getCoachListObject: string;
    editCoach: string;
  };
  authentication: {
    login: string;
    registration: string;
    refresh: string;
    resetPassword: string;
    setNewPassword: string;
  };
  appointment: {
    bookable: string;
    booking: string;
    myAppointments: string;
    cancelByParticipant: string;
    cancelByAdmin: string;
  };
  location: {
    getAll: string;
  };
  coach: {
    getAll: string;
    dailyPlan: string;
    searchParticipant: string;
    uploadFiles: string;
    createAppointment: string;
    appointmentOverview: string;
    deleteAppointment: string;
  };
  participant: {
    uploadFiles: string;
    downloadFile: string;
    deleteFile: string;
    getFiles: string;
  };
  public: {
    getCoachId: string;
  };
}

// Define the endpoints for the different API calls.
const url = window.location.href;
const urlParts = url.split("/");
const activeEnvironment = urlParts[2].includes("localhost")
  ? "development"
  : urlParts[2].includes("wbsstage")
  ? "stage"
  : "production";

// Define the different environments
export const environmentList: EnvironmentList[] = [
  {
    name: "development",
    url: "https://bewerbungscoaching.wbstraining.de",
  },
  {
    name: "stage",
    url: "https://bewerbungscoaching.wbsstage.de",
  },
  {
    name: "production",
    url: "https://bewerbungscoaching.wbstraining.de",
  },
];

// get the active environment url
export const api = environmentList.find(
  (env) => env.name === activeEnvironment
)?.url;

export const endpoints: Endpoints = {
  admin: {
    searchParticipant: `${api}/api/service/admin/searchParticipantShort.php`,
    searchParticipantFull: `${api}/api/service/admin/searchParticipantFull.php`,
    appointmentOverview: `${api}/api/service/admin/dailyPlan.php`,
    createAppointment: `${api}/api/service/admin/createOneAppointment.php`,
    deleteAppointment: `${api}/api/service/admin/deleteAppointment.php`,
    importAppointmentsByFile: `${api}/api/service/admin/importAppointmentsByFile.php`,
    changeActiveStatus: `${api}/api/service/admin/changeActiveStatus.php`,
    getCoachListObject: `${api}/api/service/admin/getCoachListObject.php`,
    editCoach: `${api}/api/service/admin/editCoach.php`,
  },
  authentication: {
    login: `${api}/api/service/authentication/login.php`,
    registration: `${api}/api/service/participant/newRegistration.php`,
    refresh: `${api}/api/service/authentication/sessionCheck.php`,
    resetPassword: `${api}/api/service/authentication/needNewPassword.php`,
    setNewPassword: `${api}/api/service/authentication/setNewPassword.php`, //
  },
  appointment: {
    bookable: `${api}/api/service/appointment/bookable.php`,
    booking: `${api}/api/service/appointment/bookAppointment.php`,
    myAppointments: `${api}/api/service/appointment/myAppointment.php`,
    cancelByParticipant: `${api}/api/service/appointment/stornoByParticipant.php`,
    cancelByAdmin: `${api}/api/service/appointment/stornoByAdmin.php`,
  },
  location: {
    getAll: `${api}/api/service/location/getAll.php`,
  },
  coach: {
    getAll: `${api}/api/service/coach/getCoachList.php`,
    dailyPlan: `${api}/api/service/coach/dailyPlan.php`,
    searchParticipant: `${api}/api/service/admin/searchParticipantFull.php`,
    uploadFiles: `${api}/api/service/coach/uploadFiles.php`,
    createAppointment: `${api}/api/service/coach/createOneAppointment.php`,
    appointmentOverview: `${api}/api/service/coach/dailyPlan.php`,
    deleteAppointment: `${api}/api/service/coach/deleteAppointment.php`,
  },
  participant: {
    uploadFiles: `${api}/api/service/participant/uploadFiles.php`,
    downloadFile: `${api}/api/service/participant/downloadFile.php`,
    deleteFile: `${api}/api/service/participant/deleteFile.php`,
    getFiles: `${api}/api/service/participant/getFiles.php`,
  },
  public: {
    getCoachId: `${api}/api/service/public/getCoachId.php`,
  },
};
