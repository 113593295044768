import "./App.css";
import Footer from "./components/footer/footer";
import { RouterProvider } from "react-router-dom";
import { AppContext } from "./context/appContext";
import React, { useContext, useEffect } from "react";
import { SnackbarProvider, MaterialDesignContent } from "notistack";
import { Paper, ThemeProvider, styled } from "@mui/material";
import { darkTheme } from "./theme/themes/darkTheme";
import { lightTheme } from "./theme/themes/lightTheme";

const StyledMaterialDesignContent = styled(MaterialDesignContent)(
  ({ theme }) => ({
    "&.notistack-MuiContent-success": {
      backgroundColor: theme.palette.success.main,
    },
    "&.notistack-MuiContent-error": {
      backgroundColor: theme.palette.error.main,
    },
    "&.notistack-MuiContent-warning": {
      backgroundColor: theme.palette.warning.main,
    },
  })
);

function App() {
  const { routes, colorMode, user } = useContext(AppContext);

  useEffect(() => {
    if (
      user.resetPassword === 1 &&
      !window.location.pathname.includes("change-password")
    ) {
      window.location.href = "/change-password";
    }
  }, [user.resetPassword]);

  return (
    <ThemeProvider theme={colorMode === "light" ? lightTheme : darkTheme}>
      <Paper
        elevation={0}
        sx={{
          borderRadius: 0,
          background: "transparent",
        }}
      >
        <SnackbarProvider
          Components={{
            success: StyledMaterialDesignContent,
            error: StyledMaterialDesignContent,
            warning: StyledMaterialDesignContent,
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <RouterProvider router={routes} />
        </SnackbarProvider>
        <Footer />
      </Paper>
    </ThemeProvider>
  );
}

export default App;
