import { Grid, Typography, Collapse, Box } from "@mui/material";
import transformDateToDe from "../../../../../../functions/transformDateToDe";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";

const RemainingAppointments = ({
  appointment,
  setDayliPlan,
  dayliPlan,
}: {
  appointment: any;
  setDayliPlan: (dayliPlan: any) => void;
  dayliPlan: any;
}): React.JSX.Element => {
  return (
    <Grid sx={{ my: 2 }} xs={12}>
      <Typography
        onClick={() => {
          appointment.showAllAppointments = !appointment.showAllAppointments;

          setDayliPlan({
            ...dayliPlan,
            appointments: [...dayliPlan.appointments],
          });
        }}
        sx={{
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        variant="body1"
        component="p"
      >
        Weitere Termine anzeigen
        {appointment.showAllAppointments ? (
          <KeyboardArrowUpOutlinedIcon />
        ) : (
          <KeyboardArrowDownOutlinedIcon />
        )}
      </Typography>

      <Collapse
        in={appointment.showAllAppointments}
        timeout="auto"
        unmountOnExit
      >
        {appointment.remainingAppointments.map((remainingAppointment: any) => {
          return (
            <Box
              key={remainingAppointment.appointmentId}
              sx={{
                backgroundColor: "#fff",
                p: 2,
                borderRadius: 2,
                mt: 2,
              }}
            >
              <Typography
                variant="caption"
                color="span"
                sx={{
                  fontWeight: "bold",
                }}
              >
                {`${
                  remainingAppointment.appointmentWeekDay
                }, ${transformDateToDe(
                  remainingAppointment.appointmentDate
                )}, ${remainingAppointment.appointmentFromTime
                  .split(":")
                  .slice(0, 2)
                  .join(":")} bis ${remainingAppointment.appointmentTillTime
                  .split(":")
                  .slice(0, 2)
                  .join(":")} Uhr bei ${remainingAppointment.coachName}`}
              </Typography>
            </Box>
          );
        })}
      </Collapse>
    </Grid>
  );
};

export default RemainingAppointments;
