import { TextField } from "@mui/material";

interface PhoneNumberInputProps {
  phoneNumber: string;
  setPhoneNumber?: (phoneNumber: string) => void;
  disabled?: boolean;
  required?: boolean;
}

const renderHelperText = (phoneNumber: string): string => {
  if (phoneNumber.length === 0) {
    return "Bitte gib deine Telefonnummer ein";
  } else if (!phoneNumber.startsWith("0")) {
    return "Telfonnummer muss mit 0 anfangen";
  } else if (phoneNumber.length < 8) {
    return "Telefonnummer muss mindestens 8 Zeichen lang sein";
  }
  return "";
};

const renderError = (phoneNumber: string): boolean => {
  return (
    !phoneNumber.startsWith("0") ||
    phoneNumber.length === 0 ||
    phoneNumber.length < 8
  );
};

const PhoneNumberInput = ({
  phoneNumber,
  setPhoneNumber,
  disabled,
  required,
}: PhoneNumberInputProps): React.JSX.Element => {
  return (
    <TextField
      sx={{
        "& .MuiInputBase-root": {
          color: renderError(phoneNumber)
            ? (theme) => theme.palette.error.main
            : (theme) => theme.palette.success.main,
        },
      }}
      disabled={disabled}
      focused={!renderError(phoneNumber)}
      color="success"
      required={required}
      fullWidth
      type="tel"
      id="PhoneNumberInput"
      label="Telefonnummer"
      variant="outlined"
      value={phoneNumber}
      onChange={(
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => {
        if (setPhoneNumber) {
          setPhoneNumber(event.target.value.replace(/\D/g, ""));
        }
      }}
      error={renderError(phoneNumber)}
      helperText={renderHelperText(phoneNumber)}
    />
  );
};

export default PhoneNumberInput;
