import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { IconButton } from "@mui/material";
import { Participant } from "../interfaces/interfaces";
import axios from "axios";
import { endpoints } from "../../../../endpoints/endpoints";
import { User, AppContext } from "../../../../context/appContext";
import { enqueueSnackbar } from "notistack";

const OptionMenu = ({
  participant,
  startSearch,
}: {
  participant: Participant;
  startSearch: () => void;
}): React.JSX.Element => {
  const {
    user,
  }: {
    user: User;
  } = React.useContext(AppContext);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const changeActiveStatus = () => {
    axios
      .post(endpoints.admin.changeActiveStatus, {
        sessionId: user.sessionId,
        participantId: participant.participantId,
        participantStatus: 1,
      })
      .then((response) => {
        if (response.data.status === "success") {
          startSearch();
          enqueueSnackbar("Teilnehmer erfolgreich inaktiviert", {
            variant: "success",
          });
        } else {
          enqueueSnackbar("Fehler beim inaktivieren des Teilnehmers", {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        console.error("Error changing participant status", error);
      });
  };

  return (
    <React.Fragment>
      <IconButton
        sx={{ mr: 1 }}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {
          <MoreHorizOutlinedIcon
            sx={{
              transform: "rotate(90deg)",
            }}
          />
        }
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          disabled={participant.status === 1}
          onClick={changeActiveStatus}
        >
          {`Teilnehmer ${
            participant.status === 1 ? "ist bereits inaktiv" : "inaktivieren"
          }`}
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default OptionMenu;
