import { Grid, Typography } from "@mui/material";

const TimeSlot = ({ appointment }: { appointment: any }): React.JSX.Element => {
  return (
    <Grid
      item
      xs={6}
      sm={4}
      md={3}
      lg={3}
      sx={{
        mb: 2,
      }}
    >
      <Typography
        sx={{
          fontWeight: "bold",
        }}
        variant="caption"
        component="p"
      >
        Zeitraum
      </Typography>
      <Typography variant="body1" component="p">
        {`${appointment.timeSlot} Uhr`}
      </Typography>
    </Grid>
  );
};

export default TimeSlot;
