import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
} from "@mui/material";
import { Link } from "react-router-dom";

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.text.primary,
}));

const CustomLink = ({
  props,
  onClick,
}: {
  props: {
    href: string;
    text: string;
    icon: JSX.Element;
  };
  onClick: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <StyledLink to={props.href}>
      <ListItem disablePadding onClick={() => onClick(false)}>
        <ListItemButton>
          <ListItemIcon>{props.icon}</ListItemIcon>
          <ListItemText primary={props.text} />
        </ListItemButton>
      </ListItem>
    </StyledLink>
  );
};

export default CustomLink;
