import { Container, Grid } from "@mui/material";
import Heartbanner from "./src/heartbanner";
import AboutWbs from "./src/aboutWbs";
import SponsoredFurtherTraining from "./src/sponsoredFurtherTraining";
import LegalInformation from "./src/legalInformation";
import React from "react";

const Footer = (): React.JSX.Element => {
  return (
    <React.Fragment>
      <Heartbanner />

      <Container>
        <Grid container spacing={8} sx={{ pt: 10, pb: 4, textAlign: "center" }}>
          <Grid item md={5}>
            <SponsoredFurtherTraining />
          </Grid>
          <Grid item md sm={6} xs={12}>
            <AboutWbs />
          </Grid>
          <Grid item md sm={6} xs={12}>
            <LegalInformation />
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default Footer;
