import React from "react";
import PasswordInput from "../components/inputs/password/passwordInput";
import Section from "../components/section/section";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import axios from "axios";
import { User, AppContext } from "../context/appContext";
import { endpoints } from "../endpoints/endpoints";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

const ChangePassword = (): React.JSX.Element => {
  const {
    user,
  }: {
    user: User;
    handleLogout: () => void;
  } = React.useContext(AppContext);

  const navigate = useNavigate();

  const [passwort, setPasswort] = React.useState({
    newPassword: "",
    newPasswordRepeat: "",
  });

  const checkPassword = (): boolean => {
    // Check if new password is at least 8 characters long
    if (passwort.newPassword.length < 8) {
      return true;
    }

    // Check if new password and new password repeat are the same
    if (passwort.newPassword !== passwort.newPasswordRepeat) {
      return true;
    }

    // Check if password contains at least one uppercase letter
    if (!passwort.newPassword.match(/[A-Z]/)) {
      return true;
    }

    // Check if password contains at least one lowercase letter
    if (!passwort.newPassword.match(/[a-z]/)) {
      return true;
    }

    // Check if password contains at least one number
    if (!passwort.newPassword.match(/[0-9]/)) {
      return true;
    }

    // Check if password contains at least one special character
    if (!passwort.newPassword.match(/[^A-Za-z0-9]/)) {
      return true;
    }

    return false;
  };

  const doChange = (e: any): void => {
    e.preventDefault();

    axios
      .post(endpoints.authentication.setNewPassword, {
        newPassword: passwort.newPassword,
        sessionId: user.sessionId,
      })
      .then((res) => {
        if (res.data.meta.status === "success") {
          enqueueSnackbar("Das Passwort wurde erfolgreich geändert!", {
            variant: "success",
          });

          setPasswort(
            Object.assign({}, passwort, {
              newPassword: "",
              newPasswordRepeat: "",
            })
          );

          navigate("/intern");
        } else {
          enqueueSnackbar("Das Passwort konnte nicht geändert werden!", {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar("Das Passwort konnte nicht geändert werden!", {
          variant: "error",
        });
      });
  };

  return (
    <Section>
      <Container maxWidth="sm">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            doChange(e);
          }}
        >
          <Grid container spacing={0}>
            <Grid xs={12} sx={{ my: 2 }}>
              <PasswordInput
                password={passwort.newPassword}
                setPassword={(newPassword) =>
                  setPasswort({ ...passwort, newPassword })
                }
                label={"Neues Passwort"}
                error={checkPassword()}
                helperText={
                  checkPassword()
                    ? "Passwort muss mindestens 8 Zeichen lang sein und mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen enthalten."
                    : ""
                }
              />
            </Grid>

            <Grid xs={12} sx={{ my: 2 }}>
              <PasswordInput
                password={passwort.newPasswordRepeat}
                setPassword={(newPasswordRepeat) =>
                  setPasswort({ ...passwort, newPasswordRepeat })
                }
                label={"Neues Passwort wiederholen"}
                error={checkPassword()}
                helperText={
                  checkPassword() ? "Passwörter stimmen nicht überein." : ""
                }
              />
            </Grid>

            <Grid xs={12} sx={{ my: 2, textAlign: "center" }}>
              <Button
                type="submit"
                disabled={checkPassword()}
                onClick={(e) => doChange(e)}
                variant="contained"
                color="secondary"
              >
                Passwort ändern
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Section>
  );
};

export default ChangePassword;
