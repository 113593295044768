import { TextField } from "@mui/material";

interface EndOfQualificationProps {
  disabled: boolean;
  required: boolean;
  endOfQualification: string;
  startOfQualification: string;
  setEndOfQualification: (endOfQualification: string) => void;
}

const renderError = (
  endOfQualification: string,
  startOfQualification: string
): boolean => {
  return (
    endOfQualification.length === 0 ||
    startOfQualification > endOfQualification ||
    startOfQualification.length === 0
  );
};

const renderHelperText = (
  endOfQualification: string,
  startOfQualification: string
): string => {
  if (endOfQualification.length === 0) {
    return "Bitte gib ein Datum ein";
  } else if (startOfQualification > endOfQualification) {
    return "Das Enddatum muss nach dem Startdatum liegen";
  } else if (startOfQualification.length === 0) {
    return "Bitte gib ein Startdatum ein";
  } else {
    return "";
  }
};

const EndOfQualification = ({
  disabled,
  required,
  endOfQualification,
  startOfQualification,
  setEndOfQualification,
}: EndOfQualificationProps): React.JSX.Element => {
  return (
    <TextField
      sx={{
        "& .MuiOutlinedInput-notchedOutline": {
          borderWidth: renderError(endOfQualification, startOfQualification)
            ? "1px !important "
            : "2px !important",
        },
        "& .MuiInputBase-root": {
          color: renderError(endOfQualification, startOfQualification)
            ? (theme) => theme.palette.error.main
            : (theme) => theme.palette.success.main,
        },
      }}
      disabled={disabled}
      color="success"
      required={required}
      fullWidth
      type="date"
      focused
      value={endOfQualification}
      id="EndOfQualification"
      label="Ende der Qualifizierung"
      variant="outlined"
      onChange={(event) => setEndOfQualification(event.target.value)}
      error={renderError(endOfQualification, startOfQualification)}
      helperText={renderHelperText(endOfQualification, startOfQualification)}
    />
  );
};

export default EndOfQualification;
