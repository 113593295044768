import { createTheme } from "@mui/material";

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    text: {
      primary: "#fff",
      secondary: "#D3D3D3",
    },
    background: {
      default: "#1D1D1D",
      paper: "#2A2A2A",
    },
    primary: {
      main: "#2671B3",
      contrastText: "#fff",
    },
    secondary: {
      main: "#FA7268",
      contrastText: "#fff",
    },
    success: {
      main: "#124D17",
      contrastText: "#fff",
    },
    error: {
      main: "#BD3232",
      contrastText: "#fff",
    },
    info: {
      main: "#17A2B8",
      contrastText: "#fff",
    },
    warning: {
      main: "#F2864A",
      contrastText: "#fff",
    },
  },
});
