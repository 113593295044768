import Section from "../../../components/section/section";
import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Box } from "@mui/material";
import { endpoints } from "../../../endpoints/endpoints";
import axios from "axios";
import { User, AppContext } from "../../../context/appContext";
import { enqueueSnackbar } from "notistack";
import AppointmentTile from "./src/appointmentTile";
import UploadResponse from "./interface/uploadResponse";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const AppointmentUpload = (): React.JSX.Element => {
  const {
    user,
  }: {
    user: User;
  } = React.useContext(AppContext);

  const [appointmentFile, setAppointmentFile] = React.useState<any>([]);

  const isLoading = React.useRef(false);

  const [uploadResponse, setUploadResponse] =
    React.useState<null | UploadResponse>(null);

  const startUpload = (e: any): void => {
    e.preventDefault();
    isLoading.current = true;
    const formData = new FormData();

    formData.append(`file_${user.sessionId}`, appointmentFile);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    axios
      .post(endpoints.admin.importAppointmentsByFile, formData, config)
      .then((res) => {
        if (res.data.status === "error") {
          setUploadResponse(null);
          enqueueSnackbar(res.data.message, {
            variant: "error",
          });
        } else {
          setUploadResponse(res.data);
          enqueueSnackbar("Datei erfolgreich hochgeladen", {
            variant: "success",
          });
        }

        setAppointmentFile([]);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <React.Fragment>
      <Section>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            gap: 2,
          }}
        >
          <Button
            component="label"
            variant="contained"
            startIcon={<CloudUploadIcon />}
            onChange={(event: any) => setAppointmentFile(event.target.files[0])}
          >
            Datei auswählen
            <VisuallyHiddenInput type="file" accept={".csv"} />
          </Button>

          <Button
            variant="contained"
            disabled={appointmentFile.length === 0}
            onClick={(e) => startUpload(e)}
          >
            Upload starten
          </Button>
        </Box>
      </Section>

      {uploadResponse && (
        <Section>
          <h2>Erfolgreiche Importe ({uploadResponse.success.length})</h2>
          {uploadResponse &&
            uploadResponse.success.map((appointment: any) => {
              return (
                <Box key={appointment.data.id}>
                  <AppointmentTile appointment={appointment} />
                </Box>
              );
            })}

          <br />

          <h2>Fehlgeschlagene Importe ({uploadResponse.errors.length})</h2>
          {uploadResponse &&
            uploadResponse.errors.map((appointment: any) => {
              return (
                <Box key={appointment.data.id}>
                  <AppointmentTile appointment={appointment} />
                </Box>
              );
            })}
        </Section>
      )}
    </React.Fragment>
  );
};

export default AppointmentUpload;
