import {
  Box,
  Typography,
  FormControlLabel,
  FormGroup,
  Switch,
  Grid,
} from "@mui/material";

const AppointmentFilter = ({
  showAppointments,
  setShowAppointments,
}: {
  showAppointments: { coaching: boolean; speedcoaching: boolean };
  setShowAppointments: (showAppointments: {
    coaching: boolean;
    speedcoaching: boolean;
  }) => void;
}): React.JSX.Element => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        mb: 4,
        gap: 2,
      }}
    >
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: {
              xs: "center",
              sm: "center",
              md: "flex-end",
            },
            alignContent: "center",
          }}
        >
          <Typography
            variant="body1"
            component="p"
            sx={{
              paddingTop: "8px",
            }}
          >
            Welche Termine sollen angezeigt werden:
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormGroup
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: {
                xs: "center",
                sm: "center",
                md: "flex-start",
              },
              alignContent: "center",
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  disabled={!showAppointments.speedcoaching}
                  color="secondary"
                  checked={showAppointments.coaching}
                  onChange={(e) =>
                    setShowAppointments({
                      ...showAppointments,
                      coaching: e.target.checked,
                    })
                  }
                />
              }
              label="Einzelcoachings"
            />

            <FormControlLabel
              control={
                <Switch
                  disabled={!showAppointments.coaching}
                  color="secondary"
                  checked={showAppointments.speedcoaching}
                  onChange={(e) =>
                    setShowAppointments({
                      ...showAppointments,
                      speedcoaching: e.target.checked,
                    })
                  }
                />
              }
              label="Speedcoachings"
            />
          </FormGroup>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AppointmentFilter;
