import { Grid, Button, Typography } from "@mui/material";
import InputDate from "../../../../components/inputs/date/inputDate";
import Section from "../../../../components/section/section";
import transformDateToDe from "../../../../functions/transformDateToDe";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import DayliPlanProps from "../interface/dayliPlanProps";

const SearchControls = ({
  dayliPlan,
  setDayliPlan,
  fetchAppointments,
  isLoading,
}: {
  dayliPlan: DayliPlanProps;
  setDayliPlan: React.Dispatch<React.SetStateAction<DayliPlanProps>>;
  fetchAppointments: () => void;
  isLoading: boolean;
}): React.JSX.Element => {
  console.log(dayliPlan);

  // export to csv file
  const exportToCsv = (): void => {
    // Define headers for each column
    const headers = [
      "Datum",
      "Zeitfenster",
      "Wochentag",
      "Teilnehmername",
      "Teilnehmer-Email",
      "Teilnehmer-Qualifikationsende",
      "Teilnehmer-Telefonnummer",
      "Teilnehmer-Qualifikation",
    ];

    // remove all chars except numbers from phone number
    const cleanPhoneNumber = (phoneNumber: string): string => {
      return phoneNumber.replace(/[^0-9]/g, "");
    };

    // Combine headers with data using map
    const csvContent = dayliPlan.appointments.map((appointment: any) => {
      return `${transformDateToDe(appointment.date)};${appointment.timeSlot};${
        appointment.weekDay
      };${appointment.participantFullName};${appointment.participantEmail};${
        appointment.participantEndOfQualification !== null &&
        transformDateToDe(appointment.participantEndOfQualification)
      };${cleanPhoneNumber(appointment.participantPhoneNumber)};${
        appointment.participantQualification
      }`;
    });

    // Add headers to the beginning of the CSV content
    csvContent.unshift(headers.join(";")); // Join headers with semicolon separator

    const csv = csvContent.join("\n");

    const csvFile = new Blob([csv], { type: "text/csv" });
    const downloadLink = document.createElement("a");
    downloadLink.download = `Deine Coachingtermine vom ${transformDateToDe(
      dayliPlan.fromDate
    )}-${transformDateToDe(dayliPlan.tillDate)}.csv`;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <Section>
      <Grid container spacing={2}>
        <Grid item xs={12} sm md>
          <InputDate
            disabled={isLoading}
            date={dayliPlan.fromDate}
            setDate={(date) => {
              setDayliPlan({ ...dayliPlan, fromDate: date });
            }}
          />
        </Grid>

        <Grid item xs={12} sm md>
          <InputDate
            disabled={isLoading}
            date={dayliPlan.tillDate}
            setDate={(date) => {
              setDayliPlan({ ...dayliPlan, tillDate: date });
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={3}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 2,
          }}
        >
          <Button
            disabled={isLoading}
            onClick={fetchAppointments}
            sx={{
              height: "100%",
            }}
            fullWidth
            variant="contained"
            color="primary"
          >
            {isLoading ? "Lädt..." : "Suchen"}
          </Button>

          {dayliPlan.appointments.length > 0 && (
            <Button
              disabled={isLoading}
              sx={{
                height: "100%",
              }}
              onClick={exportToCsv}
              variant="contained"
              color="primary"
            >
              <LocalPrintshopOutlinedIcon />
            </Button>
          )}
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            textAlign: "center",
          }}
        >
          <Typography variant="body1" component="p">
            {`Vom ${transformDateToDe(
              dayliPlan.fromDate
            )} bis ${transformDateToDe(dayliPlan.tillDate)} wurden ${
              dayliPlan.appointments.length
            } Termine gefunden`}
          </Typography>
        </Grid>
      </Grid>
    </Section>
  );
};

export default SearchControls;
