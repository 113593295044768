import AppointmentsParticipant from "../../pages/participant/appointments/appointmentsParticipant";
import Booking from "../../pages/participant/booking/booking";
import WelcomeParticipant from "../../pages/participant/welcomeParticipant/welcomeParticipant";
import SiteBanner from "../../components/siteBanner/siteBanner";
import Landingpage from "../../pages/landingpage";
import NotFound from "../../pages/notFound";
import BookingOverview from "../../pages/admin/bookingOverview/bookingOverview";
import AppointmentOverviewAdmin from "../../pages/admin/appointmentOverview/appointmentOverview";
import AppointmentOverviewCoach from "../../pages/coach/appointmentOverview/appointmentOverview";
import DayliPlan from "../../pages/coach/dayliPlan/dayliPlan";
import ParticipantFiles from "../../pages/participant/files/participantFiles";
import SearchParticipantCoach from "../../pages/coach/searchParticipant/searchParticipant";
import SearchParticipantAdmin from "../../pages/admin/searchParticipant/searchParticipant";
import ChangePassword from "../../pages/changePassword";
import NewAppointment from "../../pages/admin/newAppointmen/newAppointment";
import NewAppointmentCoach from "../../pages/coach/newAppointmen/newAppointment";
import AppointmentUpload from "../../pages/admin/appointmentUpload/appointmentUpload";
import Navigation from "../../pages/landingpage/navigation/navigation";
import Profile from "../../pages/profile/profile";
import CoachList from "../../pages/admin/coachList/coachList";

const routeError: React.JSX.Element = (
  <h2>Da ist etwas schiefgelaufen. Bitte versuche es später erneut.</h2>
);

export interface AppRoute {
  path: string;
  element: JSX.Element;
  errorElement: React.JSX.Element;
}

export interface AppRoutes {
  open: AppRoute[];
  participant: AppRoute[];
  coach: AppRoute[];
  admin: AppRoute[];
}

export const appRoutes: AppRoutes = {
  open: [
    {
      path: "/",
      element: (
        <>
          <Navigation />
          <Landingpage />
        </>
      ),
      errorElement: routeError,
    },
    {
      path: "*",
      element: (
        <>
          <Navigation />
          <SiteBanner title={"Seite nicht gefunden"} />
          <NotFound />,
        </>
      ),
      errorElement: routeError,
    },
  ],
  participant: [
    {
      path: "/intern",
      element: (
        <>
          <Navigation />
          <SiteBanner title={"Willkommen beim Bewerbungscoaching"} />
          <WelcomeParticipant />
        </>
      ),
      errorElement: routeError,
    },
    {
      path: "/change-password",
      element: (
        <>
          <Navigation />
          <SiteBanner title={"Passwort ändern"} />
          <ChangePassword />,
        </>
      ),
      errorElement: routeError,
    },
    {
      path: "/booking",
      element: (
        <>
          <Navigation />
          <SiteBanner title={"Termine buchen"} />
          <Booking />
        </>
      ),
      errorElement: routeError,
    },
    {
      path: "/appointments",
      element: (
        <>
          <Navigation />
          <SiteBanner title={"Meine Termine"} />
          <AppointmentsParticipant />
        </>
      ),
      errorElement: routeError,
    },
    {
      path: "/files",
      element: (
        <>
          <Navigation />
          <SiteBanner title={"Meine Unterlagen"} />
          <ParticipantFiles />
        </>
      ),
      errorElement: routeError,
    },
    // {
    //   path: "/infoportal",
    //   element: (
    //     <>
    //       <Navigation />
    //       <SiteBanner title={"Infoportal"} />
    //     </>
    //   ),
    //   errorElement: routeError,
    // },
    {
      path: "/profile",
      element: (
        <>
          <Navigation />
          <SiteBanner title={"Profil"} />
          <Profile />
        </>
      ),
      errorElement: routeError,
    },
    {
      path: "/logout",
      element: (
        <>
          <Navigation />
          <SiteBanner title={"Logout"} />
        </>
      ),
      errorElement: routeError,
    },
  ],

  coach: [
    {
      path: "daily-plan",
      element: (
        <>
          <Navigation />
          <SiteBanner title={"Tagesplan"} />
          <DayliPlan />
        </>
      ),
      errorElement: routeError,
    },
    {
      path: "search-participant-coach",
      element: (
        <>
          <Navigation />
          <SiteBanner title={"Teilnehmersuche"} />
          <SearchParticipantCoach />
        </>
      ),
      errorElement: routeError,
    },
    {
      path: "new-appointment-coach",
      element: (
        <>
          <Navigation />
          <SiteBanner title={"Termin erstellen"} />
          <NewAppointmentCoach />
        </>
      ),
      errorElement: routeError,
    },
    {
      path: "/appointment-overview-coach",
      element: (
        <>
          <Navigation />
          <SiteBanner title={"Terminübersicht"} />
          <AppointmentOverviewCoach />
        </>
      ),
      errorElement: routeError,
    },

    {
      path: "/change-password",
      element: (
        <>
          <Navigation />
          <SiteBanner title={"Passwort ändern"} />
          <ChangePassword />,
        </>
      ),
      errorElement: routeError,
    },
  ],
  admin: [
    {
      path: "/booking-overview",
      element: (
        <>
          <Navigation />
          <SiteBanner title={"Buchungsübersicht"} />
          <BookingOverview />
        </>
      ),
      errorElement: routeError,
    },
    {
      path: "/appointment-overview-admin",
      element: (
        <>
          <Navigation />
          <SiteBanner title={"Terminübersicht"} />
          <AppointmentOverviewAdmin />
        </>
      ),
      errorElement: routeError,
    },
    {
      path: "/change-password",
      element: (
        <>
          <Navigation />
          <SiteBanner title={"Passwort ändern"} />
          <ChangePassword />,
        </>
      ),
      errorElement: routeError,
    },
    {
      path: "/new-appointment-admin",
      element: (
        <>
          <Navigation />
          <SiteBanner title={"Termine erstellen"} />
          <NewAppointment />
        </>
      ),
      errorElement: routeError,
    },
    {
      path: "/new-appointment-upload",
      element: (
        <>
          <Navigation />
          <SiteBanner title={"Termin upload"} />
          <AppointmentUpload />
        </>
      ),
      errorElement: routeError,
    },
    {
      path: "/search-participant-admin",
      element: (
        <>
          <Navigation />
          <SiteBanner title={"Teilnehmersuche"} />
          <SearchParticipantAdmin />
        </>
      ),
      errorElement: routeError,
    },
    {
      path: "/coachlist",
      element: (
        <>
          <Navigation />
          <SiteBanner title={"Coachliste"} />
          <CoachList />
        </>
      ),
      errorElement: routeError,
    },
  ],
};
