import { Box, Button, Typography } from "@mui/material";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";

const LoadMoreButton = ({
  maxShownAppointments,
  setMaxShownAppointments,
}: {
  maxShownAppointments: number;
  setMaxShownAppointments: (maxShownAppointments: number) => void;
}): React.JSX.Element => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        mt: 4,
      }}
    >
      <Button
        onClick={() => setMaxShownAppointments(maxShownAppointments + 5)}
        variant="contained"
        color="secondary"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <ArrowDownwardOutlinedIcon />
        <Typography variant="body1" component="div" sx={{ mx: 2 }}>
          Mehr laden
        </Typography>
        <ArrowDownwardOutlinedIcon />
      </Button>
    </Box>
  );
};

export default LoadMoreButton;
