import { Grid, Typography, Box, Button, IconButton } from "@mui/material";
import OptionMenu from "../optionMenu";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import React from "react";
import { Participant, ShowFileDialogProps } from "../../interfaces/interfaces";

const ListTileHead = ({
  participant,
  setShowFileDialog,
  showParticipantDetails,
  startSearch,
}: {
  participant: Participant;
  setShowFileDialog: React.Dispatch<React.SetStateAction<ShowFileDialogProps>>;
  showParticipantDetails: (participant: Participant) => void;
  startSearch: () => void;
}): React.JSX.Element => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Typography
          variant="caption"
          color="span"
          sx={{
            fontWeight: "bold",
          }}
        >
          Name, Vorname
        </Typography>
        <br />
        <Typography variant="h6" color="initial">
          {participant.fullName}
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          justifyContent: {
            xs: "center",
            md: "flex-end",
          },
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            mr: 3,
          }}
        >
          <Typography
            variant="caption"
            color="span"
            sx={{
              fontWeight: "bold",
            }}
          >
            Status
          </Typography>
          <br />
          <Typography
            variant="body1"
            color={
              participant.status === 0
                ? (theme) => theme.palette.success.main
                : (theme) => theme.palette.error.main
            }
          >
            {participant.status === 0 ? "aktiv" : "inaktiv"}
          </Typography>
        </Box>

        <Button
          sx={{
            mr: 2,
          }}
          endIcon={<FileUploadOutlinedIcon />}
          variant="outlined"
          color="primary"
          onClick={() =>
            setShowFileDialog({
              open: true,
              participant: participant,
            })
          }
        >
          Unterlagen
        </Button>

        <OptionMenu participant={participant} startSearch={startSearch} />

        <IconButton
          aria-label=""
          onClick={() => {
            showParticipantDetails(participant);
          }}
        >
          {
            <KeyboardArrowUpOutlinedIcon
              sx={{
                transition: "transform 0.3s",
                transform: participant.collapse
                  ? "rotate(0deg)"
                  : "rotate(180deg)",
              }}
            />
          }
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default ListTileHead;
