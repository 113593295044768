import { Typography, Box } from "@mui/material";
import React from "react";
import FooterLink from "./footerLink";

const aboutWbsLinks: {
  title: string;
  href: string;
}[] = [
  {
    title: "Unternehmen",
    href: "https://www.wbs-gruppe.de/story-spirit-sinn/",
  },
  {
    title: "Blog",
    href: "https://www.wbstraining.de/ratgeber/",
  },
  {
    title: "Feedback geben",
    href: "https://www.wbstraining.de/bewerten/",
  },
  {
    title: "Arbeitslos melden - Ratgeber",
    href: "https://www.wbstraining.de/ratgeber/hilfe-bei-arbeitslosigkeit/",
  },
  {
    title: "Qualifizierungschancengesetz",
    href: "https://www.wbstraining.de/unternehmen/foerderungen/qualifizierungschancengesetz/",
  },
  {
    title: "Kursnet Bildungsgutschein (BGS)",
    href: "https://www.wbstraining.de/foerderungen/bildungsgutschein/",
  },
];

const AboutWbs = (): React.JSX.Element => {
  return (
    <React.Fragment>
      <Typography
        variant="h5"
        component="h5"
        sx={{ mb: 4 }}
        color={"secondary"}
      >
        Über WBS TRAINING
      </Typography>

      <Box
        sx={{
          listStyle: "none",
        }}
      >
        {aboutWbsLinks.map((link, key: number) => (
          <FooterLink key={key} target="_blank" rel="noopener" href={link.href}>
            <Typography variant="body1" component="li" sx={{ mb: 1 }}>
              {link.title}
            </Typography>
          </FooterLink>
        ))}
      </Box>
    </React.Fragment>
  );
};

export default AboutWbs;
