import Section from "../../../components/section/section";
import NoAppointmentsMessage from "./src/noAppointmentsMessage";
import CancellationText from "./src/cancellationText";
import BookedAppointmentTile from "./src/bookedAppointmentTile";
import React, { useEffect } from "react";
import axios from "axios";
import { endpoints } from "../../../endpoints/endpoints";
import { User, AppContext, Appointment } from "../../../context/appContext";

const AppointmentsParticipant = (): React.JSX.Element => {
  const {
    user,
    myAppointments,
    setMyAppointments,
  }: {
    user: User;
    myAppointments: Appointment[];
    setMyAppointments: React.Dispatch<React.SetStateAction<Appointment[]>>;
  } = React.useContext(AppContext);

  const isLoading = React.useRef(false);

  useEffect(() => {
    if (isLoading.current) {
      return;
    }

    isLoading.current = true;

    axios
      .post(endpoints.appointment.myAppointments, {
        sessionId: user.sessionId,
      })
      .then((response) => {
        setMyAppointments(response.data.data);
        isLoading.current = false;
      })
      .catch((error) => {
        console.error(error);
        isLoading.current = false;
      });
  }, [setMyAppointments, user.sessionId]);

  return (
    <>
      <Section>
        {myAppointments.length === 0 && <NoAppointmentsMessage />}
      </Section>

      <CancellationText />

      <Section>
        {myAppointments.map((appointment: Appointment) => {
          if (appointment.status === "K") return null;
          return (
            <BookedAppointmentTile
              key={appointment.id}
              appointment={appointment}
            />
          );
        })}
      </Section>
    </>
  );
};

export default AppointmentsParticipant;
