import { Box, Typography } from "@mui/material";
import Section from "../components/section/section";
import SentimentDissatisfiedOutlinedIcon from "@mui/icons-material/SentimentDissatisfiedOutlined";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const NotFound = (): React.JSX.Element => {
  const navigate = useNavigate();
  const [count, setCount] = React.useState(5);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount(count - 1);
      if (count === 0) {
        clearInterval(interval);
        navigate("/");
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [count, navigate]);

  return (
    <Section>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "1rem",
        }}
      >
        <SentimentDissatisfiedOutlinedIcon
          sx={{
            fontSize: "10rem",
            color: (theme) => theme.palette.error.main,
          }}
        />
        <Typography
          variant="h2"
          component="h2"
          sx={{ mt: 2, fontWeight: "bold", fontSize: "7rem" }}
          color={(theme) => theme.palette.error.main}
        >
          404
        </Typography>

        <Typography
          variant="h5"
          component="h5"
          sx={{
            fontSize: "2rem",
            textAlign: "center",
          }}
        >
          Seite nicht gefunden!
          <br />
          Du wirst in {count} Sekunden zur Startseite weitergeleitet
        </Typography>
      </Box>
    </Section>
  );
};

export default NotFound;
