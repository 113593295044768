import { Link, styled } from "@mui/material";

const FooterLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: "inherit",
  "&:hover": {
    color: theme.palette.primary.main,
  },
}));

export default FooterLink;
