import { Box, Typography } from "@mui/material";
import React from "react";
import { SearchParticipantProps } from "../interfaces/interfaces";

const FoundCounter = ({
  searchParameters,
}: {
  searchParameters: SearchParticipantProps;
}): React.JSX.Element => {
  return (
    <React.Fragment>
      {searchParameters.data !== null && !searchParameters.loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            my: 2,
          }}
        >
          <Typography variant="body1" color="initial">
            {`${searchParameters.data.length} Teilnehmer:in gefunden`}
          </Typography>
        </Box>
      )}
    </React.Fragment>
  );
};

export default FoundCounter;
