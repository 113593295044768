import { IconButton } from "@mui/material";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";

const ExpandMoreButton = ({
  onClick,
  expand,
}: {
  onClick: () => void;
  expand: boolean;
}): React.JSX.Element => {
  return (
    <IconButton aria-label="" onClick={onClick}>
      {
        <KeyboardArrowUpOutlinedIcon
          sx={{
            transition: "transform 0.3s",
            transform: expand ? "rotate(0deg)" : "rotate(180deg)",
          }}
        />
      }
    </IconButton>
  );
};

export default ExpandMoreButton;
