import { Typography, Divider } from "@mui/material";
import React from "react";
import transformDateToDe from "../../../../functions/transformDateToDe";
import AppointmentProps from "../interface/appointmentProps";

const RenderDateLine = ({
  appointment,
}: {
  appointment: AppointmentProps;
}): React.JSX.Element => {
  return (
    <React.Fragment>
      <Typography
        sx={{
          fontWeight: "bold",
          mt: 5,
        }}
        variant="h6"
        component="p"
      >
        {`${appointment.weekDay} der ${transformDateToDe(appointment.date)}`}
      </Typography>
      <Divider
        sx={{
          mb: 2,
          borderWidth: 1,
          borderRadius: 2,
          borderColor: "secondary.main",
        }}
      />
    </React.Fragment>
  );
};

export default RenderDateLine;
