import { Typography } from "@mui/material";

const HeaderText = (): React.JSX.Element => {
  return (
    <>
      <Typography
        variant="h3"
        component="h3"
        sx={{ mb: 4, textAlign: "center" }}
      >
        Du bist noch nicht dabei? Hier kannst du dich anmelden!
      </Typography>

      <Typography
        variant="body1"
        component="p"
        sx={{ mb: 4, textAlign: "center" }}
      >
        Deine Telefonnummer wird nur verwendet, wenn Dein Coach dich im
        Zusammenhang mit Deinem Coachingtermin telefonisch erreichen muss und
        nicht an Dritte weitergegeben.
      </Typography>

      <Typography
        variant="body1"
        component="p"
        sx={{ mb: 4, textAlign: "center", fontWeight: "bold" }}
      >
        Bitte registriere dich, alle Felder sind Pflichtfelder!
      </Typography>
    </>
  );
};

export default HeaderText;
