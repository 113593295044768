import SegmentIcon from "@mui/icons-material/Segment";
import { IconButton, Typography } from "@mui/material";
import { NavigationProps } from "../../../pages/landingpage/navigation/navigation";

const MenuButton = ({ open, setOpen }: NavigationProps): React.JSX.Element => {
  return (
    <IconButton
      disableRipple
      color="inherit"
      aria-label="open drawer"
      onClick={() => setOpen(!open)}
      edge="start"
    >
      <SegmentIcon
        sx={{
          fontSize: 30,
        }}
      />
      <Typography
        fontSize={18}
        sx={{
          mx: 1,
          display: {
            xs: "none",
            sm: "flex",
          },
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Menü
      </Typography>
    </IconButton>
  );
};

export default MenuButton;
