import { Box, Typography } from "@mui/material";

const SiteBanner = ({ title }: { title: string }) => {
  return (
    <Box
      sx={{
        background: (theme) => theme.palette.primary.main,
      }}
    >
      <Typography
        variant="h6"
        component="h6"
        sx={{
          py: 2,
          color: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default SiteBanner;
