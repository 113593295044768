import React, { useCallback, useEffect } from "react";
import SearchControls from "./src/searchControls";
import DayliPlanProps from "./interface/dayliPlanProps";
import ListView from "./src/listView";
import axios from "axios";
import { endpoints } from "../../../endpoints/endpoints";
import { User, AppContext } from "../../../context/appContext";

const DayliPlan = (): React.JSX.Element => {
  const {
    user,
  }: {
    user: User;
  } = React.useContext(AppContext);

  const [isLoading, setIsLoading] = React.useState(false);

  const [dayliPlan, setDayliPlan] = React.useState<DayliPlanProps>({
    fromDate: new Date().toISOString().split("T")[0],
    tillDate: new Date().toISOString().split("T")[0],
    appointments: [],
  });

  const [fetched, setFetched] = React.useState<boolean>(false);

  const fetchAppointments = useCallback(async () => {
    setIsLoading(true);

    await axios
      .post(endpoints.coach.dailyPlan, {
        sessionId: user.sessionId,
        fromDate: dayliPlan.fromDate,
        tillDate: dayliPlan.tillDate,
      })
      .then((response) => {
        let tmpDate: string = "";

        response.data.data.map((appointment: any) => {
          appointment.showDateLine = false;
          appointment.showAllAppointments = false;

          if (appointment.date !== tmpDate) {
            tmpDate = appointment.date;
            appointment.showDateLine = true;
          }

          return appointment;
        });

        setDayliPlan({ ...dayliPlan, appointments: response.data.data });
        setIsLoading(false);
        setFetched(true);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [dayliPlan, setDayliPlan, user.sessionId]);

  useEffect(() => {
    if (isLoading) return;
    if (dayliPlan.appointments.length > 0) return;
    if (fetched) return;
    fetchAppointments();
  }, [dayliPlan.appointments.length, fetchAppointments, fetched, isLoading]);

  return (
    <React.Fragment>
      <SearchControls
        dayliPlan={dayliPlan}
        setDayliPlan={setDayliPlan}
        fetchAppointments={fetchAppointments}
        isLoading={isLoading}
      />
      <ListView
        dayliPlan={dayliPlan}
        setDayliPlan={setDayliPlan}
        fetchAppointments={fetchAppointments}
      />
    </React.Fragment>
  );
};

export default DayliPlan;
