import { Box, Typography, Menu, MenuItem, Avatar, styled } from "@mui/material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import React from "react";
import { Link } from "react-router-dom";
import { User, AppContext } from "../../../context/appContext";

const CustomAvatar = styled(Avatar)(({ theme }) => ({
  ":hover": {
    background: theme.palette.primary.main,
  },
}));

interface Setting {
  name: string;
  path: string;
}

const profileSettings: Setting[] = [
  {
    name: "Ihr Profil",
    path: "/profile",
  },
  {
    name: "Passwort ändern",
    path: "/change-password",
  },
];

const UserMenu = (): React.JSX.Element => {
  const {
    user,
    handleLogout,
  }: {
    user: User;
    handleLogout: () => void;
    setColorMode: React.Dispatch<React.SetStateAction<"light" | "dark">>;
    colorMode: "light" | "dark";
  } = React.useContext(AppContext);

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 2,
      }}
    >
      <Typography
        variant="body1"
        component="p"
        sx={{
          display: { xs: "none", md: "flex" },
        }}
      >
        {`${user.firstName} ${user.lastName}`}
      </Typography>

      <CustomAvatar
        onClick={handleOpenUserMenu}
        sx={{
          cursor: "pointer",
          bgcolor: (theme) => theme.palette.secondary.main,
          color: (theme) => theme.palette.secondary.contrastText,
        }}
      >
        <SettingsOutlinedIcon />
      </CustomAvatar>

      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {profileSettings.map((setting: Setting, key: number) => (
          <Link
            key={key}
            style={{
              textDecoration: "none",
              color: "black",
            }}
            to={setting.path}
          >
            <MenuItem
              sx={{
                px: 4,
              }}
              onClick={handleCloseUserMenu}
            >
              <Typography variant="body1" textAlign="center">
                {setting.name}
              </Typography>
            </MenuItem>
          </Link>
        ))}

        <MenuItem
          sx={{
            px: 4,
          }}
          onClick={handleLogout}
        >
          <Typography variant="body1" textAlign="center">
            <Link
              style={{
                textDecoration: "none",
                color: "black",
              }}
              to={"/"}
            >
              Abmelden
            </Link>
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default UserMenu;
