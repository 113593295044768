import { Grid } from "@mui/material";
import WelcomeImage from "../../../../assets/landingpage/hero-1.jpeg";

const WelcomeImageWrapper = (): React.JSX.Element => {
  return (
    <Grid
      item
      lg={5}
      sx={{
        display: {
          xs: "none",
          lg: "flex",
        },
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        style={{
          boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
          borderRadius: "10px",
        }}
        width={"100%"}
        src={WelcomeImage}
        alt="WelcomeImage"
      />
    </Grid>
  );
};

export default WelcomeImageWrapper;
