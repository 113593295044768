import { Button } from "@mui/material";
import axios from "axios";
import { endpoints } from "../../../../endpoints/endpoints";
import React from "react";
import { User, AppContext, Appointment } from "../../../../context/appContext";
import { enqueueSnackbar } from "notistack";

const RenderAppointmentOperation = ({
  appointment,
}: {
  appointment: Appointment;
}): React.JSX.Element => {
  const {
    user,
    myAppointments,
    setMyAppointments,
  }: {
    user: User;
    myAppointments: Appointment[];
    setMyAppointments: React.Dispatch<React.SetStateAction<Appointment[]>>;
  } = React.useContext(AppContext);

  const cancelAppointment = () => {
    axios
      .post(endpoints.appointment.cancelByParticipant, {
        sessionId: user.sessionId,
        appointmentId: appointment.id,
      })
      .then((response) => {
        if (response.data.meta.status === "success") {
          const newAppointments = myAppointments.filter(
            (app) => app.id !== appointment.id
          );
          setMyAppointments(newAppointments);
          enqueueSnackbar("Der Termin wurde erfolgreich storniert!", {
            variant: "success",
          });
        } else {
          enqueueSnackbar("Stornierung ist fehlgeschlagen!", {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar("Stornierung ist fehlgeschlagen!", {
          variant: "error",
        });
      });
  };

  return (
    <React.Fragment>
      {appointment.meta.stornoPossible && (
        <Button
          onClick={() => cancelAppointment()}
          fullWidth
          variant="contained"
          color="secondary"
        >
          Stornieren
        </Button>
      )}

      {/* Renders the to coaching button if the appointment is today */}
      {appointment.meta.today && (
        <Button
          href={`${appointment.href}`}
          target="_blank"
          fullWidth
          variant="contained"
          color="secondary"
        >
          Zum Coaching
        </Button>
      )}

      {/* Renders the rating button if the appointment is in the past */}
      {appointment.meta.past && (
        <Button disabled fullWidth variant="contained" color="secondary">
          Beendet
        </Button>
      )}

      {!appointment.meta.stornoPossible &&
        !appointment.meta.past &&
        !appointment.meta.today && (
          <Button disabled fullWidth variant="contained" color="secondary">
            Nicht Stornierbar
          </Button>
        )}
    </React.Fragment>
  );
};

export default RenderAppointmentOperation;
