import { Box } from "@mui/material";
import React from "react";
import LoadMoreButton from "../../../participant/booking/src/loadMoreButton";
import Section from "../../../../components/section/section";
import DayliPlanProps from "../interface/dayliPlanProps";
import FileDialog from "../../searchParticipant/src/fileDialog";
import { ShowFileDialogProps } from "../../searchParticipant/searchParticipant";
import RenderApoointment from "./renderApoointment/renderApoointment";

const ListView = ({
  dayliPlan,
  setDayliPlan,
  fetchAppointments,
}: {
  dayliPlan: DayliPlanProps;
  setDayliPlan: (dayliPlan: DayliPlanProps) => void;
  fetchAppointments: () => void;
}): React.JSX.Element => {
  const [maxShownAppointments, setMaxShownAppointments] =
    React.useState<number>(5);

  const [showFileDialog, setShowFileDialog] =
    React.useState<ShowFileDialogProps>({
      open: false,
      participant: null,
    });

  return (
    <Section>
      {dayliPlan.appointments
        .slice(0, maxShownAppointments)
        .map((appointment: any) => {
          return (
            <Box key={appointment.id}>
              <RenderApoointment
                appointment={appointment}
                setDayliPlan={setDayliPlan}
                dayliPlan={dayliPlan}
                setShowFileDialog={setShowFileDialog}
              />
            </Box>
          );
        })}

      {maxShownAppointments < dayliPlan.appointments.length && (
        <LoadMoreButton
          maxShownAppointments={maxShownAppointments}
          setMaxShownAppointments={setMaxShownAppointments}
        />
      )}

      <FileDialog
        showFileDialog={showFileDialog}
        setShowFileDialog={setShowFileDialog}
        startSearch={() => fetchAppointments()}
      />
    </Section>
  );
};

export default ListView;
