import { Typography } from "@mui/material";

const NoParticipantsFound = (): React.JSX.Element => {
  return (
    <Typography
      variant="h6"
      component="h6"
      sx={{
        textAlign: "center",
      }}
    >
      Es konnten keine Teilnehmer:in gefunden werden
    </Typography>
  );
};

export default NoParticipantsFound;
