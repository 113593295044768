import React from "react";
import StaticData from "./src/staticData";
import Section from "../../components/section/section";

const Profile = (): React.JSX.Element => {
  return (
    <Section>
      <StaticData />
    </Section>
  );
};

export default Profile;
