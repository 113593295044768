import { Box, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { NavigationProps } from "../../../pages/landingpage/navigation/navigation";

const CloseButton = ({ open, setOpen }: NavigationProps): React.JSX.Element => {
  return (
    <Box
      sx={{
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        pt: 2,
      }}
    >
      <IconButton
        disableRipple
        color="inherit"
        aria-label="open drawer"
        onClick={() => setOpen(!open)}
        edge="start"
      >
        <ArrowBackIcon />
        <Typography
          variant="body1"
          component="p"
          sx={{
            ml: 1,
          }}
        >
          schließen
        </Typography>
      </IconButton>
    </Box>
  );
};

export default CloseButton;
