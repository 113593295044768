import React from "react";
import SearchControlls from "./src/searchControlls";
import UserDataView from "./src/userDataView";
import axios from "axios";
import { User, AppContext } from "../../../context/appContext";
import { endpoints } from "../../../endpoints/endpoints";
import {
  ParticipantData,
  SearchCriteria,
  ShowFileDialogProps,
} from "./interface/bookingOverviewInterface";
import FileDialog from "./src/fileDialog";

const BookingOverview = (): React.JSX.Element => {
  const {
    user,
  }: {
    user: User;
  } = React.useContext(AppContext);

  const [showFileDialog, setShowFileDialog] =
    React.useState<ShowFileDialogProps>({
      open: false,
      participant: null,
    });

  const [participantData, setParticipantData] = React.useState<
    ParticipantData[] | null
  >(null);

  const [searchCriteria, setSearchCriteria] = React.useState<SearchCriteria>({
    partipantName: "",
    loading: false,
    date: "",
    coach: {
      label: "Alle",
      value: null,
    },
  });

  const startSearch = (): void => {
    setSearchCriteria({ ...searchCriteria, loading: true });

    axios
      .post(endpoints.admin.searchParticipantFull, {
        sessionId: user.sessionId,
        searchString: searchCriteria.partipantName,
        date: searchCriteria.date,
        coachId: searchCriteria.coach?.value,
      })
      .then((response) => {
        setParticipantData(response.data.data);
        setSearchCriteria({ ...searchCriteria, loading: false });
      })
      .catch((error) => {
        setSearchCriteria({ ...searchCriteria, loading: false });
        console.log(error);
      });
  };

  return (
    <>
      <SearchControlls
        searchCriteria={searchCriteria}
        setSearchCriteria={setSearchCriteria}
        startSearch={startSearch}
      />

      <UserDataView
        participantData={participantData}
        setShowFileDialog={setShowFileDialog}
        startSearch={startSearch}
      />

      <FileDialog
        showFileDialog={showFileDialog}
        setShowFileDialog={setShowFileDialog}
        setParticipantData={setParticipantData}
        startSearch={startSearch}
      />
    </>
  );
};

export default BookingOverview;
