import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect } from "react";
import { endpoints } from "../../../endpoints/endpoints";
import { enqueueSnackbar } from "notistack";

interface LocationInputProps {
  disabled?: boolean;
  required?: boolean;
  location: {
    label: string;
    value: string;
  } | null;
  setLocation: (location: { label: string; value: string } | null) => void;
}

const LocationInput = ({
  disabled,
  required,
  location,
  setLocation,
}: LocationInputProps): React.JSX.Element => {
  const fetching = React.useRef(false);

  const [options, setOptions] = React.useState([]);

  const changeLocation = (
    _event: any,
    option: {
      label: string;
      value: string;
    } | null
  ): void => {
    setLocation(option ?? null);
  };

  const fetchLocationList = async (): Promise<void> => {
    fetching.current = true;

    axios
      .get(endpoints.location.getAll)
      .then((response) => {
        if (response.data.locations) {
          setOptions(response.data.locations);
        } else {
          enqueueSnackbar("Standortliste konnte nicht geladen werden!", {
            variant: "error",
          });
        }
        fetching.current = false;
      })
      .catch(() => {
        enqueueSnackbar("Standortliste konnte nicht geladen werden!", {
          variant: "error",
        });
        fetching.current = false;
      });
  };

  useEffect(() => {
    if (options.length === 0 && !fetching.current) {
      fetchLocationList();
    }
  }, [options]);

  return (
    <Autocomplete
      loading={fetching.current}
      loadingText="Lädt Standortliste..."
      sx={{
        "& .MuiInputBase-root": {
          color: !location
            ? (theme) => theme.palette.error.main
            : (theme) => theme.palette.success.main,
        },
      }}
      disabled={disabled || options.length === 0}
      disablePortal
      id="LocationInput"
      options={options}
      value={location}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      onChange={(event, option) => changeLocation(event, option)}
      renderInput={(params) => (
        <TextField
          focused={!!location}
          color="success"
          required={required}
          error={!location}
          helperText={!location ? "Bitte wähle einen Standort aus" : ""}
          {...params}
          label="Standort"
        />
      )}
    />
  );
};

export default LocationInput;
