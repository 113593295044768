import Section from "../../../components/section/section";
import React from "react";
import AppointmentOverviewList from "./src/appointmentOverviewList";
import Appointment from "./interface/appointment";
import SearchControlls from "./src/searchControls";
import axios from "axios";
import { endpoints } from "../../../endpoints/endpoints";
import SearchControllsProps from "./interface/searchControllsProps";
import { User, AppContext } from "../../../context/appContext";

const AppointmentOverview = (): React.JSX.Element => {
  const [appointments, setAppointments] = React.useState<Appointment[] | null>(
    null
  );

  const {
    user,
  }: {
    user: User;
  } = React.useContext(AppContext);

  const [searchControlls, setSearchControlls] =
    React.useState<SearchControllsProps>({
      date: {
        start: new Date().toISOString().split("T")[0],
        end: new Date().toISOString().split("T")[0],
      },
      coach: {
        label: "Alle",
        value: null,
      },
      status: {
        booked: true,
        free: true,
        missed: true,
      },
      loading: false,
    });

  const startSearch = (): void => {
    setSearchControlls({ ...searchControlls, loading: true });

    axios
      .post(endpoints.public.getCoachId, {
        sessionId: user.sessionId,
      })
      .then((response) => {
        axios
          .post(endpoints.admin.appointmentOverview, {
            sessionId: user.sessionId,
            fromDate: searchControlls.date.start,
            tillDate: searchControlls.date.end,
            coachId: response.data,
            status: {
              booked: searchControlls.status.booked,
              free: searchControlls.status.free,
              missed: searchControlls.status.missed,
              goodwill: false,
            },
          })
          .then((response) => {
            setAppointments(response.data.data);
            setSearchControlls({ ...searchControlls, loading: false });
          })
          .catch((error) => {
            console.error("Error fetching appointment overview", error);
            setSearchControlls({ ...searchControlls, loading: false });
          });
      })
      .catch((error) => {
        console.error("Error fetching appointment overview", error);
        setSearchControlls({ ...searchControlls, loading: false });
      });
  };

  return (
    <Section>
      <SearchControlls
        startSearch={startSearch}
        setSearchControlls={setSearchControlls}
        searchControlls={searchControlls}
      />
      {appointments && (
        <AppointmentOverviewList
          appointments={appointments}
          startSearch={startSearch}
          setAppointments={setAppointments}
        />
      )}
    </Section>
  );
};

export default AppointmentOverview;
