import { Typography, Grid } from "@mui/material";
import Section from "../../../components/section/section";
import participantWelcomeImage from "../../../assets/participant/welcome-image-1.png";

const WelcomeParticipant = (): React.JSX.Element => {
  return (
    <Section>
      <Typography
        variant="h4"
        component="h4"
        sx={{ textAlign: "center", mb: 1 }}
      >
        Willkommen im WBS JobMentoring Onlinecoachingportal
      </Typography>

      <Typography
        variant="h5"
        component="h5"
        sx={{ mb: 4, textAlign: "center" }}
      >
        Unser Unterstützungsangebot auf deinem Weg in den Job
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Typography variant="body1" component="p" sx={{ mb: 2 }}>
            Neben unseren umfangreichen JobMentoring Selbstlernkursen und
            vielfältigen Informationen rund um die Themen der Bewerbung, hast du
            die Möglichkeit, im individuellen Einzelcoaching mit unserer
            ausgebildeten Coach:innen deine Bewerbung auf Erfolgskurs zu
            bringen.
          </Typography>

          <Typography variant="body1" component="p" sx={{ mb: 2 }}>
            Inhalt deines Online-Bewerbungscoachings können all die Themen sein,
            die für dich im Rahmen deines Bewerbungsprozesses wichtig sind. So
            unterstützen dich die Coach:innen neben der Erarbeitung
            aussagekräftiger, zielorientierter Bewerbungsunterlagen auch in der
            Vorbereitung von Vorstellungsgesprächen.
          </Typography>

          <Typography variant="body1" component="p">
            Insgesamt kannst du bis zu drei persönliche Einzelcoachingtermine
            kostenfrei buchen und die Termine auch noch wahrnehmen, wenn deine
            Qualifizierung bereits beendet ist.
          </Typography>
        </Grid>

        <Grid item xs={12} md={5}>
          <img
            width={"100%"}
            style={{ boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)" }}
            src={participantWelcomeImage}
            alt="participantWelcomeImage"
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" component="p" sx={{ mb: 2 }}>
            Allerdings empfehlen wir dir, die Optimierung deiner Unterlagen
            bereits während deiner Qualifizierungzeit, damit du schnellstmöglich
            mit deiner aktiven Arbeitssuche starten kannst.
          </Typography>

          <Typography variant="body1" component="p" sx={{ mb: 2 }}>
            Ergänzend zu deinen Einzelcoachingterminen kannst du für erste,
            nicht allzu private Themen ohne vorherige Terminvereinbarung unser
            Speedcoachingangebot nutzen. Zu den im Buchungskalender benannten
            Zeiten kannst du in dieser offenen Sprechstunde erste Fragen klären.
          </Typography>
        </Grid>
      </Grid>
    </Section>
  );
};

export default WelcomeParticipant;
