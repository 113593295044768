import { Typography } from "@mui/material";
import Section from "../../../components/section/section";
import axios from "axios";
import { endpoints } from "../../../endpoints/endpoints";
import React from "react";
import { User, AppContext } from "../../../context/appContext";
import CoachProps from "./interface/coachProps";
import CoachListProps from "./interface/coachListProps";
import RenderList from "./src/renderList";
import EditCoachProps from "./interface/editCoachProps";
import EditCoachDialog from "./src/editCoachDialog";
import RenderTopBar from "./src/renderTopBar";

const CoachList = (): React.JSX.Element => {
  const {
    user,
  }: {
    user: User;
  } = React.useContext(AppContext);

  const [coachList, setCoachList] = React.useState<CoachListProps>({
    loading: false,
    fetched: false,
    data: [] as CoachProps[],
  });

  const [editCoach, setEditCoach] = React.useState<EditCoachProps>({
    showDialog: false,
    coach: {} as CoachProps,
  });

  const [search, setSearch] = React.useState<string>("");

  const fetchCoachList = React.useCallback(async (): Promise<void> => {
    setCoachList({
      ...coachList,
      loading: true,
    });

    await axios
      .post(endpoints.admin.getCoachListObject, {
        sessionId: user.sessionId,
      })
      .then(async (response) => {
        // add expand property to each coach
        response.data.forEach((coach: CoachProps) => {
          coach.expand = false;
        });

        setCoachList({
          loading: false,
          fetched: true,
          data: response.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }, [coachList, user.sessionId]);

  React.useEffect(() => {
    if (coachList.fetched) return;
    if (coachList.loading) return;
    if (coachList.data.length > 0) return;

    fetchCoachList();
  }, [coachList, fetchCoachList]);

  if (coachList.loading)
    return (
      <Section>
        <Typography variant="h4">Lade Coachs...</Typography>
      </Section>
    );

  return (
    <React.Fragment>
      <RenderTopBar search={search} setSearch={setSearch} />

      <RenderList
        search={search}
        coachList={coachList}
        setEditCoach={setEditCoach}
        setCoachList={setCoachList}
      />

      {editCoach.showDialog && (
        <EditCoachDialog
          editCoach={editCoach}
          setEditCoach={setEditCoach}
          fetchCoachList={fetchCoachList}
        />
      )}
    </React.Fragment>
  );
};

export default CoachList;
