import React from "react";
import SideNavigation from "../../../components/sideNavigation/sideNavigation";
import TopNavigation from "../../../components/topNavigation/topNavigation";

export interface NavigationProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Navigation = (): React.JSX.Element => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TopNavigation open={open} setOpen={setOpen} />
      <SideNavigation open={open} setOpen={setOpen} />
    </>
  );
};

export default Navigation;
