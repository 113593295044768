import React, { createContext, useEffect, useMemo } from "react";
import { createBrowserRouter } from "react-router-dom";
import { appRoutes } from "./routes/appRoutes";
import axios from "axios";
import { endpoints } from "../endpoints/endpoints";
import { enqueueSnackbar } from "notistack";

export const AppContext = createContext<any>({});

interface AppContextProps {
  children: React.JSX.Element;
}

export interface User {
  sessionId: string;
  sessionValidUntil: string;
  id: number | string;
  gender: {
    label: string;
    value: string;
  } | null;
  firstName: string;
  lastName: string;
  role: {
    label: string;
    value: string;
  } | null;
  email: string;
  location: {
    label: string;
    value: string;
  } | null;
  phoneNumber: string;
  startOfQualification: string;
  endOfQualification: string;
  qualification: string;
  resetPassword: number;
}

const emptyUser: User = {
  sessionId: "",
  sessionValidUntil: "",
  id: 0,
  gender: null,
  firstName: "",
  lastName: "",
  role: null,
  email: "",
  location: null,
  phoneNumber: "",
  startOfQualification: "",
  endOfQualification: "",
  qualification: "",
  resetPassword: 0,
};

export interface Appointment {
  id: string;
  date: string;
  start: string;
  end: string;
  day: string;
  type: string;
  coach: string;
  href: string | null;
  status: string;
  meta: {
    today: true;
    past: false;
    missed: false;
    stornoPossible: false;
    rating: null;
  };
}

const AppContextProvider = ({
  children,
}: AppContextProps): React.JSX.Element => {
  const [openLoginDialog, setOpenLoginDialog] = React.useState(false);
  const [openForegtPasswordDialog, setOpenForegtPasswordDialog] =
    React.useState(false);

  const [myAppointments, setMyAppointments] = React.useState<Appointment[]>([]);

  const [colorMode, setColorMode] = React.useState<"light" | "dark">("light");

  const [user, setUser] = React.useState<User>(emptyUser);

  const refreshUser = React.useRef(false);

  const [routes, setRoutes] = React.useState<any>(
    createBrowserRouter(appRoutes.open)
  );

  useEffect(() => {
    if (refreshUser.current) return;

    const storedSessionId = localStorage.getItem("sessionId");

    if (storedSessionId) {
      refreshUser.current = true;
      axios
        .post(endpoints.authentication.refresh, { sessionId: storedSessionId })
        .then((res) => {
          if (res.data.data) {
            setUser(res.data.data);
          } else {
            enqueueSnackbar("Du wurdest automatisch abgemeldet!", {
              variant: "error",
            });
            localStorage.removeItem("sessionId");
            setUser(emptyUser);
          }

          refreshUser.current = false;
        })
        .catch((err) => {
          console.log(err);
          refreshUser.current = false;
        });
    }
  }, []);

  useEffect(() => {
    switch (user.role?.value) {
      // participant case
      case "676a0d5f-e974-4321-80ba-d464ca3f1df9":
        setRoutes(
          createBrowserRouter([...appRoutes.open, ...appRoutes.participant])
        );
        break;

      // coach case
      case "c751ded5-7446-4d9c-8ff6-20e95ebaf716":
        setRoutes(
          createBrowserRouter([
            ...appRoutes.open,
            ...appRoutes.participant,
            ...appRoutes.coach,
          ])
        );
        break;

      // admin case
      case "8779046b-1003-42a4-982a-a676d0c6d268":
        setRoutes(
          createBrowserRouter([
            ...appRoutes.open,
            ...appRoutes.participant,
            ...appRoutes.coach,
            ...appRoutes.admin,
          ])
        );

        break;

      default:
        setRoutes(createBrowserRouter(appRoutes.open));
        break;
    }
  }, [user.role]);

  const handleLogout = () => {
    localStorage.removeItem("sessionId");
    setUser(emptyUser);
  };

  const value: {
    user: User;
    setUser: React.Dispatch<React.SetStateAction<User>>;
    openLoginDialog: boolean;
    setOpenLoginDialog: React.Dispatch<React.SetStateAction<boolean>>;
  } = useMemo(
    () => ({
      user,
      setUser,
      openLoginDialog,
      setOpenLoginDialog,
      routes,
      handleLogout,
      colorMode,
      setColorMode,
      openForegtPasswordDialog,
      setOpenForegtPasswordDialog,
      myAppointments,
      setMyAppointments,
    }),
    [
      user,
      openLoginDialog,
      routes,
      colorMode,
      openForegtPasswordDialog,
      myAppointments,
    ]
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default AppContextProvider;
