import { Grid, Typography, Button } from "@mui/material";
import Section from "../../../../components/section/section";
import AttachmentIcon from "@mui/icons-material/Attachment";
import transformDateToDe from "../../../../functions/transformDateToDe";
import {
  ParticipantData,
  ShowFileDialogProps,
} from "../interface/bookingOverviewInterface";
import NoParticipantsFound from "./noParticipantsFound";
import AppointmentList from "./appointmentList";

const UserDataView = ({
  participantData,
  setShowFileDialog,
  startSearch,
}: {
  participantData: ParticipantData[] | null;
  setShowFileDialog: (showFileDialog: ShowFileDialogProps) => void;
  startSearch: () => void;
}): React.JSX.Element => {
  return (
    <Section>
      {participantData !== null && participantData.length === 0 && (
        <NoParticipantsFound />
      )}

      {participantData !== null &&
        participantData.map((participant) => {
          return (
            <Grid
              key={participant.participantId}
              container
              rowSpacing={2}
              sx={{
                background: "#eee",
                borderRadius: 2,
                mb: 4,
                p: 2,
                pb: 4,
              }}
            >
              <Grid item xs={12} sm={6} lg={4}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                  }}
                  variant="caption"
                  component="p"
                >
                  Teilnehmer:in
                </Typography>
                <Typography variant="body1" component="p">
                  {participant.fullName}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                  }}
                  variant="caption"
                  component="p"
                >
                  Qualifizierung
                </Typography>
                <Typography variant="body1" component="p">
                  {participant.qualification}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                  }}
                  variant="caption"
                  component="p"
                >
                  Email
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  sx={{
                    wordBreak: "break-all",
                  }}
                >
                  {participant.email}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                  }}
                  variant="caption"
                  component="p"
                >
                  Telefonnummer
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  sx={{
                    wordBreak: "break-all",
                  }}
                >
                  {participant.phoneNumber}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                  }}
                  variant="caption"
                  component="p"
                >
                  Zeitraum
                </Typography>
                <Typography variant="body1" component="p">
                  {`${transformDateToDe(
                    participant.startOfQualification
                  )} bis ${transformDateToDe(participant.endOfQualification)}`}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                  }}
                  variant="caption"
                  component="p"
                >
                  Unterlagen
                </Typography>
                <Button
                  onClick={() =>
                    setShowFileDialog({
                      open: true,
                      participant,
                    })
                  }
                  startIcon={<AttachmentIcon />}
                  variant="contained"
                  color="secondary"
                  size="small"
                  disabled={participant.files.meta.total === 0}
                >
                  {participant.files.data.length > 0
                    ? "Ansehen"
                    : "Keine Unterlagen vorhanden"}
                </Button>
              </Grid>

              <Grid item xs={12}>
                <AppointmentList
                  participant={participant}
                  startSearch={startSearch}
                />
              </Grid>
            </Grid>
          );
        })}
    </Section>
  );
};

export default UserDataView;
