import { Typography } from "@mui/material";
import React from "react";

const RenderEmail = ({ email }: { email: string }): React.JSX.Element => {
  return (
    <React.Fragment>
      <Typography
        variant="caption"
        color="span"
        sx={{
          fontWeight: "bold",
        }}
      >
        Email
      </Typography>
      <br />
      <Typography variant="body1" color="initial">
        {email}
      </Typography>
    </React.Fragment>
  );
};

export default RenderEmail;
