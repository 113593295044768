import { Box, Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import React from "react";
import axios from "axios";
import { endpoints } from "../../../endpoints/endpoints";
import EmailInput, {
  checkEmail,
} from "../../../components/inputs/email/emailInput";
import EmailRepeatInput from "../../../components/inputs/email/emailRepeatInput";
import EndOfQualification from "../../../components/inputs/endOfQualification/endOfQualificationInput";
import FirstNameInput from "../../../components/inputs/firstName/firstNameInput";
import GenderInput from "../../../components/inputs/gender/genderInput";
import LastNameInput from "../../../components/inputs/lastName/lastNameInput";
import LocationInput from "../../../components/inputs/location/locationInput";
import PhoneNumberInput from "../../../components/inputs/phoneNumber/phoneNumberInput";
import QualificationInput from "../../../components/inputs/qualification/qualificationInput";
import StartOfQualification from "../../../components/inputs/startOfQualification/startOfQualificationInput";
import HeaderText from "./src/headerText";
import RegistrationResultDialog from "./src/registrationResultDialog";
import RegistrationSubmitButton from "./src/registrationSubmitButton";

export interface RegistrationDTO {
  gender: {
    label: string;
    value: string;
  } | null;
  firstName: string;
  lastName: string;
  email: string;
  repeatEmail: string;
  phoneNumber: string;
  qualification: string;
  location: {
    label: string;
    value: string;
  } | null;
  startOfQualification: string;
  endOfQualification: string;
}

export interface ResultDialogDTO {
  open: boolean;
  email: string;
  status: "success" | "error" | null;
  message: string | null;
}

const Registration = (): React.JSX.Element => {
  const [registrationDTO, setRegistrationDTO] = React.useState<RegistrationDTO>(
    {
      gender: null,
      firstName: "",
      lastName: "",
      email: "",
      repeatEmail: "",
      phoneNumber: "",
      qualification: "",
      location: null,
      startOfQualification: "",
      endOfQualification: "",
    }
  );

  const [registrationResultDialogDTO, setRegistrationResultDialogDTO] =
    React.useState<ResultDialogDTO>({
      open: false,
      email: "",
      status: null,
      message: null,
    });

  const [registrationIsLoading, setRegistrationIsLoading] =
    React.useState(false);

  const returnMessage = (returnDTO: {
    meta: {
      status: string;
      message: string;
    };
    data: any[];
  }): void => {
    switch (returnDTO.meta.status) {
      case "success":
        // Set success dialog
        setRegistrationResultDialogDTO({
          ...registrationResultDialogDTO,
          open: true,
          email: registrationDTO.email,
          status: "success",
          message: null,
        });

        // Reset form
        setRegistrationDTO({
          gender: null,
          firstName: "",
          lastName: "",
          email: "",
          repeatEmail: "",
          phoneNumber: "",
          qualification: "",
          location: null,
          startOfQualification: "",
          endOfQualification: "",
        });

        break;
      case "error":
        // Set error dialog
        setRegistrationResultDialogDTO({
          ...registrationResultDialogDTO,
          open: true,
          email: registrationDTO.email,
          status: "error",
          message: returnDTO.meta.message,
        });
        break;
      default:
        // Set error dialog
        setRegistrationResultDialogDTO({
          ...registrationResultDialogDTO,
          open: true,
          email: registrationDTO.email,
          status: "error",
          message: "Unbekannter Fehler",
        });
        break;
    }
  };

  const sendRegistration = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setRegistrationIsLoading(true);

    axios
      .post(endpoints.authentication.registration, registrationDTO)
      .then((response) => {
        returnMessage(response.data);
        setRegistrationIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        // fallback error message
        setRegistrationResultDialogDTO({
          ...registrationResultDialogDTO,
          open: true,
          status: "error",
          message: "Unbekannter Fehler",
        });
        setRegistrationIsLoading(false);
      });
  };

  const checkForm = () => {
    // Validate for empty or null values
    for (const key in registrationDTO) {
      if (
        registrationDTO[key as keyof RegistrationDTO] === "" ||
        registrationDTO[key as keyof RegistrationDTO] === null
      ) {
        return false;
      }
    }

    // Validate email matching
    if (registrationDTO.email !== registrationDTO.repeatEmail) {
      return false;
    }

    // Validate qualification dates
    if (
      registrationDTO.startOfQualification > registrationDTO.endOfQualification
    ) {
      return false;
    }

    // Validate phone number length
    if (registrationDTO.phoneNumber.length < 8) {
      return false;
    }

    // Validate email format
    if (
      registrationDTO.email.includes("wbsedu") ||
      !checkEmail(registrationDTO.email)
    ) {
      return false;
    }

    // If all validations pass, return true
    return true;
  };

  return (
    <Box
      sx={{
        py: 10,
        background: "#eeeeee",
      }}
      id="registration"
    >
      <Container maxWidth="lg">
        <HeaderText />

        <form onSubmit={sendRegistration}>
          <Grid container spacing={4} sx={{ mt: 3 }}>
            <Grid item xs={12} sm={6} md={2}>
              <GenderInput
                disabled={registrationIsLoading}
                required
                gender={registrationDTO.gender}
                setGender={(
                  option: {
                    label: string;
                    value: string;
                  } | null
                ) => {
                  setRegistrationDTO({
                    ...registrationDTO,
                    gender: option,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={5}>
              <FirstNameInput
                disabled={registrationIsLoading}
                required
                firstName={registrationDTO.firstName}
                setFirstName={(firstName: string) =>
                  setRegistrationDTO({
                    ...registrationDTO,
                    firstName: firstName,
                  })
                }
              />
            </Grid>

            <Grid item xs={12} sm={12} md={5}>
              <LastNameInput
                disabled={registrationIsLoading}
                required
                lastName={registrationDTO.lastName}
                setLastName={(lastName: string) =>
                  setRegistrationDTO({
                    ...registrationDTO,
                    lastName: lastName,
                  })
                }
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <EmailInput
                required
                disabled={registrationIsLoading}
                email={registrationDTO.email}
                error
                setEmail={(email: string) =>
                  setRegistrationDTO({
                    ...registrationDTO,
                    email: email,
                  })
                }
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <EmailRepeatInput
                required
                error
                disabled={registrationIsLoading}
                email={registrationDTO.email}
                repeatEmail={registrationDTO.repeatEmail}
                setRepeatEmail={(repeatEmail: string) =>
                  setRegistrationDTO({
                    ...registrationDTO,
                    repeatEmail: repeatEmail,
                  })
                }
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <PhoneNumberInput
                required
                disabled={registrationIsLoading}
                phoneNumber={registrationDTO.phoneNumber}
                setPhoneNumber={(phoneNumber: string) =>
                  setRegistrationDTO({
                    ...registrationDTO,
                    phoneNumber: phoneNumber,
                  })
                }
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <LocationInput
                required
                disabled={registrationIsLoading}
                location={registrationDTO.location}
                setLocation={(
                  location: { label: string; value: string } | null
                ) =>
                  setRegistrationDTO({
                    ...registrationDTO,
                    location: location,
                  })
                }
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <QualificationInput
                required
                disabled={registrationIsLoading}
                qualification={registrationDTO.qualification}
                setQualification={(qualification: string) =>
                  setRegistrationDTO({
                    ...registrationDTO,
                    qualification: qualification,
                  })
                }
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <StartOfQualification
                disabled={registrationIsLoading}
                required
                startOfQualification={registrationDTO.startOfQualification}
                endOfQualification={registrationDTO.endOfQualification}
                setStartOfQualification={(startOfQualification: string) => {
                  setRegistrationDTO({
                    ...registrationDTO,
                    startOfQualification: startOfQualification,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <EndOfQualification
                disabled={registrationIsLoading}
                required
                endOfQualification={registrationDTO.endOfQualification}
                startOfQualification={registrationDTO.startOfQualification}
                setEndOfQualification={(endOfQualification: string) => {
                  setRegistrationDTO({
                    ...registrationDTO,
                    endOfQualification: endOfQualification,
                  });
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                textAlign: "center",
              }}
            >
              <RegistrationSubmitButton
                registrationIsLoading={registrationIsLoading}
                checkForm={checkForm}
              />
            </Grid>
          </Grid>
        </form>
      </Container>

      <RegistrationResultDialog
        registrationResultDialogDTO={registrationResultDialogDTO}
        setRegistrationResultDialogDTO={setRegistrationResultDialogDTO}
      />
    </Box>
  );
};

export default Registration;
