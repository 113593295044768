import { IconButton } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

const EditButton = ({
  onClick,
}: {
  onClick: () => void;
}): React.JSX.Element => {
  return (
    <IconButton aria-label="edit-button" onClick={onClick}>
      <EditOutlinedIcon />
    </IconButton>
  );
};

export default EditButton;
