import { TextField } from "@mui/material";
import { checkEmail } from "./emailInput";

interface EmailRepeatInputProps {
  disabled?: boolean;
  required?: boolean;
  email: string;
  repeatEmail: string;
  setRepeatEmail: (repeatEmail: string) => void;
  error?: boolean;
}

const EmailRepeatInput = ({
  disabled,
  required,
  email,
  repeatEmail,
  setRepeatEmail,
  error,
}: EmailRepeatInputProps): React.JSX.Element => {
  return (
    <TextField
      sx={{
        "& .MuiInputBase-root": {
          color:
            !checkEmail(repeatEmail, error) || repeatEmail !== email
              ? (theme) => theme.palette.error.main
              : (theme) => theme.palette.success.main,
        },
      }}
      disabled={disabled}
      focused={checkEmail(repeatEmail, error)}
      color="success"
      required={required}
      fullWidth
      type="email"
      id="EmailRepeatInput"
      label="Email Adresse wiederholen"
      variant="outlined"
      value={repeatEmail}
      onChange={(event) => {
        setRepeatEmail(event.target.value);
      }}
      error={!checkEmail(repeatEmail, error) || email !== repeatEmail}
      helperText={
        email.includes("wbsedu")
          ? "Email Adresse darf nicht 'wbsedu' enthalten"
          : !checkEmail(repeatEmail, error)
          ? "Bitte gib eine gültige Email Adresse ein"
          : email !== repeatEmail
          ? "Die Email Adressen stimmen nicht überein"
          : ""
      }
    />
  );
};

export default EmailRepeatInput;
