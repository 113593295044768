import { Grid, Typography } from "@mui/material";

const Email = ({ appointment }: { appointment: any }): React.JSX.Element => {
  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={3}
      lg={3}
      sx={{
        mb: 2,
      }}
    >
      <Typography
        sx={{
          fontWeight: "bold",
        }}
        variant="caption"
        component="p"
      >
        Email
      </Typography>
      <Typography variant="body1" component="p">
        {appointment.participantEmail}
      </Typography>
    </Grid>
  );
};

export default Email;
