import { Box, Container, Grid, styled } from "@mui/material";
import wbsLogo from "../../assets/wbs-training-logo.jpg";
import React from "react";
import MenuButton from "./src/menuButton";
import LoginButton from "./src/loginButton";
import { AppContext, User } from "../../context/appContext";
import UserMenu from "./src/userMenu";
import LoginDialog from "./src/loginDialog";
import { NavigationProps } from "../../pages/landingpage/navigation/navigation";

const NavLogo = styled("img")(() => ({
  width: 150,
  margin: "0 10px",
}));

const TopNavigation = ({
  open,
  setOpen,
}: NavigationProps): React.JSX.Element => {
  const {
    user,
  }: {
    user: User;
    setColorMode: React.Dispatch<React.SetStateAction<"light" | "dark">>;
    colorMode: "light" | "dark";
  } = React.useContext(AppContext);

  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "15px 20px",
        }}
      >
        <Grid container spacing={0}>
          <Grid
            item
            xs={2}
            sm={4}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <MenuButton open={open} setOpen={setOpen} />
          </Grid>

          <Grid
            item
            xs={8}
            sm={4}
            sx={{
              textAlign: "center",
            }}
          >
            <NavLogo src={wbsLogo} alt="wbsLogo" />
          </Grid>

          <Grid
            item
            xs={2}
            sm={4}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {user.sessionId ? <UserMenu /> : <LoginButton />}
          </Grid>
        </Grid>
      </Box>
      <LoginDialog />
    </Container>
  );
};

export default TopNavigation;
