import { Typography, Box, Divider } from "@mui/material";
import {
  Appointment,
  ParticipantData,
} from "../interface/bookingOverviewInterface";
import AppointmentListView from "./appointmentListView";

const AppointmentList = ({
  participant,
  startSearch
}: {
  participant: ParticipantData;
  startSearch: () => void;
}): React.JSX.Element => {
  return (
    <>
      <Typography
        sx={{
          fontWeight: "bold",
          borderBottom: "1px solid",
          mb: 1,
        }}
        variant="h6"
        component="h6"
      >
        Termine
      </Typography>
      {participant.appointments.data.map(
        (appointment: Appointment, key: number) => {
          return (
            <Box key={key}>
              <AppointmentListView appointment={appointment} number={key} startSearch={startSearch} />

              {key !== participant.appointments.data.length - 1 && (
                <Divider
                  sx={{
                    my: 2,
                  }}
                />
              )}
            </Box>
          );
        }
      )}
    </>
  );
};

export default AppointmentList;
