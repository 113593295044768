import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { Box, Typography } from "@mui/material";

const NoAppointmentsMessage = (): React.JSX.Element => {
  return (
    <Box
      sx={{
        textAlign: "center",
        background: (theme) => theme.palette.error.main,
        borderRadius: 2,
        padding: 2,
        color: (theme) => theme.palette.error.contrastText,
      }}
    >
      <Typography
        variant="h5"
        component="div"
        sx={{
          mb: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
        }}
      >
        <ReportProblemOutlinedIcon
          sx={{
            fontSize: 40,
            color: (theme) => theme.palette.error.contrastText,
          }}
        />
        Du hast noch keine Termine gebucht
        <ReportProblemOutlinedIcon
          sx={{
            fontSize: 40,
            color: (theme) => theme.palette.error.contrastText,
          }}
        />
      </Typography>

      <Typography variant="body1" component="div" sx={{ mb: 2 }}>
        Verfügbare Termine siehst du unter dem Menüpunkt <b>„Termine buchen"</b>
      </Typography>

      <Typography variant="body1" component="div" sx={{ mb: 2 }}>
        Bitte beachte, dass die Buchung von Einzelcoachingterminen erst möglich
        ist, wenn du mindestens eine editierbare Datei hochgeladen hast.
      </Typography>

      <Typography variant="body1" component="div" sx={{ mb: 2 }}>
        Lade also bitte VOR der Terminauswahl unter der Schaltfläche „Meine
        Unterlagen" mindestens eine editierbare Datei hoch (docx, odt) hoch.
        Ergänzend kannst du deinem Coach auch PDF Dateien zur Verfügung stellen.
      </Typography>
    </Box>
  );
};

export default NoAppointmentsMessage;
