import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Chip, Button } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import transformDateToDe from "../../../../functions/transformDateToDe";
import { deDE } from "@mui/x-data-grid/locales";
import BootstrapTooltip from "../../../../components/bootstrapTooltip/bootstrapTooltip";
import Appointment from "../interface/appointment";
import React from "react";
import axios from "axios";
import { endpoints } from "../../../../endpoints/endpoints";
import { User, AppContext } from "../../../../context/appContext";
import { enqueueSnackbar } from "notistack";

const AppointmentOverviewList = ({
  appointments,
  startSearch,
  setAppointments,
}: {
  appointments: Appointment[] | null;
  startSearch: () => void;
  setAppointments: React.Dispatch<React.SetStateAction<Appointment[] | null>>;
}): React.JSX.Element => {
  const {
    user,
  }: {
    user: User;
  } = React.useContext(AppContext);

  const [deleteAppointment, setDeleteAppointment] =
    React.useState<Appointment | null>(null);

  const columns: GridColDef[] = [
    {
      field: "date",
      headerName: "Datum",
      width: 90,
      renderCell: (params: any) => {
        return transformDateToDe(params.value);
      },
    },
    {
      field: "weekDay",
      headerName: "Tag",
      width: 95,
    },
    {
      field: "timeSlot",
      headerName: "Zeitraum",
      minWidth: 145,
      renderCell: (params: any) => {
        return `${params.value.split("-")[0]} bis ${
          params.value.split("-")[1]
        } Uhr`;
      },
    },
    {
      field: "type",
      headerName: "Typ",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "coachFullName",
      headerName: "Coach",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "participantFullName",
      headerName: "Teilnehmer:in",
      minWidth: 200,
      flex: 1,
      renderCell: (params: any) => {
        return params.row.participantFullName
          ? params.row.participantFullName
          : "";
      },
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params: any) => {
        // return empty string if type is not Einzelcoaching
        if (params.row.type !== "Einzelcoaching") {
          return "";
        }

        return (
          <Chip
            color={
              params.value === "F"
                ? "info"
                : params.value === "G"
                ? "success"
                : "error"
            }
            sx={{
              width: "100%",
            }}
            label={params.value}
            component="a"
          />
        );
      },
    },
    {
      field: "controlls",
      headerName: "",
      renderCell: (params: any) => {
        return (
          <BootstrapTooltip
            placement="left"
            title={"Termin löschen"}
            children={
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "right",
                  width: "100%",
                }}
              >
                <Button
                  onClick={() =>
                    deleteAppointment?.id !== params.row.id
                      ? setDeleteAppointment(params.row)
                      : doDeleteAppointment()
                  }
                  variant="contained"
                  color="primary"
                >
                  {deleteAppointment?.id !== params.row.id ? (
                    <DeleteOutlineOutlinedIcon />
                  ) : (
                    "Sicher?"
                  )}
                </Button>
              </Box>
            }
          />
        );
      },
    },
  ];

  const doDeleteAppointment = () => {
    console.log("delete", deleteAppointment);

    axios
      .post(endpoints.admin.deleteAppointment, {
        sessionId: user.sessionId,
        appointmentId: deleteAppointment?.id,
      })
      .then((response) => {
        if (response.data.meta.status === "success") {
          enqueueSnackbar("Termin gelöscht!", {
            variant: "success",
          });
          setDeleteAppointment(null);
          setAppointments(null);
          startSearch();
        } else {
          enqueueSnackbar(response.data.meta.message, {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar("Termin konnte nicht gelöscht werden!", {
          variant: "error",
        });
        console.error(error);
      });
  };

  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <DataGrid
        localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
        rows={appointments || []}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5, 10, 15, 25]}
        disableRowSelectionOnClick
      />
    </Box>
  );
};

export default AppointmentOverviewList;
