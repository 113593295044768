import { Box, Grid, Typography } from "@mui/material";
import transformDateToDe from "../../../../functions/transformDateToDe";
import UploadAppointment from "../interface/uploadAppointment";
import React from "react";

const AppointmentTile = ({
  appointment,
}: {
  appointment: UploadAppointment;
}): React.JSX.Element => {
  return (
    <Box
      sx={{
        backgroundColor:
          appointment.status === "error" ? "error.main" : "success.main",
        color: "white",
        px: 2,
        py: 1,
        mb: 1,
        borderRadius: 2,
      }}
      key={appointment.data.id}
    >
      <Grid container spacing={1}>
        <Grid item xs={6} md={2}>
          <Typography variant="caption" component="div">
            Datum:
          </Typography>
          <Typography variant="body1" component="div">
            {transformDateToDe(appointment.data.date)}
          </Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography variant="caption" component="div">
            Wochentag:
          </Typography>
          <Typography variant="body1" component="div">
            {appointment.data.day}
          </Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <Typography variant="caption" component="div">
            Zeitraum:
          </Typography>
          <Typography variant="body1" component="div">
            {appointment.data.fromTime.slice(0, -3)} bis{" "}
            {appointment.data.tillTime.slice(0, -3)} Uhr
          </Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <Typography variant="caption" component="div">
            Fehler:
          </Typography>
          <Typography variant="body1" component="div">
            {appointment.status === "error" ? appointment.message : "Keine"}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AppointmentTile;
