import * as React from "react";
import Button from "@mui/material/Button";
import { Autocomplete, TextField } from "@mui/material";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import axios from "axios";
import { endpoints } from "../../../../endpoints/endpoints";
import { User, AppContext } from "../../../../context/appContext";
import { Appointment } from "../interface/bookingOverviewInterface";
import { enqueueSnackbar } from "notistack";

const options: {
  label: string;
  value: number;
}[] = [
  {
    label: "Stornieren (normal)",
    value: 0,
  },
  {
    label: "Stornieren (kulanz)",
    value: 1,
  },
  {
    label: "Termin versäumt",
    value: 2,
  },
  {
    label: "Gebucht",
    value: 3,
  },
];

interface SelectedOption {
  label: string;
  value: number;
}

const AppointmentControlls = ({
  appointment,
  startSearch,
}: {
  appointment: Appointment;
  startSearch: () => void;
}): React.JSX.Element => {
  const {
    user,
  }: {
    user: User;
  } = React.useContext(AppContext);

  const [selectedOption, setSelectedOption] =
    React.useState<SelectedOption | null>(null);

  const doCancellation = (appointment: any) => {
    axios
      .post(endpoints.appointment.cancelByAdmin, {
        sessionId: user.sessionId,
        appointmentId: appointment.id,
        action: {
          cancelled: selectedOption?.value === 0,
          goodwill: selectedOption?.value === 1,
          missed: selectedOption?.value === 2,
          booked: selectedOption?.value === 3,
        },
      })
      .then((response) => {
        setSelectedOption(null);

        enqueueSnackbar(
          response.data.meta.status === "success"
            ? "Änderung erfolgreich"
            : "Es ist ein Fehler",
          {
            variant: response.data.meta.status,
          }
        );

        startSearch();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <React.Fragment>
      <Autocomplete
        size="small"
        disablePortal
        isOptionEqualToValue={(option, value) => option.value === value.value}
        id="combo-box-demo"
        fullWidth
        options={options}
        value={selectedOption}
        onChange={(_event: any, newValue: SelectedOption | null) => {
          setSelectedOption(newValue);
        }}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => <TextField {...params} label="Aktion" />}
      />
      <Button
        disabled={selectedOption === null}
        onClick={() => doCancellation(appointment)}
        variant="contained"
        color="primary"
      >
        <CheckBoxOutlinedIcon />
      </Button>
    </React.Fragment>
  );
};

export default AppointmentControlls;
