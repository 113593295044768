import { Typography } from "@mui/material";
import React from "react";
import CoachProps from "../interface/coachProps";

const RenderCoachName = ({
  coach,
}: {
  coach: CoachProps;
}): React.JSX.Element => {
  return (
    <React.Fragment>
      <Typography
        variant="caption"
        color="span"
        sx={{
          fontWeight: "bold",
        }}
      >
        Anrede, Vorname, Name
      </Typography>
      <br />
      <Typography variant="body1" color="initial">
        {coach.gender === "m" ? "Herr" : "Frau"} {coach.vorname},{" "}
        {coach.nachname}
      </Typography>
    </React.Fragment>
  );
};

export default RenderCoachName;
