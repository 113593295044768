import { Box, List } from "@mui/material";
import React, { useEffect } from "react";
import { AppContext } from "../../../context/appContext";
import { LinkProps, linkList } from "../../../context/links/appLinks";
import LinkRoleHeadline from "./linkRoleHeadline";
import CustomLink from "./customLink";

const LinkList = ({
  setOpen,
}: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}): React.JSX.Element => {
  const [links, setLinks] = React.useState<LinkProps[]>(linkList.open);
  const { user } = React.useContext(AppContext);

  useEffect(() => {
    if (user.role) {
      switch (user.role.value) {
        // participant case
        case "676a0d5f-e974-4321-80ba-d464ca3f1df9":
          setLinks([...linkList.open, ...linkList.participant]);
          break;

        // coach case
        case "c751ded5-7446-4d9c-8ff6-20e95ebaf716":
          setLinks([...linkList.open, ...linkList.coach]);
          break;

        // admin case
        case "8779046b-1003-42a4-982a-a676d0c6d268":
          setLinks([...linkList.open, ...linkList.admin]);
          break;

        default:
          setLinks(linkList.open);
          break;
      }
    }
  }, [user]);

  return (
    <List>
      <LinkRoleHeadline text="Allgemeiner Bereich" />

      {links.map((link: LinkProps, linkKey: number) => {
        let newRole = false;

        // check if the previous link has a role and if it is different from the current link role value to determine if a new role headline is needed
        if (linkKey > 0) {
          if (links[linkKey - 1].role?.value !== links[linkKey].role?.value) {
            newRole = true;
          }
        }

        return (
          <Box key={linkKey}>
            {newRole && (
              <LinkRoleHeadline text={`${links[linkKey].role?.label}bereich`} />
            )}

            <CustomLink
              key={linkKey}
              onClick={() => setOpen(false)}
              props={{
                href: link.href,
                text: link.text,
                icon: link.icon,
              }}
            />
          </Box>
        );
      })}
    </List>
  );
};

export default LinkList;
