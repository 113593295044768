import { Grid, Typography, Button } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

const Uploads = ({
  appointment,
  setShowFileDialog,
}: {
  appointment: any;
  setShowFileDialog: (showFileDialog: any) => void;
}): React.JSX.Element => {
  return (
    <Grid
      item
      xs={6}
      sm={6}
      md={6}
      lg={3}
      sx={{
        mb: 2,
      }}
    >
      <Typography
        sx={{
          fontWeight: "bold",
        }}
        variant="caption"
        component="p"
      >
        Uploads
      </Typography>
      <Button
        sx={{
          mr: 2,
        }}
        size="small"
        endIcon={<FileUploadOutlinedIcon />}
        variant="outlined"
        color="primary"
        onClick={() =>
          setShowFileDialog({
            open: true,
            participant: appointment,
          })
        }
      >
        Unterlagen
      </Button>
    </Grid>
  );
};

export default Uploads;
