import { Box, Typography } from "@mui/material";

const RenderFileDetail = ({
  label,
  shownDetail,
}: {
  label: String;
  shownDetail: string;
}): React.JSX.Element => {
  return (
    <Box>
      <Typography variant="caption" component="div">
        {`${label}:`}
      </Typography>
      <Typography variant="body1" component="div">
        {shownDetail}
      </Typography>
    </Box>
  );
};

export default RenderFileDetail;
