import { Typography, Box } from "@mui/material";
import FooterLink from "./footerLink";
import React from "react";

const lawLinks: {
  title: string;
  href: string;
}[] = [
  {
    title: "Impressum",
    href: "https://www.wbstraining.de/unternehmen/impressum/",
  },
  {
    title: "AGB",
    href: "https://www.wbstraining.de/allgemeine-geschaeftsbedingungen/",
  },
  {
    title: "Datenschutz",
    href: "https://www.wbstraining.de/datenschutz/",
  },
];

const LegalInformation = (): React.JSX.Element => {
  return (
    <React.Fragment>
      <Typography
        variant="h5"
        component="h5"
        sx={{ mb: 4 }}
        color={"secondary"}
      >
        Rechtliches
      </Typography>

      <Box
        sx={{
          listStyle: "none",
        }}
      >
        {lawLinks.map((link, key: number) => (
          <FooterLink key={key} target="_blank" rel="noopener" href={link.href}>
            <Typography variant="body1" component="li" sx={{ mb: 1 }}>
              {link.title}
            </Typography>
          </FooterLink>
        ))}
      </Box>
    </React.Fragment>
  );
};

export default LegalInformation;
