import { Typography } from "@mui/material";

const LinkRoleHeadline = ({
  text,
}: {
  text: string;
}): React.JSX.Element | null => {
  return (
    <Typography
      variant="body2"
      component="div"
      sx={{
        color: (theme) => theme.palette.primary.main,
        fontWeight: "bold",
        ml: 2,
        my: 1,
      }}
    >
      {text}
    </Typography>
  );
};

export default LinkRoleHeadline;
