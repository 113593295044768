import {
  Grid,
  Typography,
  Box,
  Button,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Section from "../../../../components/section/section";
import styled from "@emotion/styled";
import React from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import BackupTableOutlinedIcon from "@mui/icons-material/BackupTableOutlined";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import axios from "axios";
import { endpoints } from "../../../../endpoints/endpoints";
import { AppContext, User } from "../../../../context/appContext";
import { enqueueSnackbar } from "notistack";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const UploadSection = (): React.JSX.Element => {
  const {
    user,
  }: {
    user: User;
  } = React.useContext(AppContext);

  const [files, setFiles] = React.useState<any>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const fileUpload = (event: any): void => {
    const uniqueFiles = Array.from(new Set(event.target.files));
    setFiles([...files, ...uniqueFiles]);
  };

  const doUpload = (e: any) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();

    files.forEach((file: any, index: number) => {
      formData.append(`file_${user.sessionId}_${index}`, file);
    });

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    axios
      .post(endpoints.participant.uploadFiles, formData, config)
      .then((response: any) => {
        if (response.data.status === "success") {
          enqueueSnackbar("Dateien erfolgreich hochgeladen", {
            variant: "success",
          });
          window.location.reload();
        } else {
          enqueueSnackbar(response.data.message, {
            variant: "error",
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        enqueueSnackbar("Fehler beim Hochladen der Dateien", {
          variant: "error",
        });
      });
  };

  const checkMaxFileSize = (files: any): boolean => {
    // kommuliere alle Dateigrößen zusammen
    const totalSize = files.reduce((acc: any, file: any) => acc + file.size, 0);

    // return true wenn die Dateigröße kleiner als 10MB ist
    return totalSize > 10000000;
  };

  const getMegaBytes = (bytes: number): string => {
    return (bytes / 1000 / 1000).toFixed(4);
  };

  return (
    <React.Fragment>
      <Section>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Typography variant="caption" component="p">
              Hinweis:
            </Typography>

            <Typography variant="body1" component="p" sx={{ mb: 2 }}>
              Bitte stelle deinem Coach editierbare Unterlagen zur Verfügung,
              denn nur so könnt ihr deine Unterlagen gemeinsam im Termin
              bearbeiten.
            </Typography>

            <Typography variant="body1" component="p" sx={{ mb: 2 }}>
              Bitte lade hier deine derzeit aktuellen Unterlagen (Lebenslauf,
              Beispielanschreiben) hoch. Ergänzend zu deinen Dokumenten im Word
              / Open Office Format kannst du gerne auch weitere Dokumente im PDF
              Format zur Verfügung stellen.
            </Typography>
          </Grid>

          <Grid item md={12}>
            <Typography variant="caption" component="p">
              Neue Datei hochladen:
            </Typography>

            <Typography variant="body1" component="p" sx={{ mb: 2 }}>
              Erlaubte Dateigrösse: 10MB
            </Typography>

            <Typography variant="body1" component="p" sx={{ mb: 2 }}>
              Formate: docx, odt, pdf
            </Typography>
          </Grid>
        </Grid>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 2,
          }}
        >
          <Button
            size="large"
            component="label"
            onChange={(e) => fileUpload(e)}
            variant="contained"
            tabIndex={-1}
            color="secondary"
            startIcon={<BackupTableOutlinedIcon sx={{ mr: 1 }} />}
          >
            Unterlagen auswählen
            <VisuallyHiddenInput
              type="file"
              multiple
              accept="
            application/vnd.openxmlformats-officedocument.wordprocessingml.document,
            application/vnd.oasis.opendocument.text,
            application/pdf,
          "
            />
          </Button>
        </Box>
      </Section>

      <Dialog
        open={files.length > 0}
        onClose={() => setFiles([])}
        aria-labelledby={"file-upload-dialog"}
      >
        <DialogTitle id={"file-upload-dialog-title"}>Ihr Upload</DialogTitle>

        <Divider />

        <DialogContent>
          {files.map((file: any, index: number) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid #ccc",
              }}
            >
              <Typography
                variant="body2"
                component="p"
                sx={{
                  my: 0,
                  padding: "10px",
                }}
              >
                {`${index + 1}. ${file.name}`}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Typography variant="caption" component="p">
                  {getMegaBytes(file.size)} MB
                </Typography>

                <Button
                  onClick={() => {
                    const newFiles = [...files];
                    newFiles.splice(index, 1);
                    setFiles(newFiles);
                  }}
                  variant="text"
                  sx={{
                    color: (theme) => theme.palette.error.main,
                  }}
                >
                  <CloseOutlinedIcon />
                </Button>
              </Box>
            </Box>
          ))}
        </DialogContent>

        <Divider />

        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            gap: 2,
            px: 2,
            pb: 2,
          }}
        >
          <Button onClick={() => setFiles([])} color="primary">
            abbrechen
          </Button>

          <Button
            disabled={isLoading || checkMaxFileSize(files)}
            size="large"
            component="label"
            onClick={doUpload}
            variant="contained"
            tabIndex={-1}
            color="secondary"
            endIcon={<CloudUploadOutlinedIcon />}
          >
            {checkMaxFileSize(files)
              ? `Dateien zu groß (max. 10MB)`
              : isLoading
              ? "Lade hoch..."
              : "Hochladen"}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default UploadSection;
