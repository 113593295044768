import { Grid, Typography } from "@mui/material";
import transformDateToDe from "../../../../../functions/transformDateToDe";
import { Participant } from "../../interfaces/interfaces";
import React from "react";

const ListTileUserData = ({
  participant,
}: {
  participant: Participant;
}): React.JSX.Element => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        mt: 1,
      }}
    >
      <Grid item xs={12} md={6} xl={4} sx={{ wordWrap: "break-word" }}>
        <Typography
          variant="caption"
          color="span"
          sx={{
            fontWeight: "bold",
          }}
        >
          Email
        </Typography>
        <br />
        <Typography variant="body1" color="initial">
          {participant.email}
        </Typography>
      </Grid>

      <Grid item xs={12} md={6} xl={4} sx={{ wordWrap: "break-word" }}>
        <Typography
          variant="caption"
          color="span"
          sx={{
            fontWeight: "bold",
          }}
        >
          Telefon
        </Typography>
        <br />
        <Typography variant="body1" color="initial">
          {participant.phoneNumber}
        </Typography>
      </Grid>

      <Grid item xs={12} md={6} xl={4} sx={{ wordWrap: "break-word" }}>
        <Typography
          variant="caption"
          color="span"
          sx={{
            fontWeight: "bold",
          }}
        >
          Ort
        </Typography>
        <br />
        <Typography variant="body1" color="initial">
          {participant.location}
        </Typography>
      </Grid>

      <Grid item xs={12} md={6} xl={4} sx={{ wordWrap: "break-word" }}>
        <Typography
          variant="caption"
          color="span"
          sx={{
            fontWeight: "bold",
          }}
        >
          Qualifikation
        </Typography>
        <br />
        <Typography variant="body1" color="initial">
          {participant.qualification}
        </Typography>
      </Grid>

      <Grid item xs={12} md={6} xl={4} sx={{ wordWrap: "break-word" }}>
        <Typography
          variant="caption"
          color="span"
          sx={{
            fontWeight: "bold",
          }}
        >
          Beginn
        </Typography>
        <br />
        <Typography variant="body1" color="initial">
          {transformDateToDe(participant.startOfQualification)}
        </Typography>
      </Grid>

      <Grid item xs={12} md={6} xl={4} sx={{ wordWrap: "break-word" }}>
        <Typography
          variant="caption"
          color="span"
          sx={{
            fontWeight: "bold",
          }}
        >
          Ende
        </Typography>
        <br />
        <Typography variant="body1" color="initial">
          {transformDateToDe(participant.endOfQualification)}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ListTileUserData;
