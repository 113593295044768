import { Grid, Typography } from "@mui/material";
import AppointmentControlls from "./appointmentControlls";
import transformDateToDe from "../../../../functions/transformDateToDe";
import { Appointment } from "../interface/bookingOverviewInterface";

interface AppointmentListViewProps {
  appointment: Appointment;
  number: number;
  startSearch: () => void;
}

const AppointmentListView = ({
  appointment,
  number,
  startSearch,
}: AppointmentListViewProps): React.JSX.Element => {
  return (
    <Grid
      container
      spacing={0}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        p: 2,
      }}
    >
      <Grid item xs={1}>
        <Typography
          sx={{
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "1.5rem",
          }}
          variant="body1"
          component="p"
        >
          {`${number + 1}.`}
        </Typography>
      </Grid>

      <Grid item xs={11} spacing={2} container>
        <Grid item xs={11} sm={4} md={2}>
          <Typography
            sx={{
              fontWeight: "bold",
            }}
            variant="caption"
            component="p"
          >
            Datum
          </Typography>
          <Typography variant="body1" component="p">
            {transformDateToDe(appointment.date)}
          </Typography>
        </Grid>

        <Grid item xs={11} sm={4} md={2}>
          <Typography
            sx={{
              fontWeight: "bold",
            }}
            variant="caption"
            component="p"
          >
            Zeitraum
          </Typography>
          <Typography variant="body1" component="p">
            {`${appointment.timeSlot} Uhr`}
          </Typography>
        </Grid>

        <Grid item xs={11} sm={11} md={3}>
          <Typography
            sx={{
              fontWeight: "bold",
            }}
            variant="caption"
            component="p"
          >
            Coach
          </Typography>
          <Typography variant="body1" component="p">
            {appointment.coachFullName}
          </Typography>
        </Grid>

        <Grid item xs={11} sm={11} md={2}>
          <Typography
            sx={{
              fontWeight: "bold",
            }}
            variant="caption"
            component="p"
          >
            Status
          </Typography>
          <Typography
            variant="body1"
            component="p"
            sx={{
              color:
                appointment.status === "V"
                  ? (theme) => theme.palette.error.main
                  : appointment.status === "K"
                  ? (theme) => theme.palette.warning.main
                  : (theme) => theme.palette.success.main,
            }}
          >
            {appointment.status === "V" && "Versäumt"}
            {appointment.status === "G" && "Gebucht"}
            {appointment.status === "K" && "Storniert (Kulanz)"}
          </Typography>
        </Grid>

        <Grid
          item
          xs={11}
          sm={5}
          md={3}
          sx={{
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
            gap: 1,
          }}
        >
          <AppointmentControlls
            appointment={appointment}
            startSearch={startSearch}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AppointmentListView;
