import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CloseButton from "./src/closeButton";
import LinkList from "./src/linkList";
import React from "react";
import { NavigationProps } from "../../pages/landingpage/navigation/navigation";

const drawerWidth: number = 240;

const SideNavigation = ({
  open,
  setOpen,
}: NavigationProps): React.JSX.Element => {
  return (
    <Box>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            border: "none",
          },
        }}
        variant="temporary"
        anchor="left"
        open={open}
        onClose={() => setOpen(false)}
      >
        <CloseButton open={open} setOpen={setOpen} />
        <LinkList setOpen={setOpen} />
      </Drawer>
    </Box>
  );
};

export default SideNavigation;
