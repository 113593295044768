import { Box, Grid, Typography } from "@mui/material";
import CancellationMessage from "./cancellationMessage";
import RenderAppointmentOperation from "./renderAppointmentOperation";
import { Appointment } from "../../../../context/appContext";
import StornoNotPossibleMessage from "./stornoNotPossibleMessage";

const BookedAppointmentTile = ({
  appointment,
}: {
  appointment: Appointment;
}): React.JSX.Element => {
  return (
    <Box
      sx={{
        backgroundColor: "#eeeeee",
        p: 2,
        mb: 2,
        borderRadius: 2,
      }}
    >
      <Grid
        container
        spacing={4}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid item xs={6} sm={4} md>
          <Box>
            <Typography variant="caption" component="div">
              Datum:
            </Typography>
            <Typography variant="body1" component="div">
              {appointment.date.split("-").reverse().join(".")}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6} sm={4} md>
          <Box>
            <Typography variant="caption" component="div">
              Uhrzeit:
            </Typography>
            <Typography variant="body1" component="div">
              {appointment.start.slice(0, -3)} bis{" "}
              {appointment.end.slice(0, -3)} Uhr
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6} sm={4} md>
          <Box>
            <Typography variant="caption" component="div">
              Typ:
            </Typography>
            <Typography variant="body1" component="div">
              {appointment.type}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6} sm={6} md>
          <Box>
            <Typography variant="caption" component="div">
              Coach:
            </Typography>
            <Typography variant="body1" component="div">
              {appointment.coach}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md>
          <RenderAppointmentOperation appointment={appointment} />
        </Grid>

        {!appointment.meta.today &&
          !appointment.meta.stornoPossible &&
          !appointment.meta.past && (
            <Grid item xs={12}>
              <StornoNotPossibleMessage />
            </Grid>
          )}

        {/* Renders the cancellation message if the appointment is today. */}
        {appointment.meta.today && (
          <Grid item xs={12}>
            <CancellationMessage />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default BookedAppointmentTile;
