import { TextField } from "@mui/material";

interface StartOfQualificationProps {
  disabled?: boolean;
  required?: boolean;
  startOfQualification: string;
  endOfQualification: string;
  setStartOfQualification: (startOfQualification: string) => void;
}

const StartOfQualification = ({
  disabled,
  required,
  startOfQualification,
  endOfQualification,
  setStartOfQualification,
}: StartOfQualificationProps): React.JSX.Element => {
  const renderError = () => {
    return (
      startOfQualification.length === 0 ||
      startOfQualification > endOfQualification ||
      endOfQualification.length === 0
    );
  };

  const renderHelperText = () => {
    if (startOfQualification.length === 0) {
      return "Bitte gib ein Datum ein";
    } else if (startOfQualification > endOfQualification) {
      return "Das Startdatum muss vor dem Enddatum liegen";
    } else if (endOfQualification.length === 0) {
      return "Bitte gib ein Enddatum ein";
    } else {
      return "";
    }
  };

  return (
    <TextField
      sx={{
        "& .MuiOutlinedInput-notchedOutline": {
          borderWidth: renderError() ? "1px !important" : "2px !important",
        },
        "& .MuiInputBase-root": {
          color: renderError()
            ? (theme) => theme.palette.error.main
            : (theme) => theme.palette.success.main,
        },
      }}
      disabled={disabled}
      color="success"
      required={required}
      fullWidth
      type="date"
      focused
      value={startOfQualification}
      id="StartOfQualification"
      label="Anfang der Qualifizierung"
      variant="outlined"
      onChange={(event) => setStartOfQualification(event.target.value)}
      error={renderError()}
      helperText={renderHelperText()}
    />
  );
};

export default StartOfQualification;
