import React from "react";
import Section from "../../../components/section/section";
import axios from "axios";
import { User, AppContext } from "../../../context/appContext";
import { endpoints } from "../../../endpoints/endpoints";
import { Box, Collapse, Divider } from "@mui/material";
import FileDialog from "./src/fileDialog";
import {
  Participant,
  SearchParticipantProps,
  ShowFileDialogProps,
} from "./interfaces/interfaces";
import SearchForm from "./src/serachForm";
import FoundCounter from "./src/foundCounter";
import SearchParticipantLoader from "./src/searchParticipantLoader";
import ListTileHead from "./src/listTile/listTileHead";
import ListTileUserData from "./src/listTile/listTileUserData";
import ListTileAppointments from "./src/listTile/listTileAppointments";

const SearchParticipant = (): React.JSX.Element => {
  const [searchParameters, setSearchParameters] =
    React.useState<SearchParticipantProps>({
      participantName: "",
      loading: false,
      data: null,
    });

  const [showFileDialog, setShowFileDialog] =
    React.useState<ShowFileDialogProps>({
      open: false,
      participant: null,
    });

  const {
    user,
  }: {
    user: User;
  } = React.useContext(AppContext);

  const startSearch = (): void => {
    setSearchParameters({
      ...searchParameters,
      loading: true,
    });

    axios
      .post(endpoints.coach.searchParticipant, {
        sessionId: user.sessionId,
        searchString: searchParameters.participantName,
      })
      .then((response) => {
        // formatting the data for better view
        if (response.data.data.length > 0) {
          response.data.data.forEach((participant: Participant) => {
            // adds a collapse property to each participant
            participant.collapse = false;

            // sorts the appointments by date
            participant.appointments.data.sort((a: any, b: any) => {
              return new Date(a.date).getTime() - new Date(b.date).getTime();
            });
          });
        }

        setSearchParameters({
          ...searchParameters,
          data: response.data.data,
          loading: false,
        });
      })
      .catch((error) => {
        setSearchParameters({
          ...searchParameters,
          loading: false,
        });
        console.log(error);
      });
  };

  const showParticipantDetails = (participant: Participant) => {
    const oldParticipants = searchParameters.data;

    if (oldParticipants !== null) {
      oldParticipants.forEach((oldParticipant) => {
        if (oldParticipant.participantId === participant.participantId) {
          oldParticipant.collapse = !oldParticipant.collapse;
        }
      });

      setSearchParameters({
        ...searchParameters,
        data: oldParticipants,
      });
    }
  };

  return (
    <React.Fragment>
      <Section>
        <SearchForm
          startSearch={startSearch}
          setSearchParameters={setSearchParameters}
          searchParameters={searchParameters}
        />
        <FoundCounter searchParameters={searchParameters} />
        <SearchParticipantLoader searchParameters={searchParameters} />
      </Section>

      {searchParameters.data !== null && (
        <Section>
          {searchParameters.data.map((participant: Participant, index) => (
            <Box
              key={index}
              sx={{
                backgroundColor: "#eeeeee",
                p: 2,
                mb: 2,
                borderRadius: 2,
              }}
            >
              <ListTileHead
                participant={participant}
                setShowFileDialog={setShowFileDialog}
                showParticipantDetails={showParticipantDetails}
                startSearch={startSearch}
              />

              <Collapse
                sx={{ my: 2 }}
                key={index}
                in={participant.collapse}
                timeout="auto"
                unmountOnExit
              >
                <Divider />

                <ListTileUserData participant={participant} />
                <ListTileAppointments participant={participant} />
              </Collapse>
            </Box>
          ))}
        </Section>
      )}

      <FileDialog
        showFileDialog={showFileDialog}
        setShowFileDialog={setShowFileDialog}
        startSearch={startSearch}
      />
    </React.Fragment>
  );
};

export default SearchParticipant;
