import { Typography } from "@mui/material";

const SponsoredFurtherTraining = (): React.JSX.Element => {
  return (
    <>
      <Typography
        variant="h5"
        component="h5"
        sx={{ mb: 4 }}
        color={"secondary"}
      >
        Geförderte Weiterbildungen
      </Typography>

      <Typography variant="body1" component="p" sx={{ mb: 4 }}>
        Wir stärken Menschen durch Bildung, z. B. in den Bereichen IT, SAP, CAD,
        Personal, Medien, Kaufmännisches, Sprache oder Gesundheit. Jährlich
        absolvieren über 30.000 Menschen erfolgreich eine Weiterbildung bei WBS
        TRAINING. Unseren Teilnehmerinnen und Teilnehmern vermitteln wir
        professionelles Wissen und Handlungskompetenzen. Mit über 200 Standorten
        und der Möglichkeit einer Teilnahme von zu Hause ist WBS TRAINING immer
        da, wo Menschen lernen möchten.
      </Typography>
    </>
  );
};

export default SponsoredFurtherTraining;
