import Section from "../../../../components/section/section";
import { Uploads, File } from "../participantFiles";
import RenderHeadline from "./renderHeadline";
import UploadTile from "./uploadTile";

const ParticipantUploads = ({
  uploads,
}: {
  uploads: Uploads;
}): React.JSX.Element => {
  return (
    <Section>
      <RenderHeadline text={"Von Dir hochgeladene Unterlagen"} />

      {uploads.files.uploadParticiopant.map((file: File) => (
        <UploadTile
          onDelete={() => window.location.reload()}
          deletable={true}
          key={file.id}
          file={file}
        />
      ))}
    </Section>
  );
};

export default ParticipantUploads;
