import { VisibilityOff, Visibility } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import React from "react";

interface PasswordInputProps {
  disabled?: boolean;
  required?: boolean;
  password: string;
  setPassword?: (lastName: string) => void;
  label: string;
  error?: boolean;
  helperText?: string;
}

const PasswordInput = ({
  disabled,
  required,
  password,
  setPassword,
  label,
  error,
  helperText,
}: PasswordInputProps): React.JSX.Element => {
  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <TextField
      sx={{
        "& .MuiInputBase-root": {
          color:
            password.length === 0
              ? (theme) => theme.palette.error.main
              : (theme) => theme.palette.success.main,
        },
      }}
      disabled={disabled}
      focused={password.length > 0}
      color="success"
      required={required}
      fullWidth
      type={showPassword ? "text" : "password"}
      id="PasswordInput"
      label={label}
      variant="outlined"
      value={password}
      onChange={(e) => {
        if (setPassword) {
          setPassword(e.target.value);
        }
      }}
      error={error}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              onMouseDown={() => setShowPassword(!showPassword)}
              edge="start"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      helperText={helperText}
    />
  );
};

export default PasswordInput;
