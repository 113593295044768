import { TextField } from "@mui/material";

interface SearchParticipantByNameProps {
  name: string;
  onChange?: (name: string) => void;
  required?: boolean;
  disabled?: boolean;
}

const SearchParticipantByName = ({
  name,
  onChange,
  required,
  disabled,
}: SearchParticipantByNameProps): React.JSX.Element => {
  return (
    <TextField
      required={required}
      disabled={disabled}
      color="secondary"
      fullWidth
      type="text"
      id="SearchParticipantByName"
      label="Teilnehmer:in suchen"
      variant="outlined"
      value={name}
      onChange={(e) => {
        if (onChange) {
          onChange(e.target.value);
        }
      }}
    />
  );
};

export default SearchParticipantByName;
