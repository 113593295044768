import { Grid } from "@mui/material";
import RenderDateLine from "../renderDateLine";
import React from "react";
import TimeSlot from "./src/timeSlot";
import FullName from "./src/fullName";
import LastDay from "./src/lastDay";
import PhoneNumber from "./src/phoneNumber";
import Qualification from "./src/qualification";
import RemainingAppointments from "./src/remainingAppointments";
import Uploads from "./src/uploads";
import Email from "./src/email";

const RenderApoointment = ({
  appointment,
  setDayliPlan,
  dayliPlan,
  setShowFileDialog,
}: {
  appointment: any;
  setDayliPlan: (dayliPlan: any) => void;
  dayliPlan: any;
  setShowFileDialog: (showFileDialog: any) => void;
}): React.JSX.Element => {
  return (
    <React.Fragment>
      {appointment.showDateLine && <RenderDateLine appointment={appointment} />}

      <Grid
        container
        spacing={0}
        sx={{
          backgroundColor: "#eee",
          mb: 2,
          px: 2,
          pt: 2,
          borderRadius: 2,
        }}
      >
        <TimeSlot appointment={appointment} />
        <PhoneNumber appointment={appointment} />
        <LastDay appointment={appointment} />
        <Uploads
          appointment={appointment}
          setShowFileDialog={setShowFileDialog}
        />
        <Qualification appointment={appointment} />
        <FullName appointment={appointment} />
        <Email appointment={appointment} />
        <RemainingAppointments
          appointment={appointment}
          setDayliPlan={setDayliPlan}
          dayliPlan={dayliPlan}
        />
      </Grid>
    </React.Fragment>
  );
};

export default RenderApoointment;
