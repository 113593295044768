import { Grid, Typography } from "@mui/material";
import PlagiarismOutlinedIcon from "@mui/icons-material/PlagiarismOutlined";
import FitnessCenterOutlinedIcon from "@mui/icons-material/FitnessCenterOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import HikingOutlinedIcon from "@mui/icons-material/HikingOutlined";
import Section from "../../../components/section/section";

const benefits: {
  id: string;
  title: string;
  description: string;
  icon: JSX.Element;
}[] = [
  {
    id: "c283376a-0ab4-4659-ab3c-6cd9a994545e",
    title: "Top Coaches",
    description:
      "Kompetenz für deinen Berufserfolg. Unsere Bewerbungscoaches sind exzellent ausgebildet und Experten im Bereich Coaching und Personalauswahl. Sie wissen, worauf Recruiter achten und worauf es im Bewerbungsprozess wirklich ankommt. Mache den Perspektivenwechsel!",
    icon: (
      <PlagiarismOutlinedIcon color="secondary" sx={{ fontSize: 60, my: 1 }} />
    ),
  },
  {
    id: "64e85792-dd48-4e14-bfae-75d258e9f9ac",
    title: "Bewerbungsunterlagen-Check",
    description:
      "Anschreiben, Lebenslauf, Zeugnisse. Überzeugende und aussagekräftige Bewerbungsunterlagen sind der erste Schritt zur Einladung zum Bewerbungsgespräch. Unsere Coaches gehen deine Unterlagen sowohl formal als auch inhaltlich mit dir durch und unterstützen dich dabei, deine Stärken zu erkennen und darzustellen. Hole das Beste aus deiner Bewerbungsmappe heraus. Gestalte gemeinsam mit deinem Coach strategisch geschickte Unterlagen für deine spezielle Bewerbungssituation.",
    icon: (
      <FitnessCenterOutlinedIcon
        color="secondary"
        sx={{ fontSize: 60, my: 1 }}
      />
    ),
  },
  {
    id: "da752e81-438f-4942-a887-59773c1e5f5b",
    title: "One-to-One Coachings",
    description:
      "Individuelle Einzelcoachings. Nur du und der Bewerbungscoach: Unsere Coaches arbeiten in individueller Einzelarbeit mit dir. Denn die Vorbereitung auf den Bewerbungsprozess passt in keine Schablone. Je nach Erfahrung, Hintergrund, beruflichen Zielen und der Branche, in der du arbeiten willst, erarbeitet der Coach mit dir passgenaue Unterlagen.",
    icon: (
      <PeopleAltOutlinedIcon color="secondary" sx={{ fontSize: 60, my: 1 }} />
    ),
  },
  {
    id: "b41e54d8-8c8e-41b7-addb-cf4e90c1f9d8",
    title: "Rückenstärkung für dich",
    description:
      "Ein Bewerbungscoaching stärkt dich bei deinen nächsten Karriereschritten. Dein Coach wird dich dabei beraten und dir wertvolle Impulse mitgeben. Als neutraler Berater wird der Coach dich mit Kompetenz, Expertenwissen und mit Herz bei deinen beruflichen Herausforderungen und Vorhaben unterstützen.",
    icon: <HikingOutlinedIcon color="secondary" sx={{ fontSize: 60, my: 1 }} />,
  },
];

const Benefits = (): React.JSX.Element => {
  return (
    <Section>
      <Grid
        container
        spacing={8}
        sx={{
          textAlign: "center",
        }}
      >
        <Grid item xs={12}>
          <Typography variant="h3" component="h3" sx={{ mb: 4 }}>
            Deine Vorteile auf einen Blick
          </Typography>
        </Grid>

        {benefits.map((benefit) => (
          <Grid item md={6} key={benefit.id}>
            {benefit.icon}
            <Typography variant="h5" component="h5" sx={{ my: 2 }}>
              {benefit.title}
            </Typography>
            <Typography variant="body1" component="div">
              {benefit.description}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Section>
  );
};

export default Benefits;
