import { IconButton, Typography } from "@mui/material";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import { AppContext } from "../../../context/appContext";
import React from "react";

const LoginButton = (): React.JSX.Element => {
  const { openLoginDialog, setOpenLoginDialog } = React.useContext(AppContext);

  return (
    <IconButton
      disableRipple
      color="inherit"
      aria-label="open drawer"
      onClick={() => setOpenLoginDialog(!openLoginDialog)}
      edge="start"
    >
      <Typography
        fontSize={18}
        sx={{
          mr: 1,
          display: { xs: "none", sm: "flex" },
        }}
      >
        Log In
      </Typography>
      <LoginOutlinedIcon
        sx={{
          fontSize: 30,
        }}
      />
    </IconButton>
  );
};

export default LoginButton;
