import { TooltipProps, Tooltip, tooltipClasses, styled } from "@mui/material";

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.secondary.main,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.secondary.main,
    padding: 10,
  },
}));

const CustomTooltip = ({
  title,
  children,
  placement,
}: {
  title: string;
  children: React.JSX.Element;
  placement?:
    | "bottom"
    | "left"
    | "right"
    | "top"
    | "bottom-end"
    | "bottom-start"
    | "left-end"
    | "left-start"
    | "right-end"
    | "right-start"
    | "top-end"
    | "top-start"
    | undefined;
}): React.JSX.Element => {
  return (
    <BootstrapTooltip placement={placement} title={title} children={children} />
  );
};

export default CustomTooltip;
