import { Box, IconButton, Typography, Button } from "@mui/material";
import EmailInput from "../../inputs/email/emailInput";
import React from "react";
import { AppContext } from "../../../context/appContext";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { endpoints } from "../../../endpoints/endpoints";

const ForgetPasswordDialog = (): React.JSX.Element => {
  const { openForegtPasswordDialog, setOpenForegtPasswordDialog } =
    React.useContext(AppContext);

  const [newEmail, setNewEmail] = React.useState("");

  const callSetNewPassword = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    axios
      .post(endpoints.authentication.resetPassword, {
        email: newEmail,
      })
      .then((response) => {
        setOpenForegtPasswordDialog(!openForegtPasswordDialog);
        enqueueSnackbar(
          "Wir haben ein neues Passwort an die angegebene Email-Adresse geschickt!",
          {
            variant: "success",
          }
        );
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          padding: "10px 30px",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={() => setOpenForegtPasswordDialog(!openForegtPasswordDialog)}
        >
          <CloseOutlinedIcon />
        </IconButton>
      </Box>

      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Typography
          variant="body1"
          component="p"
          sx={{
            fontWeight: "bold",
            mb: 2,
          }}
        >
          Passwort vergessen
        </Typography>
      </Box>

      <form
        onSubmit={callSetNewPassword}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "5px 30px 30px 30px",
        }}
      >
        <EmailInput
          email={newEmail}
          setEmail={(email: string) => setNewEmail(email)}
        />

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            my: 2,
          }}
        >
          <Button
            disabled={false}
            variant="contained"
            color="primary"
            type="submit"
          >
            Neues Passwort anfordern
          </Button>
        </Box>
      </form>
    </React.Fragment>
  );
};

export default ForgetPasswordDialog;
