import { Box, TextField } from "@mui/material";
import Section from "../../../../components/section/section";

const RenderTopBar = ({
  search,
  setSearch,
}: {
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}): React.JSX.Element => {
  return (
    <Section>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 2,
          width: "100%",
        }}
      >
        <TextField
          size="small"
          color="secondary"
          sx={{
            width: "300px",
          }}
          type="text"
          id="search-Coach"
          label="Coach suchen"
          variant="outlined"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
        {/* <Button
          disabled
          size="large"
          variant="contained"
          color="secondary"
          sx={{
            height: "40px",
          }}
        >
          Coach anlegen
        </Button> */}
      </Box>
    </Section>
  );
};

export default RenderTopBar;
