import { Box, Container, Typography } from "@mui/material";

const CancellationText = (): React.JSX.Element => {
  return (
    <Box
      sx={{
        py: 6,
        background: "#eeeeee",
      }}
    >
      <Container maxWidth="lg">
        <Typography variant="h5" component="h5" sx={{ mb: 2 }}>
          Du kannst den gebuchten Termin doch nicht wahrnehmen?
        </Typography>

        <Typography variant="body1" component="p" sx={{ mb: 2 }}>
          Solltest du den gebuchten Termin nicht wahrnehmen können, storniere
          diesen bitte so früh als möglich.
        </Typography>

        <Typography variant="body1" component="p" sx={{ mb: 2 }}>
          Bitte habe Verständnis dafür, dass Termine, die nicht spätestens 48
          Stunden vor dem Termin storniert wurden, verfallen.
        </Typography>
      </Container>
    </Box>
  );
};

export default CancellationText;
