import { Box, CircularProgress, Typography } from "@mui/material";

const RenderLoading = (): React.JSX.Element => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 3,
      }}
    >
      <CircularProgress
        sx={{
          mx: 20,
        }}
        color="secondary"
        variant="indeterminate"
      />

      <Typography variant="body1" component="div">
        Lädt...
      </Typography>
    </Box>
  );
};

export default RenderLoading;
