import { TextField } from "@mui/material";

interface LastNameInputProps {
  disabled?: boolean;
  required?: boolean;
  lastName: string;
  setLastName?: (lastName: string) => void;
}

const LastNameInput = ({
  disabled,
  required,
  lastName,
  setLastName,
}: LastNameInputProps): React.JSX.Element => {
  return (
    <TextField
      sx={{
        "& .MuiInputBase-root": {
          color:
            lastName.length === 0
              ? (theme) => theme.palette.error.main
              : (theme) => theme.palette.success.main,
        },
      }}
      disabled={disabled}
      focused={lastName.length > 0}
      color="success"
      required={required}
      fullWidth
      type="text"
      id="LastNameInput"
      label="Nachname"
      variant="outlined"
      value={lastName}
      onChange={(e) => {
        if (setLastName) {
          setLastName(e.target.value);
        }
      }}
      error={lastName.length === 0}
      helperText={
        lastName.length === 0 ? "Bitte gib deinen Nachnamen ein" : ""
      }
    />
  );
};

export default LastNameInput;
