import { Autocomplete, TextField } from "@mui/material";

const genders: {
  label: string;
  value: string;
}[] = [
  {
    label: "Herr",
    value: "f4285c6f-8b98-4ee8-bd61-7beb59f1df7b",
  },
  {
    label: "Frau",
    value: "96972cc0-a7e7-44b7-a618-7f43e75a8811",
  },
  {
    label: "Divers",
    value: "8c5bf3d1-7f5a-49d1-a66d-f6cce2fa33d7",
  },
];

interface GenderInputProps {
  disabled?: boolean;
  required?: boolean;
  gender: {
    label: string;
    value: string;
  } | null;
  setGender?: (option: { label: string; value: string } | null) => void;
}

const GenderInput = ({
  disabled,
  required,
  gender,
  setGender,
}: GenderInputProps): React.JSX.Element => {
  const changeGender = (
    _event: any,
    option: {
      label: string;
      value: string;
    } | null
  ): void => {
    if (setGender) {
      setGender(option);
    }
  };

  return (
    <Autocomplete
      sx={{
        "& .MuiInputBase-root": {
          color:
            gender === null
              ? (theme) => theme.palette.error.main
              : (theme) => theme.palette.success.main,
        },
      }}
      disabled={disabled}
      disablePortal
      value={gender}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      onChange={(event, option) => changeGender(event, option)}
      id="GenderInput"
      options={genders}
      renderInput={(params) => (
        <TextField
          focused={gender !== null}
          required={required}
          color="success"
          error={gender === null}
          helperText={gender === null ? "Bitte wähle eine Anrede" : ""}
          {...params}
          label="Anrede"
        />
      )}
    />
  );
};

export default GenderInput;
