import { createTheme } from "@mui/material";

export const lightTheme = createTheme({
  palette: {
    mode: "light",

    text: {
      primary: "#000",
      secondary: "#2671B3",
    },
    background: {
      default: "#fff",
      paper: "#fff",
    },
    primary: {
      main: "#2671B3",
      light: "#B5C4DC",
      contrastText: "#fff",
    },
    secondary: {
      main: "#FA7268",
      light: "#F9CBB6",
      contrastText: "#fff",
    },
    success: {
      main: "#124D17",
      light: "#D6F3E2",
      contrastText: "#fff",
    },
    error: {
      main: "#BD3232",
      light: "#F9D3D4",
      contrastText: "#fff",
    },
    info: {
      main: "#17A2B8",
      light: "",
      contrastText: "#fff",
    },
    warning: {
      main: "#F2864A",
      light: "#FFE1BE",
      contrastText: "#fff",
    },
  },
});
