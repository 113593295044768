import { Box, Typography } from "@mui/material";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";

const NoEditableFileMessage = (): React.JSX.Element => {
  return (
    <Box
      sx={{
        textAlign: "center",
        background: (theme) => theme.palette.error.main,
        borderRadius: 2,
        padding: 2,
        my: 2,
        color: (theme) => theme.palette.error.contrastText,
      }}
    >
      <Typography
        variant="h5"
        component="div"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
          mb: 2,
        }}
      >
        <ReportProblemOutlinedIcon
          sx={{
            fontSize: 40,
            color: (theme) => theme.palette.error.contrastText,
          }}
        />
        Du hast noch keine editierbare Datei hochgeladen!
        <ReportProblemOutlinedIcon
          sx={{
            fontSize: 40,
            color: (theme) => theme.palette.error.contrastText,
          }}
        />
      </Typography>

      <Typography variant="body1" component="div" sx={{ mb: 2 }}>
        Bitte beachte, dass du deine Termine nur buchen kannst, wenn du deinem
        Coach eine editierbare Datei zur Verfügung stellst!
      </Typography>

      <Typography variant="body1" component="div" sx={{ mb: 2 }}>
        Eine editierbare Datei hat in der Regel folgende Endungen: docx, odt
      </Typography>
    </Box>
  );
};

export default NoEditableFileMessage;
