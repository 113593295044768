import Section from "../../../components/section/section";
import React from "react";
import AppointmentFilter from "./src/appointmentFilter";
import NoFilterMessage from "./src/noFilterMessage";
import AppointmentTile from "./src/appointmentTile";
import LoadMoreButton from "./src/loadMoreButton";
import axios from "axios";
import { endpoints } from "../../../endpoints/endpoints";
import { enqueueSnackbar } from "notistack";
import { Box, Divider, Typography } from "@mui/material";
import { User, AppContext } from "../../../context/appContext";
import transformDateToDe from "../../../functions/transformDateToDe";
import RenderLoading from "./src/renderLoading";
import NoEditableFileMessage from "../components/noEditableFileMessage";
import MaxBookingCountInfoMessage from "./src/maxBookingCountInfoMessage";

let dateCheck: string = "";

export interface Appointment {
  id: string;
  date: string;
  start: string;
  end: string;
  type: string;
  coach: string;
  day: string;
  href: string | null;
  newLine: boolean;
}

export interface Uploads {
  editableFile: boolean;
  files: {
    uploadParticiopant: string[];
    uploadCoach: string[];
  };
}

const Booking = (): React.JSX.Element => {
  const {
    user,
  }: {
    user: User;
  } = React.useContext(AppContext);

  const [filteredAppointments, setFilteredAppointments] = React.useState<
    Appointment[]
  >([]);

  const [uploads, setUploads] = React.useState<Uploads>({
    editableFile: false,
    files: {
      uploadParticiopant: [],
      uploadCoach: [],
    },
  });

  const getUploads = React.useCallback(() => {
    axios
      .post(endpoints.participant.getFiles, {
        sessionId: user.sessionId,
      })
      .then((response) => {
        setUploads(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [user.sessionId]);

  const [showAppointments, setShowAppointments] = React.useState({
    coaching: true,
    speedcoaching: true,
  });

  const isLoading = React.useRef(false);
  const isLoadingUploads = React.useRef(false);

  const [appointments, setAppointments] = React.useState<Appointment[]>([]);
  const [maxShownAppointments, setMaxShownAppointments] = React.useState(5);

  React.useEffect(() => {
    window.scrollTo(0, 0);

    if (isLoadingUploads.current) {
      return;
    }

    isLoadingUploads.current = true;

    getUploads();
  }, [getUploads]);

  React.useEffect(() => {
    if (isLoading.current) return;
    isLoading.current = true;

    axios
      .post(endpoints.appointment.bookable, {
        sessionId: user.sessionId,
      })
      .then((response) => {
        if (response.data.length === 0) {
          enqueueSnackbar("Es konnten keine freien Termine gefunden werden!", {
            variant: "error",
          });
        } else {
          setAppointments(response.data.data);
        }

        isLoading.current = false;
      })
      .catch((error) => {
        console.log(error);
        isLoading.current = false;
        enqueueSnackbar("Termine konnten nicht geladen werden!", {
          variant: "error",
        });
      });
  }, [user.sessionId]);

  React.useEffect(() => {
    let tmpFilteredAppointments =
      appointments.length > 0
        ? appointments.filter(
            (appointment) =>
              (showAppointments.coaching &&
                appointment.type === "Einzelcoaching") ||
              (showAppointments.speedcoaching &&
                appointment.type === "Speedcoaching")
          )
        : [];

    tmpFilteredAppointments.forEach((appointment: Appointment) => {
      // Check if date is different from previous date
      if (dateCheck !== appointment.date) {
        // Set newLine property to true if date is different
        dateCheck = appointment.date;
        appointment.newLine = true;
      } else {
        // Set newLine property to false if date is the same
        appointment.newLine = false;
      }
    });

    setFilteredAppointments(tmpFilteredAppointments);
  }, [appointments, showAppointments]);

  return (
    <Section>
      {isLoading.current && <RenderLoading />}

      {!uploads.editableFile && <NoEditableFileMessage />}

      <MaxBookingCountInfoMessage />

      {!isLoading.current && (
        <>
          <AppointmentFilter
            showAppointments={showAppointments}
            setShowAppointments={setShowAppointments}
          />

          {!showAppointments.coaching && !showAppointments.speedcoaching && (
            <NoFilterMessage />
          )}

          {filteredAppointments.length > 0 ? (
            filteredAppointments
              .slice(0, maxShownAppointments)
              .map((appointment: Appointment) => (
                <Box key={appointment.id}>
                  {appointment.newLine && (
                    <React.Fragment>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          mt: 5,
                        }}
                        variant="h6"
                        component="p"
                      >
                        {`${appointment.day} der ${transformDateToDe(
                          appointment.date
                        )}`}
                      </Typography>
                      <Divider
                        sx={{
                          mb: 2,
                          borderWidth: 1,
                          borderRadius: 2,
                          borderColor: "secondary.main",
                        }}
                      />
                    </React.Fragment>
                  )}

                  <AppointmentTile
                    appointment={appointment}
                    uploads={uploads}
                  />
                </Box>
              ))
          ) : (
            <Box
              sx={{
                backgroundColor: "#eeeeee",
                p: 2,
                mb: 2,
                borderRadius: 2,
                textAlign: "center",
              }}
            >
              <Typography variant="body1" component="div">
                Es konnten keine freien Termine gefunden werden.
              </Typography>
            </Box>
          )}

          {maxShownAppointments < appointments.length && (
            <LoadMoreButton
              maxShownAppointments={maxShownAppointments}
              setMaxShownAppointments={setMaxShownAppointments}
            />
          )}
        </>
      )}
    </Section>
  );
};

export default Booking;
