import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import EditCalendarOutlinedIcon from "@mui/icons-material/EditCalendarOutlined";
import DoorBackOutlinedIcon from "@mui/icons-material/DoorBackOutlined";
import PermContactCalendarOutlinedIcon from "@mui/icons-material/PermContactCalendarOutlined";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import TodayOutlinedIcon from "@mui/icons-material/TodayOutlined";
import PersonSearchOutlinedIcon from "@mui/icons-material/PersonSearchOutlined";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";

export interface LinkProps {
  href: string;
  text: string;
  icon: JSX.Element;
  role: {
    label: string;
    value: string;
  } | null;
}

export interface LinkListProps {
  open: LinkProps[];
  participant: LinkProps[];
  coach: LinkProps[];
  admin: LinkProps[];
}

export const linkList: LinkListProps = {
  open: [
    {
      href: "/",
      text: "Start",
      icon: <HomeOutlinedIcon />,
      role: null,
    },
  ],
  participant: [
    {
      href: "/intern",
      text: "Willkommen",
      icon: <DoorBackOutlinedIcon />,
      role: {
        label: "Teilnehmer:in",
        value: "676a0d5f-e974-4321-80ba-d464ca3f1df9",
      },
    },
    {
      href: "/booking",
      text: "Termine buchen",
      icon: <EditCalendarOutlinedIcon />,
      role: {
        label: "Teilnehmer:in",
        value: "676a0d5f-e974-4321-80ba-d464ca3f1df9",
      },
    },
    {
      href: "/appointments",
      text: "Meine Termine",
      icon: <CalendarMonthOutlinedIcon />,
      role: {
        label: "Teilnehmer:in",
        value: "676a0d5f-e974-4321-80ba-d464ca3f1df9",
      },
    },
    {
      href: "/files",
      text: "Meine Unterlagen",
      icon: <FileCopyOutlinedIcon />,
      role: {
        label: "Teilnehmer:in",
        value: "676a0d5f-e974-4321-80ba-d464ca3f1df9",
      },
    },
  ],
  coach: [
    {
      href: "/daily-plan",
      text: "Tagesplan",
      icon: <TodayOutlinedIcon />,
      role: {
        label: "Coach",
        value: "c751ded5-7446-4d9c-8ff6-20e95ebaf716",
      },
    },
    {
      href: "/search-participant-coach",
      text: "Teilnehmersuche",
      icon: <PersonSearchOutlinedIcon />,
      role: {
        label: "Coach",
        value: "c751ded5-7446-4d9c-8ff6-20e95ebaf716",
      },
    },
    {
      href: "/new-appointment-coach",
      text: "Termin erstellen",
      icon: <EditCalendarOutlinedIcon />,
      role: {
        label: "Coach",
        value: "c751ded5-7446-4d9c-8ff6-20e95ebaf716",
      },
    },
    {
      href: "/appointment-overview-coach",
      text: "Terminübersicht",
      icon: <PermContactCalendarOutlinedIcon />,
      role: {
        label: "Coach",
        value: "c751ded5-7446-4d9c-8ff6-20e95ebaf716",
      },
    },
  ],
  admin: [
    {
      href: "/booking-overview",
      text: "Buchungsübersicht",
      icon: <PermContactCalendarOutlinedIcon />,
      role: {
        label: "Admin",
        value: "8779046b-1003-42a4-982a-a676d0c6d268",
      },
    },
    {
      href: "/appointment-overview-admin",
      text: "Terminübersicht",
      icon: <PermContactCalendarOutlinedIcon />,
      role: {
        label: "Admin",
        value: "8779046b-1003-42a4-982a-a676d0c6d268",
      },
    },
    {
      href: "/new-appointment-admin",
      text: "Termin erstellen",
      icon: <EditCalendarOutlinedIcon />,
      role: {
        label: "Admin",
        value: "8779046b-1003-42a4-982a-a676d0c6d268",
      },
    },
    {
      href: "/new-appointment-upload",
      text: "Termin Upload",
      icon: <DriveFolderUploadOutlinedIcon />,
      role: {
        label: "Admin",
        value: "8779046b-1003-42a4-982a-a676d0c6d268",
      },
    },
    {
      href: "/search-participant-admin",
      text: "Teilnehmersuche",
      icon: <PersonSearchOutlinedIcon />,
      role: {
        label: "Admin",
        value: "8779046b-1003-42a4-982a-a676d0c6d268",
      },
    },
    {
      href: "/coachlist",
      text: "Coachliste",
      icon: <PersonSearchOutlinedIcon />,
      role: {
        label: "Admin",
        value: "8779046b-1003-42a4-982a-a676d0c6d268",
      },
    },
  ],
};
