import { Box, Grid, Collapse, Typography } from "@mui/material";
import React from "react";
import EditButton from "../../../../components/inputs/editButton/editButton";
import ExpandMoreButton from "../../../../components/inputs/expandMoreButton/expandMoreButton";
import CoachListProps from "../interface/coachListProps";
import CoachProps from "../interface/coachProps";
import RenderCoachDetails from "./renderCoachDetails";
import RenderCoachName from "./renderCoachName";
import Section from "../../../../components/section/section";

const RenderList = ({
  search,
  coachList,
  setEditCoach,
  setCoachList,
}: {
  search: string;
  coachList: CoachListProps;
  setEditCoach: React.Dispatch<
    React.SetStateAction<{ showDialog: boolean; coach: CoachProps }>
  >;
  setCoachList: React.Dispatch<React.SetStateAction<CoachListProps>>;
}): React.JSX.Element => {
  const searchFilter: CoachProps[] = coachList.data.filter(
    (coach: CoachProps) =>
      coach.vorname.toLowerCase().includes(search.toLowerCase()) ||
      coach.nachname.toLowerCase().includes(search.toLowerCase())
  );

  if (searchFilter.length === 0) return <NoCoachesFound />;

  return (
    <Section>
      {coachList.fetched &&
        searchFilter.map((coach: CoachProps) => (
          <Box
            key={coach.id}
            sx={{
              backgroundColor: "#eeeeee",
              mb: 2,
              p: 2,
              borderRadius: 2,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={10}>
                <RenderCoachName coach={coach} />
              </Grid>

              <Grid
                item
                xs={6}
                sm={6}
                md={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <EditButton
                  onClick={() =>
                    setEditCoach({ showDialog: true, coach: coach })
                  }
                />

                <ExpandMoreButton
                  onClick={() => {
                    setCoachList({
                      ...coachList,
                      data: coachList.data.map((coachItem) => {
                        if (coachItem.id === coach.id) {
                          coachItem.expand = !coachItem.expand;
                        }
                        return coachItem;
                      }),
                    });
                  }}
                  expand={coach.expand}
                />
              </Grid>
            </Grid>

            <Collapse in={coach.expand}>
              <RenderCoachDetails coach={coach} />
            </Collapse>
          </Box>
        ))}
    </Section>
  );
};

export default RenderList;

const NoCoachesFound = (): React.JSX.Element => {
  return (
    <Section>
      <Box
        sx={{
          backgroundColor: "#eeeeee",
          mb: 2,
          p: 2,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6">Keine Coaches gefunden</Typography>
      </Box>
    </Section>
  );
};
