import { Button, Typography } from "@mui/material";

const RegistrationSubmitButton = ({
  registrationIsLoading,
  checkForm,
}: {
  registrationIsLoading: boolean;
  checkForm: () => boolean;
}): React.JSX.Element => {
  return (
    <Button
      disabled={registrationIsLoading || !checkForm()}
      type="submit"
      variant="contained"
      color="secondary"
      sx={{ py: 1, px: 4 }}
    >
      <Typography variant="body1" component="p">
        {registrationIsLoading ? "Registrierung läuft..." : "Registrieren"}
      </Typography>
    </Button>
  );
};

export default RegistrationSubmitButton;
