import { Box, Typography } from "@mui/material";
import { SearchParticipantProps } from "../interfaces/interfaces";
import React from "react";

const SearchParticipantLoader = ({
  searchParameters,
}: {
  searchParameters: SearchParticipantProps;
}): React.JSX.Element => {
  return (
    <React.Fragment>
      {searchParameters.loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            my: 2,
          }}
        >
          <Typography variant="body1" color="initial">
            Suche Teilnehmer:in ...
          </Typography>
        </Box>
      )}
    </React.Fragment>
  );
};

export default SearchParticipantLoader;
