import { Typography, Divider, Box } from "@mui/material";
import React from "react";
import transformDateToDe from "../../../../../functions/transformDateToDe";
import { Participant } from "../../interfaces/interfaces";

const ListTileAppointments = ({
  participant,
}: {
  participant: Participant;
}): React.JSX.Element => {
  return (
    <React.Fragment>
      <Typography
        variant="h6"
        color="initial"
        sx={{
          mt: 2,
        }}
      >
        Termine
      </Typography>

      <Divider />

      {participant.appointments.data.map((appointment, index) => (
        <Box
          key={index}
          sx={{
            backgroundColor: "#fff",
            p: 2,
            borderRadius: 2,
            mt: 2,
          }}
        >
          <Typography
            variant="caption"
            color="span"
            sx={{
              fontWeight: "bold",
            }}
          >
            {`${appointment.weekDay}, ${transformDateToDe(appointment.date)}, ${
              appointment.timeSlot
            } Uhr`}
          </Typography>
        </Box>
      ))}

      {participant.appointments.data.length === 0 && (
        <Box
          sx={{
            backgroundColor: "#fff",
            p: 2,
            borderRadius: 2,
            my: 2,
          }}
        >
          <Typography
            variant="caption"
            color="span"
            sx={{
              fontWeight: "bold",
            }}
          >
            Keine Termine gefunden
          </Typography>
        </Box>
      )}
    </React.Fragment>
  );
};

export default ListTileAppointments;
