import { Grid, TextField } from "@mui/material";
import React from "react";
import { AppContext, User } from "../../../context/appContext";
import EmailInput from "../../../components/inputs/email/emailInput";
import FirstNameInput from "../../../components/inputs/firstName/firstNameInput";
import GenderInput from "../../../components/inputs/gender/genderInput";
import LastNameInput from "../../../components/inputs/lastName/lastNameInput";
import PhoneNumberInput from "../../../components/inputs/phoneNumber/phoneNumberInput";

const StaticData = (): React.JSX.Element => {
  const {
    user,
  }: {
    user: User;
  } = React.useContext(AppContext);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <GenderInput disabled gender={user.gender} />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField fullWidth label="Rolle" value={user.role?.label} disabled />
      </Grid>

      <Grid item xs={12} md={6}>
        <FirstNameInput disabled firstName={user.firstName} />
      </Grid>

      <Grid item xs={12} md={6}>
        <LastNameInput disabled lastName={user.lastName} />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Qualifikation"
          value={user.qualification}
          disabled
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          label="Begin der Qualifikation"
          value={user.startOfQualification.split("-").reverse().join(".")}
          disabled
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          label="Ende der Qualifikation"
          value={user.endOfQualification.split("-").reverse().join(".")}
          disabled
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Standort"
          value={user.location?.label}
          disabled
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <EmailInput disabled email={user.email} />
      </Grid>

      <Grid item xs={12} md={6}>
        <PhoneNumberInput
          disabled
          phoneNumber={user.phoneNumber.replace(/\s/g, "")}
        />
      </Grid>
    </Grid>
  );
};

export default StaticData;
