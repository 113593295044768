import { Typography } from "@mui/material";
import React from "react";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";

const MaxBookingCountInfoMessage = (): React.JSX.Element => {
  return (
    <Typography
      variant="body1"
      component="div"
      sx={{
        backgroundColor: (theme) => theme.palette.info.main,
        color: (theme) => theme.palette.info.contrastText,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 2,
        my: 2,
        padding: 2,
        borderRadius: 2,
      }}
    >
      <LightbulbOutlinedIcon />
      Bitte beachte, dass du nur 3 Termine eigenständig buchen kannst. Hast du
      bereits 3 Termine gebucht, werden dir nur noch Speedcoaching-Termine
      angezeigt
    </Typography>
  );
};

export default MaxBookingCountInfoMessage;
