import {
  Autocomplete,
  TextField,
  Grid,
  Button,
  Switch,
  FormControlLabel,
} from "@mui/material";
import Section from "../../../components/section/section";
import Container from "@mui/material/Container";
import React from "react";
import InputDate from "../../../components/inputs/date/inputDate";
import InputTime from "../../../components/inputs/time/inputTime";
import axios from "axios";
import { endpoints } from "../../../endpoints/endpoints";
import { User, AppContext } from "../../../context/appContext";
import SelectCoachInput from "../../../components/inputs/coach/selectCoachInput";
import { enqueueSnackbar } from "notistack";
import NewAppointmentProps from "./interface/newAppointmentProps";

const NewAppointment = (): React.JSX.Element => {
  const {
    user,
  }: {
    user: User;
  } = React.useContext(AppContext);

  const [newAppointment, setNewAppointment] =
    React.useState<NewAppointmentProps>({
      type: null,
      date: new Date().toISOString().split("T")[0],
      time: { from: "", to: "" },
      directBooking: false,
      coach: null,
      search: {
        loading: false,
        participantName: "",
        searchResults: [],
        selectedParticipant: null,
      },
    });

  const startSearch = (participantName: string) => {
    if (newAppointment.search.loading) return;

    setNewAppointment({
      ...newAppointment,
      search: {
        ...newAppointment.search,
        loading: true,
      },
    });

    axios
      .post(endpoints.admin.searchParticipant, {
        sessionId: user.sessionId,
        searchString: participantName,
      })
      .then((response) => {
        const sortetUser = response.data.data.map((user: any) => {
          return {
            label: `${user.firstName} ${user.lastName} (${user.email})`,
            value: user.participantId,
          };
        });

        setNewAppointment({
          ...newAppointment,
          search: {
            ...newAppointment.search,
            loading: false,
            searchResults: sortetUser,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const doCreateAppointment = () => {
    axios
      .post(endpoints.admin.createAppointment, {
        sessionId: user.sessionId,
        type: newAppointment.type?.label,
        date: newAppointment.date,
        fromTime: newAppointment.time.from,
        tillTime: newAppointment.time.to,
        coachId: newAppointment.coach?.value,
        participantId: newAppointment.directBooking
          ? newAppointment.search.selectedParticipant?.value
          : null,
      })
      .then((response) => {
        enqueueSnackbar(
          response.data.meta.status === "success"
            ? "Der Termin wurde erfolgreich erstellt!"
            : "Der Termin konnte nicht erstellt werden!",
          {
            variant: response.data.meta.status,
          }
        );
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar("Es ist ein unerwarteter Fehler aufgetreten!", {
          variant: "error",
        });
      });
  };

  return (
    <Section>
      <Container maxWidth="sm">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SelectCoachInput
              error
              required
              removeAll
              coach={newAppointment.coach}
              setCoach={(coach: null | { label: string; value: string }) => {
                setNewAppointment({
                  ...newAppointment,
                  coach: coach,
                });
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              disablePortal
              id="coachingTypeInput"
              options={[
                {
                  label: "Einzelcoaching",
                  value: "503cec4e-62da-46db-8833-4ec3f44736eb",
                },
                {
                  label: "Speedcoaching",
                  value: "5fcd7dda-2b9b-4374-80a3-8f2cadae84ba",
                },
              ]}
              value={newAppointment.type}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              onChange={(_event, option) => {
                setNewAppointment({ ...newAppointment, type: option });
              }}
              renderInput={(params) => (
                <TextField
                  required
                  error={!newAppointment.type}
                  color="success"
                  {...params}
                  label="Art"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <InputDate
              error
              required
              date={newAppointment.date}
              setDate={(date: string) => {
                setNewAppointment({ ...newAppointment, date: date });
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <InputTime
              error
              required
              time={newAppointment.time.from}
              setTime={(time: string) => {
                setNewAppointment({
                  ...newAppointment,
                  time: { ...newAppointment.time, from: time },
                });
              }}
              label={"Von"}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <InputTime
              error
              required
              time={newAppointment.time.to}
              setTime={(time: string) => {
                setNewAppointment({
                  ...newAppointment,
                  time: { ...newAppointment.time, to: time },
                });
              }}
              label={"Bis"}
            />
          </Grid>

          <Grid item lg={12}>
            <FormControlLabel
              control={
                <Switch
                  color="secondary"
                  checked={newAppointment.directBooking}
                  onChange={(event) => {
                    setNewAppointment({
                      ...newAppointment,
                      directBooking: event.target.checked,
                    });
                  }}
                />
              }
              label="Termin direkt auf einen TN buchen?"
            />
          </Grid>

          {newAppointment.directBooking && (
            <Grid item xs={12}>
              <Autocomplete
                loading={newAppointment.search.loading}
                loadingText="Suche..."
                disablePortal
                noOptionsText="Bitte mindestens 2 Zeichen eingeben."
                id="seqarchParticipantInput"
                options={newAppointment.search.searchResults}
                value={newAppointment.search.selectedParticipant}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                onInput={(event: any) => {
                  setNewAppointment({
                    ...newAppointment,
                    search: {
                      ...newAppointment.search,
                      participantName: event.target.value,
                    },
                  });

                  if (newAppointment.search.loading) return;
                  if (event.target.value.length < 2) return;

                  startSearch(event.target.value);
                }}
                onChange={(_event, option) => {
                  setNewAppointment({
                    ...newAppointment,
                    search: {
                      ...newAppointment.search,
                      selectedParticipant: option,
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    color="success"
                    {...params}
                    label="Teilnehmer:in"
                  />
                )}
              />
            </Grid>
          )}

          <Grid
            item
            lg={12}
            sx={{
              textAlign: "center",
            }}
          >
            <Button
              disabled={
                !newAppointment.type ||
                !newAppointment.date ||
                !newAppointment.time.from ||
                !newAppointment.time.to ||
                newAppointment.time.from > newAppointment.time.to ||
                !newAppointment.coach ||
                (newAppointment.directBooking &&
                  !newAppointment.search.selectedParticipant)
              }
              onClick={() => doCreateAppointment()}
              variant="contained"
              color="secondary"
            >
              Termin erstellen
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
};

export default NewAppointment;
