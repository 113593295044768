import { TextField } from "@mui/material";

interface FirstNameInputProps {
  disabled?: boolean;
  required?: boolean;
  firstName: string;
  setFirstName?: (firstName: string) => void;
}

const FirstNameInput = ({
  disabled,
  required,
  firstName,
  setFirstName,
}: FirstNameInputProps): React.JSX.Element => {
  return (
    <TextField
      sx={{
        "& .MuiInputBase-root": {
          color:
            firstName.length === 0
              ? (theme) => theme.palette.error.main
              : (theme) => theme.palette.success.main,
        },
      }}
      disabled={disabled}
      focused={firstName.length > 0}
      color="success"
      required={required}
      fullWidth
      type="text"
      id="FirstNameInput"
      label="Vorname"
      variant="outlined"
      value={firstName}
      onChange={(e) => {
        if (setFirstName) {
          setFirstName(e.target.value);
        }
      }}
      error={firstName.length === 0}
      helperText={
        firstName.length === 0 ? "Bitte gib deinen Vornamen ein" : ""
      }
    />
  );
};

export default FirstNameInput;
