import {
  Dialog,
  DialogContent,
  Typography,
  Divider,
  DialogActions,
  Button,
  Box,
  styled,
  DialogTitle,
} from "@mui/material";

import UploadTile from "../../../participant/files/src/uploadTile";
import BackupTableOutlinedIcon from "@mui/icons-material/BackupTableOutlined";
import React from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import axios from "axios";
import { endpoints } from "../../../../endpoints/endpoints";
import { User, AppContext } from "../../../../context/appContext";
import {
  ParticipantData,
  ShowFileDialogProps,
} from "../interface/bookingOverviewInterface";
import { enqueueSnackbar } from "notistack";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const FileDialog = ({
  showFileDialog,
  setShowFileDialog,
  setParticipantData,
  startSearch,
}: {
  showFileDialog: ShowFileDialogProps;
  setShowFileDialog: (showFileDialog: ShowFileDialogProps) => void;
  setParticipantData: (participantData: ParticipantData[] | null) => void;
  startSearch: () => void;
}) => {
  const {
    user,
  }: {
    user: User;
  } = React.useContext(AppContext);

  const [files, setFiles] = React.useState<any>([]);
  const isLoading = React.useRef(false);

  const fileUpload = (event: any): void => {
    const uniqueFiles = Array.from(new Set(event.target.files));
    console.log(uniqueFiles);
    setFiles([...files, ...uniqueFiles]);
  };

  const doUpload = (e: any) => {
    isLoading.current = true;

    const formData = new FormData();

    files.forEach((file: any, index: number) => {
      formData.append(
        `file_${user.sessionId}_?participant=${e.participantId}_${index}`,
        file
      );
    });

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    axios
      .post(endpoints.coach.uploadFiles, formData, config)
      .then(() => {
        setFiles([]);
        setShowFileDialog({
          open: false,
          participant: null,
        });

        isLoading.current = false;

        enqueueSnackbar("Die Datei wurde hochgeladen!", {
          variant: "success",
        });

        startSearch();
      })
      .catch((error) => {
        console.error(error);
        isLoading.current = false;
      });
  };

  const doDelete = () => {
    setFiles([]);

    setShowFileDialog({
      open: false,
      participant: null,
    });

    enqueueSnackbar("Die Datei wurde gelöscht!", {
      variant: "success",
    });

    startSearch();
  };

  return (
    <>
      <Dialog
        maxWidth="xl"
        open={showFileDialog.open}
        onClose={() =>
          setShowFileDialog({
            open: false,
            participant: null,
          })
        }
        aria-labelledby={"uploads-dialog"}
      >
        <DialogContent>
          {showFileDialog.participant !== null &&
            showFileDialog.participant.files.data.length > 0 && (
              <Box>
                <Typography variant="h6" color="initial">
                  Unterlagen von {showFileDialog.participant.fullName}
                </Typography>

                <Divider
                  sx={{
                    mb: 2,
                  }}
                />

                {showFileDialog.participant.files.data.map((file: any) => {
                  if (file.uploadByCoach) return null;
                  return <UploadTile key={file.id} file={file} />;
                })}

                <Typography variant="h6" color="initial">
                  Unterlagen Coach
                </Typography>

                <Divider
                  sx={{
                    mb: 2,
                  }}
                />

                {showFileDialog.participant.files.data.map((file: any) => {
                  if (!file.uploadByCoach) return null;
                  return (
                    <UploadTile
                      deletable={true}
                      key={file.id}
                      file={file}
                      onDelete={() => doDelete()}
                    />
                  );
                })}
              </Box>
            )}
        </DialogContent>

        <Divider />

        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="outlined"
            onClick={() =>
              setShowFileDialog({
                open: false,
                participant: null,
              })
            }
            color="primary"
          >
            Schließen
          </Button>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              size="large"
              component="label"
              onChange={(e) => fileUpload(e)}
              variant="contained"
              tabIndex={-1}
              color="secondary"
              startIcon={<BackupTableOutlinedIcon sx={{ mr: 1 }} />}
            >
              Unterlagen hochladen
              <VisuallyHiddenInput
                type="file"
                multiple
                accept="
            application/msword,
            application/vnd.openxmlformats-officedocument.wordprocessingml.document,
            application/vnd.oasis.opendocument.text,
            application/pdf,
          "
              />
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <Dialog
        open={files.length > 0}
        onClose={() => setFiles([])}
        aria-labelledby={"file-upload-dialog"}
      >
        <DialogTitle id={"file-upload-dialog-title"}>Ihr Upload</DialogTitle>

        <Divider />

        <DialogContent>
          {!isLoading.current &&
            files.map((file: any, index: number) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #ccc",
                }}
              >
                <Typography
                  variant="body2"
                  component="p"
                  sx={{
                    my: 0,
                    padding: "10px",
                  }}
                >
                  {`${index + 1}. ${file.name}`}
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Button
                    onClick={() => {
                      const newFiles = [...files];
                      newFiles.splice(index, 1);
                      setFiles(newFiles);
                    }}
                    variant="text"
                    sx={{
                      color: (theme) => theme.palette.error.main,
                    }}
                  >
                    <CloseOutlinedIcon />
                  </Button>
                </Box>
              </Box>
            ))}
        </DialogContent>

        <Divider />

        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 2,
            px: 2,
            pb: 2,
          }}
        >
          <Button onClick={() => setFiles([])} color="primary">
            abbrechen
          </Button>

          <Button
            disabled={files.length === 0}
            size="large"
            component="label"
            onClick={() => doUpload(showFileDialog.participant)}
            variant="contained"
            tabIndex={-1}
            color="secondary"
            endIcon={<CloudUploadOutlinedIcon />}
          >
            Hochladen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FileDialog;
