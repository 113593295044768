import { Grid, Button } from "@mui/material";
import SearchParticipantByName from "../../../../components/inputs/searchParticipantByName/searchParticipantByName";
import { SearchParticipantProps } from "../interfaces/interfaces";

const SearchForm = ({
  startSearch,
  setSearchParameters,
  searchParameters,
}: {
  startSearch: () => void;
  setSearchParameters: (searchParameters: SearchParticipantProps) => void;
  searchParameters: SearchParticipantProps;
}): React.JSX.Element => {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        startSearch();
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8} md={10}>
          <SearchParticipantByName
            disabled={searchParameters.loading}
            required
            name={searchParameters.participantName}
            onChange={(name: string) =>
              setSearchParameters({
                ...searchParameters,
                participantName: name,
              })
            }
          />
        </Grid>

        <Grid item xs={12} sm={4} md={2}>
          <Button
            disabled={
              searchParameters.participantName === "" ||
              searchParameters.loading
            }
            onClick={() => startSearch()}
            sx={{
              height: "100%",
              width: "100%",
            }}
            variant="contained"
            color="primary"
          >
            Suchen
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default SearchForm;
