import { Typography, Link } from "@mui/material";

const CancellationMessage = (): React.JSX.Element => {
  return (
    <Typography variant="body1" component="div">
      Solltest du deinen Termin aus wichtigen Gründen kurzfristig nicht
      wahrnehmen können, informiere uns bitte per E-Mail an{" "}
      <Link href="mailto:bewerbungscoaching@wbstraining.de">
        bewerbungscoaching@wbstraining.de
      </Link>
    </Typography>
  );
};

export default CancellationMessage;
