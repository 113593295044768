import { Grid, TextField, Button, CircularProgress } from "@mui/material";
import SearchParticipantByName from "../../../../components/inputs/searchParticipantByName/searchParticipantByName";
import Section from "../../../../components/section/section";
import SelectCoachInput from "../../../../components/inputs/coach/selectCoachInput";
import { SearchCriteria } from "../interface/bookingOverviewInterface";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

interface SearchControllsProps {
  searchCriteria: SearchCriteria;
  setSearchCriteria: (searchCriteria: SearchCriteria) => void;
  startSearch: () => void;
}

const SearchControlls = ({
  searchCriteria,
  setSearchCriteria,
  startSearch,
}: SearchControllsProps): React.JSX.Element => {
  return (
    <Section>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          startSearch();
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SearchParticipantByName
              name={searchCriteria.partipantName}
              onChange={(name) => {
                setSearchCriteria({ ...searchCriteria, partipantName: name });
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              color="secondary"
              fullWidth
              type="date"
              value={searchCriteria.date}
              onChange={(e) => {
                setSearchCriteria({ ...searchCriteria, date: e.target.value });
              }}
              id="search-booking-date"
              label="Datum"
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <SelectCoachInput
              coach={searchCriteria.coach}
              setCoach={(coach: { label: string; value: string | null }) => {
                setSearchCriteria({ ...searchCriteria, coach });
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Button
              disabled={
                searchCriteria.loading ||
                (searchCriteria.partipantName === "" &&
                  searchCriteria.date === "" &&
                  (searchCriteria.coach?.label === "Alle" ||
                    searchCriteria.coach === null))
              }
              startIcon={
                searchCriteria.loading ? (
                  <CircularProgress
                    size={18}
                    sx={{
                      color: "white",
                    }}
                  />
                ) : (
                  <SearchOutlinedIcon />
                )
              }
              type="submit"
              fullWidth
              size="large"
              sx={{
                height: "100%",
              }}
              variant="contained"
              color="primary"
            >
              {searchCriteria.loading ? "Lädt..." : "Suchen"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Section>
  );
};

export default SearchControlls;
