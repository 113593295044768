import React from "react";
import SearchParticipantByName from "../../../components/inputs/searchParticipantByName/searchParticipantByName";
import Section from "../../../components/section/section";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import axios from "axios";
import { User, AppContext } from "../../../context/appContext";
import { endpoints } from "../../../endpoints/endpoints";
import { Box, Collapse, Typography, IconButton, Divider } from "@mui/material";
import transformDateToDe from "../../../functions/transformDateToDe";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FileDialog from "./src/fileDialog";

interface SearchParticipantMeta {
  total: number;
}

interface AppointmentsData {
  id: number;
  date: string;
  timeSlot: string;
  weekDay: string;
  type: string;
}

interface Appointments {
  meta: SearchParticipantMeta;
  data: AppointmentsData[];
}

interface FilesData {
  id: number;
  name: string;
  uploadByCoach: false;
}

interface Files {
  meta: SearchParticipantMeta;
  data: FilesData[];
}

interface Participant {
  participantId: number;
  fullName: string;
  email: string;
  phoneNumber: string;
  qualification: string;
  startOfQualification: string;
  endOfQualification: string;
  location: string;
  appointments: Appointments;
  files: Files;
  collapse: boolean;
}

interface SearchParticipantProps {
  participantName: string;
  loading: boolean;
  data: Participant[] | null;
}

export interface ShowFileDialogProps {
  open: boolean;
  participant: Participant | null;
}

const SearchParticipant = (): React.JSX.Element => {
  const [searchParameters, setSearchParameters] =
    React.useState<SearchParticipantProps>({
      participantName: "",
      loading: false,
      data: null,
    });

  const [showFileDialog, setShowFileDialog] =
    React.useState<ShowFileDialogProps>({
      open: false,
      participant: null,
    });

  const {
    user,
  }: {
    user: User;
  } = React.useContext(AppContext);

  const startSearch = () => {
    setSearchParameters({
      ...searchParameters,
      loading: true,
    });

    axios
      .post(endpoints.coach.searchParticipant, {
        sessionId: user.sessionId,
        searchString: searchParameters.participantName,
      })
      .then((response) => {
        if (response.data.data.length > 0) {
          response.data.data.forEach((participant: Participant) => {
            participant.collapse = false;
          });
        }

        setSearchParameters({
          ...searchParameters,
          data: response.data.data,
          loading: false,
        });
      })
      .catch((error) => {
        setSearchParameters({
          ...searchParameters,
          loading: false,
        });
        console.log(error);
      });
  };

  const showParticipantDetails = (participant: Participant) => {
    const oldParticipants = searchParameters.data;

    if (oldParticipants !== null) {
      oldParticipants.forEach((oldParticipant) => {
        if (oldParticipant.participantId === participant.participantId) {
          oldParticipant.collapse = !oldParticipant.collapse;
        }
      });

      setSearchParameters({
        ...searchParameters,
        data: oldParticipants,
      });
    }
  };

  return (
    <React.Fragment>
      <Section>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            startSearch();
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8} md={10}>
              <SearchParticipantByName
                disabled={searchParameters.loading}
                required
                name={searchParameters.participantName}
                onChange={(name: string) =>
                  setSearchParameters({
                    ...searchParameters,
                    participantName: name,
                  })
                }
              />
            </Grid>

            <Grid item xs={12} sm={4} md={2}>
              <Button
                disabled={
                  searchParameters.participantName === "" ||
                  searchParameters.loading
                }
                onClick={() => startSearch()}
                sx={{
                  height: "100%",
                  width: "100%",
                }}
                variant="contained"
                color="primary"
              >
                Suchen
              </Button>
            </Grid>
          </Grid>
        </form>

        {searchParameters.data !== null && !searchParameters.loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              my: 2,
            }}
          >
            <Typography variant="body1" color="initial">
              {`${searchParameters.data.length} Teilnehmer:in gefunden`}
            </Typography>
          </Box>
        )}

        {searchParameters.loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              my: 2,
            }}
          >
            <Typography variant="body1" color="initial">
              Suche Teilnehmer:in ...
            </Typography>
          </Box>
        )}
      </Section>

      {searchParameters.data !== null && (
        <Section>
          {searchParameters.data.map((participant, index) => (
            <Box
              key={index}
              sx={{
                backgroundColor: "#eeeeee",
                p: 2,
                mb: 2,
                borderRadius: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6" color="initial">
                  {participant.fullName}
                </Typography>

                <Box>
                  <Button
                    sx={{
                      mr: 2,
                    }}
                    endIcon={<FileUploadOutlinedIcon />}
                    variant="outlined"
                    color="primary"
                    onClick={() =>
                      setShowFileDialog({
                        open: true,
                        participant: participant,
                      })
                    }
                  >
                    Unterlagen
                  </Button>

                  <IconButton
                    aria-label=""
                    onClick={() => {
                      showParticipantDetails(participant);
                    }}
                  >
                    {
                      <KeyboardArrowUpOutlinedIcon
                        sx={{
                          transition: "transform 0.3s",
                          transform: participant.collapse
                            ? "rotate(0deg)"
                            : "rotate(180deg)",
                        }}
                      />
                    }
                  </IconButton>
                </Box>
              </Box>

              <Collapse
                sx={{ my: 2 }}
                key={index}
                in={participant.collapse}
                timeout="auto"
                unmountOnExit
              >
                <Grid container>
                  <Grid item xs sm md lg={4} sx={{ mb: 2 }}>
                    <Typography
                      variant="caption"
                      color="span"
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      Email
                    </Typography>
                    <br />
                    <Typography variant="body1" color="initial">
                      {participant.email}
                    </Typography>
                  </Grid>

                  <Grid item xs sm md lg={4} sx={{ mb: 2 }}>
                    <Typography
                      variant="caption"
                      color="span"
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      Telefon
                    </Typography>
                    <br />
                    <Typography variant="body1" color="initial">
                      {participant.phoneNumber}
                    </Typography>
                  </Grid>

                  <Grid item xs sm md lg={4} sx={{ mb: 2 }}>
                    <Typography
                      variant="caption"
                      color="span"
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      Ort
                    </Typography>
                    <br />
                    <Typography variant="body1" color="initial">
                      {participant.location}
                    </Typography>
                  </Grid>

                  <Grid item xs sm md lg={4} sx={{ mb: 2 }}>
                    <Typography
                      variant="caption"
                      color="span"
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      Qualifikation
                    </Typography>
                    <br />
                    <Typography variant="body1" color="initial">
                      {participant.qualification}
                    </Typography>
                  </Grid>

                  <Grid item xs sm md lg={4} sx={{ mb: 2 }}>
                    <Typography
                      variant="caption"
                      color="span"
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      Beginn
                    </Typography>
                    <br />
                    <Typography variant="body1" color="initial">
                      {transformDateToDe(participant.startOfQualification)}
                    </Typography>
                  </Grid>

                  <Grid item xs sm md lg={4} sx={{ mb: 2 }}>
                    <Typography
                      variant="caption"
                      color="span"
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      Ende
                    </Typography>
                    <br />
                    <Typography variant="body1" color="initial">
                      {transformDateToDe(participant.endOfQualification)}
                    </Typography>
                  </Grid>
                </Grid>

                <Typography variant="h6" color="initial">
                  Termine
                </Typography>

                <Divider />

                {participant.appointments.data.map((appointment, index) => (
                  <Box
                    key={index}
                    sx={{
                      backgroundColor: "#fff",
                      p: 2,
                      borderRadius: 2,
                      mt: 2,
                    }}
                  >
                    <Typography
                      variant="caption"
                      color="span"
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      {`${appointment.weekDay}, ${transformDateToDe(
                        appointment.date
                      )}, ${appointment.timeSlot} Uhr`}
                    </Typography>
                  </Box>
                ))}

                {participant.appointments.data.length === 0 && (
                  <Box
                    sx={{
                      backgroundColor: "#fff",
                      p: 2,
                      borderRadius: 2,
                      my: 2,
                    }}
                  >
                    <Typography
                      variant="caption"
                      color="span"
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      Keine Termine gefunden
                    </Typography>
                  </Box>
                )}
              </Collapse>
            </Box>
          ))}
        </Section>
      )}

      <FileDialog
        showFileDialog={showFileDialog}
        setShowFileDialog={setShowFileDialog}
        startSearch={startSearch}
      />
    </React.Fragment>
  );
};

export default SearchParticipant;
