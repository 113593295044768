import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import EmailInput, {
  checkEmail,
} from "../../../../components/inputs/email/emailInput";
import FirstNameInput from "../../../../components/inputs/firstName/firstNameInput";
import LastNameInput from "../../../../components/inputs/lastName/lastNameInput";
import EditCoachProps from "../interface/editCoachProps";
import axios from "axios";
import { endpoints } from "../../../../endpoints/endpoints";
import { AppContext, User } from "../../../../context/appContext";
import React from "react";
import { enqueueSnackbar } from "notistack";

const EditCoachDialog = ({
  editCoach,
  setEditCoach,
  fetchCoachList,
}: {
  editCoach: EditCoachProps;
  setEditCoach: React.Dispatch<React.SetStateAction<EditCoachProps>>;
  fetchCoachList: () => Promise<void>;
}): React.JSX.Element => {
  const {
    user,
  }: {
    user: User;
  } = React.useContext(AppContext);

  const doEdit = async (): Promise<void> => {
    await axios
      .post(endpoints.admin.editCoach, {
        sessionId: user.sessionId,
        coach: editCoach.coach,
      })
      .then(async (response) => {
        if (response.data) {
          setEditCoach({
            ...editCoach,
            showDialog: false,
          });

          fetchCoachList();

          enqueueSnackbar("Coach erfolgreich bearbeitet!", {
            variant: "success",
          });
        } else {
          enqueueSnackbar("Fehler beim Bearbeiten des Coaches!", {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar("Fehler beim Bearbeiten des Coaches!", {
          variant: "error",
        });
      });
  };

  return (
    <Dialog
      maxWidth="lg"
      open={editCoach.showDialog}
      onClose={() =>
        setEditCoach({
          ...editCoach,
          showDialog: false,
        })
      }
      aria-labelledby="edit-coach-alert-dialog-title"
      aria-describedby="edit-coach-alert-dialog-description"
    >
      <DialogTitle id="edit-coach-alert-dialog-title">
        Coach bearbeiten
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} md={6}>
            <RenderFirstNameInput
              editCoach={editCoach}
              setEditCoach={setEditCoach}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <RenderLastNameInput
              editCoach={editCoach}
              setEditCoach={setEditCoach}
            />
          </Grid>

          <Grid item xs={12}>
            <RenderEmailInput
              editCoach={editCoach}
              setEditCoach={setEditCoach}
            />
          </Grid>

          <Grid item xs={12}>
            <RenderTeamsLinkInput
              editCoach={editCoach}
              setEditCoach={setEditCoach}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: 2,
        }}
      >
        <Button
          onClick={() =>
            setEditCoach({
              ...editCoach,
              showDialog: false,
            })
          }
          variant="outlined"
          color="secondary"
        >
          Schließen
        </Button>

        <Button
          onClick={doEdit}
          variant="contained"
          color="primary"
          disabled={
            editCoach.coach.vorname.length === 0 ||
            editCoach.coach.nachname.length === 0 ||
            !checkEmail(editCoach.coach.email) ||
            editCoach.coach.teams_link.length === 0
          }
        >
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditCoachDialog;

const RenderFirstNameInput = ({
  editCoach,
  setEditCoach,
}: {
  editCoach: EditCoachProps;
  setEditCoach: React.Dispatch<React.SetStateAction<EditCoachProps>>;
}): React.JSX.Element => {
  return (
    <FirstNameInput
      required
      firstName={editCoach.coach.vorname}
      setFirstName={(firstName: string) =>
        setEditCoach({
          ...editCoach,
          coach: {
            ...editCoach.coach,
            vorname: firstName,
          },
        })
      }
    />
  );
};

const RenderLastNameInput = ({
  editCoach,
  setEditCoach,
}: {
  editCoach: EditCoachProps;
  setEditCoach: React.Dispatch<React.SetStateAction<EditCoachProps>>;
}): React.JSX.Element => {
  return (
    <LastNameInput
      disabled={false}
      required={true}
      lastName={editCoach.coach.nachname}
      setLastName={(lastName: string) =>
        setEditCoach({
          ...editCoach,
          coach: {
            ...editCoach.coach,
            nachname: lastName,
          },
        })
      }
    />
  );
};

const RenderEmailInput = ({
  editCoach,
  setEditCoach,
}: {
  editCoach: EditCoachProps;
  setEditCoach: React.Dispatch<React.SetStateAction<EditCoachProps>>;
}): React.JSX.Element => {
  return (
    <EmailInput
      disabled={false}
      required={true}
      email={editCoach.coach.email}
      setEmail={(email: string) =>
        setEditCoach({
          ...editCoach,
          coach: {
            ...editCoach.coach,
            email: email,
          },
        })
      }
    />
  );
};

const RenderTeamsLinkInput = ({
  editCoach,
  setEditCoach,
}: {
  editCoach: EditCoachProps;
  setEditCoach: React.Dispatch<React.SetStateAction<EditCoachProps>>;
}): React.JSX.Element => {
  return (
    <TextField
      multiline
      focused
      color="success"
      error={editCoach.coach.teams_link.length === 0}
      helperText={
        editCoach.coach.teams_link.length === 0
          ? "Bitte gib einen Teams-Link ein"
          : ""
      }
      sx={{
        "& .MuiInputBase-root": {
          color:
            editCoach.coach.teams_link.length > 0
              ? (theme) => theme.palette.success.main
              : (theme) => theme.palette.error.main,
        },
      }}
      required
      fullWidth
      type="text"
      id="teams-link-input"
      label="Teams-Link"
      variant="outlined"
      value={editCoach.coach.teams_link}
      onChange={(e) => {
        setEditCoach({
          ...editCoach,
          coach: {
            ...editCoach.coach,
            teams_link: e.target.value,
          },
        });
      }}
    />
  );
};
