import { TextField } from "@mui/material";

export const checkEmail = (email: string, error?: boolean): boolean => {
  if (error && email.includes("wbsedu")) return false;
  const emailRegex = /\S+@\S+\.\S+/;
  return emailRegex.test(email);
};

interface EmailInputProps {
  email: string;
  setEmail?: (email: string) => void;
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
}

const EmailInput = ({
  email,
  setEmail,
  disabled,
  required,
  error,
}: EmailInputProps): React.JSX.Element => {
  return (
    <TextField
      sx={{
        "& .MuiInputBase-root": {
          color: !checkEmail(email, error)
            ? (theme) => theme.palette.error.main
            : (theme) => theme.palette.success.main,
        },
      }}
      disabled={disabled}
      focused={checkEmail(email)}
      color="success"
      required={required}
      fullWidth
      type="email"
      id="EmailInput"
      label="Email Adresse"
      variant="outlined"
      onChange={(e) => {
        if (setEmail) {
          setEmail(e.target.value);
        }
      }}
      value={email}
      error={!checkEmail(email, error)}
      helperText={
        error && email.includes("wbsedu")
          ? "Email Adresse darf nicht 'wbsedu' enthalten"
          : !checkEmail(email, error)
          ? "Bitte gib eine gültige Email Adresse ein"
          : ""
      }
    />
  );
};

export default EmailInput;
