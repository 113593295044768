import { Box, Grid, Typography, Button, Fade } from "@mui/material";
import { Appointment, Uploads } from "../booking";
import axios from "axios";
import { endpoints } from "../../../../endpoints/endpoints";
import React from "react";
import { enqueueSnackbar } from "notistack";
import { User, AppContext } from "../../../../context/appContext";
import { useNavigate } from "react-router-dom";

const AppointmentTile = ({
  appointment,
  uploads,
}: {
  appointment: Appointment;
  uploads?: Uploads;
}): React.JSX.Element => {
  const {
    user,
  }: {
    user: User;
  } = React.useContext(AppContext);

  const navigate = useNavigate();

  const [bookingIsLoading, setBookingIsLoading] = React.useState(false);

  const doBooking = (appointmentId: string) => {
    if (bookingIsLoading) {
      return;
    }

    setBookingIsLoading(true);

    axios
      .post(endpoints.appointment.booking, {
        sessionId: user.sessionId,
        appointmentId: appointmentId,
      })
      .then(() => {
        enqueueSnackbar("Der Termin wurde gebucht!", {
          variant: "success",
        });

        navigate("/appointments");
        setBookingIsLoading(false);
      })
      .catch(() => {
        setBookingIsLoading(false);
        enqueueSnackbar("Termine konnten nicht geladen werden!", {
          variant: "error",
        });
      });
  };

  return (
    <Fade in>
      <Box
        sx={{
          backgroundColor: "#eee",
          p: 2,
          mb: 2,
          borderRadius: 2,
        }}
      >
        <Grid
          container
          spacing={4}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid item xs={6} sm={3} md={2}>
            <Box>
              <Typography variant="caption" component="div">
                Zeitraum:
              </Typography>
              <Typography variant="body1" component="div">
                {appointment.start} bis {appointment.end} Uhr
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={6} sm={3} md={2}>
            <Box>
              <Typography variant="caption" component="div">
                Typ:
              </Typography>
              <Typography variant="body1" component="div">
                {appointment.type}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={6} sm={3} md={6}>
            <Box>
              <Typography variant="caption" component="div">
                Coach:
              </Typography>
              <Typography variant="body1" component="div">
                {appointment.coach}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={6} sm={3} md={2}>
            <Box>
              {appointment.type === "Speedcoaching" ? (
                <Button
                  href={appointment.href!}
                  target="_blank"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Zum Coaching
                </Button>
              ) : (
                <Button
                  onClick={() => doBooking(appointment.id)}
                  disabled={bookingIsLoading || !uploads?.editableFile}
                  fullWidth
                  variant="contained"
                  color="secondary"
                >
                  {bookingIsLoading ? "Buchen..." : "Buchen"}
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Fade>
  );
};

export default AppointmentTile;
