import { TextField } from "@mui/material";

interface QualificationInputProps {
  disabled?: boolean;
  required?: boolean;
  qualification: string;
  setQualification: (qualification: string) => void;
}

const QualificationInput = ({
  disabled,
  required,
  qualification,
  setQualification,
}: QualificationInputProps) => {
  return (
    <TextField
      sx={{
        "& .MuiInputBase-root": {
          color:
            qualification.length === 0
              ? (theme) => theme.palette.error.main
              : (theme) => theme.palette.success.main,
        },
      }}
      disabled={disabled}
      focused={qualification.length > 0}
      color="success"
      required={required}
      fullWidth
      type="text"
      id="QualificationInput"
      label="Qualifizierung"
      variant="outlined"
      value={qualification}
      onChange={(event) => setQualification(event.target.value)}
      error={qualification.length === 0}
      helperText={
        qualification.length === 0 ? "Bitte gib deine Qualifizierung ein" : ""
      }
    />
  );
};

export default QualificationInput;
