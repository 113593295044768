import { Box, Container, Grid } from "@mui/material";
import React from "react";
import HeroBg from "../../../assets/landingpage/hero-bg.jpg";
import WelcomeImageWrapper from "./src/welcomeImageWrapper";
import WelcomeTextWrapper from "./src/WelcomeTextWrapper";

const Welcome = (): React.JSX.Element => {
  return (
    <Box
      sx={{
        py: 10,
        color: "white",
        background: `
      linear-gradient(to left, 
        rgba(44, 159, 225, 0.8) 0%, 
        rgba(0, 113, 178, 0.8) 100%), 
        url(${HeroBg})`,
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={10}>
          <WelcomeImageWrapper />
          <WelcomeTextWrapper />
        </Grid>
      </Container>
    </Box>
  );
};

export default Welcome;
